<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <template v-slot:activator="{ on }">
      <v-fab-transition>
        <v-btn
          v-on="on"
          color="primary"
          dark
          v-show="$vuetify.breakpoint.mdAndUp || ready"
          :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
          :fab="$vuetify.breakpoint.smAndDown"
          :fixed="$vuetify.breakpoint.smAndDown"
          :bottom="$vuetify.breakpoint.smAndDown"
          :right="$vuetify.breakpoint.smAndDown"
          :class="{'ma-0': $vuetify.breakpoint.mdAndUp}"
        >
          <v-icon :left="$vuetify.breakpoint.mdAndUp">person_add</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('user_management.create_new_user') }}</span>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ $t('user_management.create_new_user') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="dialog-content">

        <v-subheader class="pl-0 font-weight-bold secondary--text text--darken-1">{{ $t('user_management.user_type') }}</v-subheader>

        <v-btn-toggle v-model="accountType" mandatory>
          <v-btn small value="COGNITO">{{ $t('user_management.account_type.housing_manager') }}</v-btn>
          <v-btn small value="PASSWORDLESS">{{ $t('user_management.account_type.housing_association') }}</v-btn>
        </v-btn-toggle>

        <v-form ref="form">

          <v-subheader class="mt-5 pl-0 font-weight-bold secondary--text text--darken-1">{{ $t('user_management.user_info') }}</v-subheader>

          <template v-if="accountType === 'COGNITO'">
            <v-text-field
              :rules="[$rules.required, $rules.email, $rules.spacesNotAllowed, notInUse]"
              validate-on-blur
              class="mt-0 email"
              :label="$t('user_management.email')"
              autocomplete="username"
              type="text"
              v-model="username"
            ></v-text-field>
            <v-text-field
              :rules="[$rules.phone, notInUse]"
              validate-on-blur
              class="mt-2 email"
              :label="$t('user_management.phone')"
              autocomplete="phone"
              type="text"
              v-model="phone"
            ></v-text-field>
          </template>

          <template v-if="accountType === 'PASSWORDLESS'">
            <v-text-field
              :rules="[$rules.email, $rules.spacesNotAllowed, notInUse]"
              validate-on-blur
              class="mt-0 email"
              :label="$t('user_management.email')"
              autocomplete="username"
              type="email"
              @blur="validateEmailAndPhone = true"
              v-model="username"
            ></v-text-field>
            <v-text-field
              :rules="[$rules.phone, notInUse]"
              validate-on-blur
              class="mt-2 email"
              :label="$t('user_management.phone')"
              autocomplete="phone"
              type="tel"
              @blur="validateEmailAndPhone = true"
              v-model="phone"
            ></v-text-field>
          </template>


          <v-text-field
            :rules="[$rules.required]"
            validate-on-blur
            class="mt-2 first-name"
            :label="$t('user_management.first_name')"
            autocomplete="first-name"
            type="text"
            v-model="firstName"
          ></v-text-field>
          <v-text-field
            :rules="[$rules.required]"
            validate-on-blur
            class="mt-2 last-name"
            :label="$t('user_management.last_name')"
            autocomplete="family-name"
            type="text"
            v-model="lastName"
          ></v-text-field>
          <v-text-field
            class="mt-2"
            :label="$t('user_management.street_address')"
            type="text"
            v-model="streetAddress"
          ></v-text-field>
          <v-text-field
            class="mt-2"
            :label="$t('user_management.postal_code')"
            type="text"
            v-model="postalCode"
          ></v-text-field>
          <v-text-field
            class="mt-2"
            :label="$t('user_management.city')"
            type="text"
            v-model="city"
          ></v-text-field>

          <template v-if="accountType === 'COGNITO'">
            <v-subheader class="pl-0 font-weight-bold secondary--text text--darken-1">{{ $t('user_management.user_roles_in_app') }}</v-subheader>
            <v-select
              v-model="roleSet"
              :items="translatedRoles"
              :label="$t('user_management.role.title')"></v-select>
          </template>
          <template v-else>
            <v-checkbox :label="$t('user_management.send_invitation_message')" v-model="invitationMessage" />
          </template>

        </v-form>

        <v-subheader class="pl-0 font-weight-bold secondary--text text--darken-1">{{ $t('user_management.user_tenants') }}</v-subheader>
        <template v-if="userTenants.length > 0">
          <div class="mb-5">
            <v-card v-for="(tenant, index) in userTenants" :key="index" elevation="0" color="grey lighten-3 mb-2">
              <v-card-text class="pa-0">
                <v-list-item class="px-0">
                  <v-list-item-content class="py-0">
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" class="pa-2 pb-1">
                        <v-autocomplete :label="$t('tenants.organization')" :no-data-text="$t('user_management.no_tenants')" hide-details outlined v-model="tenant.tenant" :items="tenants" item-value="id" item-text="name"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" class="pa-2">
                        <v-select :label="$t('user_management.tenant_role')" hide-details outlined v-model="tenant.roleSet" :items="translatedTenantRoles"></v-select>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                  <v-list-item-action class="ml-0 mr-2">
                    <v-btn @click="removeTenantFromUser(tenant, index)" icon><v-icon>close</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
            </v-card>
          </div>
        </template>
        <v-btn text outlined block @click="addNewTenantForUser">{{ $t('user_management.add_new_tenant') }}</v-btn>

        <template v-if="accountType === 'PASSWORDLESS'">
          <v-alert :value="emailOrPhoneIsRequired" type="error" outlined  class="mt-4">
            {{ $t('validation.email_or_phone_required') }}
          </v-alert>
        </template>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''">
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="saveUser"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import adminApi from "../../api/admin";
  import libphonenumber from "google-libphonenumber";

  const PNF = require('google-libphonenumber').PhoneNumberFormat;

  export default {
    name: "AddUserDialog",
    props: {
      roles: {
        type: Array,
        default: () => [],
      },
      allUsers: {
        type: Array,
        default: () => [],
      },
      tenants: {
        type: Array,
        default: () => [],
      },
      tenantRoles: {
        type: Array,
        default: () => [],
      }
    },
    data() {
      return {
        dialog: false,
        accountType: 'COGNITO',
        emailOrPhone: '',
        username: '',
        firstName: '',
        lastName: '',
        streetAddress: '',
        postalCode: '',
        city: '',
        phone: '',
        roleSet: this.roles.length > 0 ? this.roles[0] : '',
        showAlert: false,
        ready: false,
        userTenants: [],
        invitationMessage: true,
        validateEmailAndPhone: false,
      }
    },
    methods: {
      async saveUser() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }

        this.showAlert = false;

        if (this.validateEmailAndPhone) {
          if (this.accountType !== 'COGNITO' && this.emailOrPhoneIsRequired) {
            return;
          }
        } else {
          this.validateEmailAndPhone = true;
          return;
        }

        const tenantRoles = this.userTenants.filter(t => t.tenant.length > 0).map(tenant => {
          return {tenant: tenant.tenant, roleSet: [tenant.roleSet]};
        });

        let user = {
          roleSet: this.accountType === 'COGNITO' ? [this.roleSet] : ['basic'],
          username: this.username.length > 0 ? this.username : this.phone,
          email: this.username,
          phone: this.phone,
          firstName: this.firstName,
          lastName: this.lastName,
          streetAddress: this.streetAddress,
          postalCode: this.postalCode,
          city: this.city,
          type: this.accountType,
          tenantRoles: tenantRoles,
        };

        try {
          const newUser = await adminApi.createUser(user);

          if (this.accountType === 'PASSWORDLESS' && this.invitationMessage) {
            if (this.username.length > 0) {
              this.$emit('send-invitation', newUser.id, 'EMAIL');
            } else if (this.phone.length > 0) {
              this.$emit('send-invitation', newUser.id, 'SMS');
            }
          }

          this.dialog = false;
          this.$showSuccessNotification(this.$t('user_management.user_created_success'));
        } catch (err) {
          this.$handleApiError(err);
        }
      },
      notInUse(value) {
        if (value.length === 0) return true;
        try {
          const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
          const number = phoneUtil.parseAndKeepRawInput(value, 'FI');
          value = phoneUtil.format(number, PNF.E164);
        } catch (ignored) { // is not a phone number
        }

        const userIndex = this.allUsers.findIndex(user =>
          (user.subject && user.subject.length > 0 && user.subject === value) ||
          (user.email && user.email.length > 0 && user.email === value) ||
          (user.phone && user.phone.length > 0 && user.phone === value));
        return userIndex === -1 || this.$t('validation.user_details_already_in_use');
      },
      resetFormData() {
        this.username = '';
        this.firstName = '';
        this.lastName = '';
        this.streetAddress = '';
        this.postalCode = '';
        this.city = '';
        this.phone = '';
        this.userTenants = [];
        this.invitationMessage = true;
        this.roleSet = this.roles.length > 0 ? this.roles[0] : '';
        this.showAlert = false;
        this.validateEmailAndPhone = false;
      },
      addNewTenantForUser() {
        this.userTenants.push({tenant: '', roleSet: 'tenant_member'});
      },
      removeTenantFromUser(tenant, index) {
        if (tenant.tenant && tenant.tenant.length > 0) {
          const tenantIndex = this.userTenants.findIndex(t => t.tenant === tenant.tenant);
          if (tenantIndex > -1) index = tenantIndex;
        }
        this.userTenants.splice(index, 1);
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.resetFormData();
          this.$emit('on-dismiss');
        }
      },
      accountType() {
        this.$refs.form.resetValidation();
        this.resetFormData();
      }
    },
    computed: {
      translatedRoles() {
        const roles = [];
        this.roles.forEach(role => {
          roles.push({value: role, text: this.$t(`user_management.role.${role}`)});
        });
        return roles;
      },
      translatedTenantRoles() {
        const roles = [];
        this.tenantRoles.forEach(role => {
          roles.push({value: role, text: this.$t(`user_management.role.${role}`)});
        });
        return roles;
      },
      emailOrPhoneIsRequired() {
        if (this.validateEmailAndPhone) {
          return !(this.username.length > 0 || this.phone.length > 0);
        }
        return false;
      }
    },
    mounted() {
      if (this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          this.ready = true
        }, 300);
      } else {
        this.ready = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
.dialog-content {
  max-height: calc(100vh - 56px);
  padding-bottom: 100px !important;
  overflow: auto;
}

@media only screen and (min-width: 600px) {
  .dialog-content {
    max-height: calc(100vh - 350px);
    padding-bottom: 50px !important;
    overflow: auto;
  }
}
</style>
