import Vue from 'vue';
import Router from 'vue-router';
import LoginView from './views/LoginView.vue';

// Admin views
import UserManagementView from './views/UserManagementView.vue';
import Tenants from './views/TenantsView.vue';

// User views
import UserProfile from './views/UserProfile.vue';

// Tenant views
import TenantContainer from "./views/TenantContainer.vue";
import TenantUserManagementView from "./views/tenant/TenantUserManagementView.vue";
import TenantManagementView from "./views/tenant/TenantManagementView.vue";
import ProposalsView from "@/views/tenant/ProposalsView";
import SingleProposalView from "@/views/tenant/SingleProposalView";
import EditProposalView from "@/views/tenant/EditProposalView";
import RedirectToTenantStartView from "@/views/RedirectToTenantStartView";
import DevelopmentLoginView from "@/views/DevelopmentLoginView";
import ThreadsView from '@/views/tenant/ThreadsView';
import EditThreadView from '@/views/tenant/EditThreadView';
import SingleThreadView from '@/views/tenant/SingleThreadView';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'start',
      component: RedirectToTenantStartView,
    },
    {
      path: '/passwordlogin',
      name: 'passwordlogin',
      component: DevelopmentLoginView,
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/users',
      name: 'users',
      component: UserManagementView,
    },
    {
      path: '/me',
      name: 'userprofile',
      component: UserProfile,
    },
    {
      path: '/t/:tenantId',
      props: true,
      component: TenantContainer,
      children: [
        {
          path: 'users',
          name: 'tenant_users',
          props: true,
          component: TenantUserManagementView,
        },
        {
          path: 'settings',
          name: 'tenant_settings',
          props: true,
          component: TenantManagementView,
        },
        {
          path: 'proposals',
          name: 'proposals',
          props: true,
          component: ProposalsView,
        },
        {
          path: 'proposal/:id/:tab?/:voteNow?',
          name: 'single_proposal',
          props: true,
          component: SingleProposalView,
        },
        {
          path: 'proposals/edit/:id',
          name: 'edit_proposal',
          component: EditProposalView,
          props: true,
        },
        {
          path: 'new_proposal',
          name: 'new_proposal',
          component: EditProposalView,
          props: {
            viewMode: 'NEW',
            id: -1,
          },
        },
        {
          path: 'threads',
          name: 'threads',
          props: true,
          component: ThreadsView,
        },
        {
          path: 'threads/edit/:id',
          name: 'edit_thread',
          component: EditThreadView,
          props: true,
        },
        {
          path: 'new_thread',
          name: 'new_thread',
          component: EditThreadView,
          props: {
            viewMode: 'NEW',
            id: -1,
          },
        },
        {
          path: 'thread/:id/:tab?',
          name: 'single_thread',
          props: true,
          component: SingleThreadView,
        },
      ]
    },
    {
      path: '/tenants',
      name: 'tenants',
      component: Tenants,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})
