<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark :color="options.color">
        <v-toolbar-title class="white--text" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="cancel()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5" v-show="!!message">{{ message }}</v-card-text>
      <v-divider></v-divider>

      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn :class="$vuetify.breakpoint.xsOnly ? 'mx-0' : ''" :block="$vuetify.breakpoint.xsOnly" color="default" @click.native="cancel()">{{options.cancel}}</v-btn>
        <v-btn :class="$vuetify.breakpoint.xsOnly ? 'mb-2 mx-0' : ''" :block="$vuetify.breakpoint.xsOnly" :color="options.color" @click.native="agree()">{{options.agree}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>



<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {});
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open;
 * }
 */
export default {
  name: 'ConfirmDialog',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: 'primary',
        agree: this.$t('yes'),
        cancel: this.$t('no'),
        width: 390,
      }
    };
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
}
</script>
