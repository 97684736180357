/* eslint-disable no-param-reassign,no-unused-vars */
import Vue from 'vue'
import moment from 'moment/moment'
import md5 from 'md5';

import adminApi from "../api/admin";
import userApi from "../api/user";
import systemApi from "../api/system";
import tenantApi from "../api/tenant";
import tenantService from '../service/tenant.service';
import store from "../store";
import i18n from "../i18n";

moment.locale('fi');

const HelperPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $tenant() {
          const savedTenant = tenantService.tenant;
          if (savedTenant) {
            return savedTenant;
          }

          if (savedTenant == null && this.$userInfo && this.$userInfo.tenants.length > 0) {
            return this.$userInfo.tenants[0];
          }

          return null;
        },
        $userApi() {
          return userApi;
        },
        $adminApi() {
          return adminApi;
        },
        $systemApi() {
          return systemApi;
        },
        $tenantApi() {
          return tenantApi;
        },
        $baseUrl() {
          const hasCustomBaseUrl = process.env.VUE_APP_API_BASEURL.indexOf('undefined') === -1;
          if (hasCustomBaseUrl) {
            return process.env.VUE_APP_API_BASEURL;
          }
          return '';
        },
        $bottomNavigationStyle() {
          if (this.$vuetify.breakpoint.xsOnly) {
            return "z-index: 5; bottom: 72px"
          }
          return '';
        }
      },
      methods: {
        $isValidEmail(mail) {
          if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return true;
          }
          return false;
        },
        $showSuccessNotification(message) {
          store.dispatch('showNotification', {
            color: 'success',
            message,
            showButton: false,
          });
        },
        $showWarningNotification(message) {
          store.dispatch('showNotification', {
            color: 'warning',
            message,
            showButton: false,
          });
        },
        $showErrorNotification(message) {
          const msg = message != null ? message : i18n.t('generic_error');
          store.dispatch('showNotification', {
            color: 'error',
            message: msg,
            showButton: false,
          });
        },
        /**
         *
         * @param {!error} err
         * @param {msg} [msg] message to show
         */
        $handleApiError(err, msg) {
          console.log(err);
          if (err.response != null && err.response.data && err.response.data.error_key != null) {
            this.$showErrorNotification(i18n.t(err.response.data.error_key));
          } else {
            this.$showErrorNotification(msg);
          }
        },
        $reloadApp() {
          location.reload();
        },
        $formatCalendarTimeStamp(date) {
          if (moment(date).isSame(moment(), 'minute')) {
            return this.$t('proposals.chat.just_right_now');
          }
          if (moment(date).isSame(moment(), 'week')) {
            return moment(date).fromNow();
          }
          return moment(date).calendar(null, {
            sameElse: 'llll'
          });
        },
        $formatTimeStamp(date) {
          return moment(date).format('LLLL');
        },
        $formatDateNoTime(date) {
          return date ? moment(date).format('DD.MM.YYYY') : '';
        },
        $formatTimeNoDate(date) {
          if (date) {
            return moment(date).format('HH:mm');
          }
        },
        $formatDateAndTime(date) {
          if (date) {
            return moment(date).format('DD.MM.YYYY HH:mm');
          }
        },
        $humanizeDuration(duration, showSeconds = false) {
          if (duration.asHours() > 23) {
            return Math.floor(duration.asHours()) + 'h ' + duration.minutes() + 'min';
          }
          return duration.hours() + 'h ' + duration.minutes() + 'min ' + (showSeconds ? duration.seconds() + 's' : '');
        },
        $randomizeColorFromString(string, saturation = 75, lightness = 60) {
          let hash = 0;
          if (string.length > 0) {
            for (let i = 0; i < string.length; i++) {
              hash = string.charCodeAt(i) + ((hash << 5) - hash);
              hash = hash & hash; // Convert to 32bit integer
            }
          }

          if (hash < 0) hash = hash * -1;

          let shortened = hash % 200;
          return `hsl(${shortened}, ${saturation}%, ${lightness}%)`;
        },
        async $getFileUrl(attachment) {
          let fileAccessToken = '';
          if (attachment.proposalId) {
            fileAccessToken = await tenantApi.createFileAccessToken(attachment);
          } else {
            fileAccessToken = await tenantApi.createThreadFileAccessToken(attachment);
          }
          return `${this.$baseUrl}/api/public/file/${attachment.fileKey}?token=${fileAccessToken}`;
        },
        $getImageUrl(imageKey) {
          return `${this.$baseUrl}/api/public/image/${imageKey}`;
        },
        $getGravatarUrl(email, size) {
          const picSize = size ? size : '68';
          const hash = md5((email.toLowerCase()).trim());
          return `https://www.gravatar.com/avatar/${hash}?s=${picSize}&d=mm`; // eslint-disable-line no-undef
        },
        async $createFileUrl(file) {
          let a;
          if (file.proposalId) {
            a = await tenantApi.downloadAttachment(file);
          } else {
            a = await tenantApi.downloadThreadAttachment(file);
          }
          const blob = new Blob([a], { type: file.contentType });
          return URL.createObjectURL(blob);
        }
      }
    });
  },
};

export default HelperPlugin;
