<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ $t('tenants.edit_organization') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">

          <v-text-field
            class="mt-5 organization_name"
            :label="$t('tenants.organization_name')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            autocomplete="organization"
            v-model="currentTenant.name"
          ></v-text-field>

          <v-text-field
            class="mt-5 city"
            :label="$t('tenants.city')"
            validate-on-blur
            type="text"
            autocomplete="city"
            v-model="currentTenant.city"
          ></v-text-field>

        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">Tarkista, että olet täyttänyt kaikki pakolliset kentät.</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="saveOrganization"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "TenantDetailsDialog",
    data() {
      return {
        dialog: false,
        organization: '',
        showAlert: false,
        currentTenant: {},
      }
    },
    methods: {
      async saveOrganization() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }
        try {
          await this.$tenantApi.updateCurrentTenant({
            name: this.currentTenant.name,
            city: this.currentTenant.city,
          });
          this.dialog = false;
          this.$showSuccessNotification(this.$t('tenants.organization_update_success'));
        } catch (err) {
          this.$handleApiError(err, this.$t('tenants.organization_update_failed'));
        }
      },
      open(tenant) {
        this.dialog = true;
        this.currentTenant = Object.assign({}, tenant);
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.currentTenant = {};
          this.showAlert = false;
          this.$refs.form.reset();
          this.$emit('on-dismiss');
        }
      }
    },
  }
</script>

<style scoped>

</style>
