<template>
  <v-row no-gutters class="mt-0 pt-3">
    <v-col cols="10" :order="isUsersOwnComment ? 1 : 0">
      <v-card :color="singleChatMessageColor"
              elevation="2"
              class="rounded-t-lg rounded-b-lg"
              :class="isUsersOwnComment ? 'rounded-br-0' : 'rounded-tl-0'">
        <v-card-subtitle v-if="!isUsersOwnComment" class="caption pt-2 pb-0" :style="{color: $randomizeColorFromString(comment.user ? comment.user.firstName + comment.user.lastName + comment.user.email + comment.user.phone : '', 60)}">
          {{ comment.user ? comment.user.firstName && comment.user.lastName ? `${comment.user.firstName} ${comment.user.lastName}` : comment.user.email : '' }}
        </v-card-subtitle>
        <v-card-text :class="singleChatMessageTextStyles">
          <div class="caption" v-if="comment.type === 'ATTACHMENT_DELETED'">
            <strong>{{ $t('proposals.chat.file_deleted') }}:</strong> {{ comment.message }}
          </div>
          <template v-else>
            <template v-if="comment.attachment">
              <div class="caption" v-if="comment.attachment.deleted">
                <strong>{{ $t('proposals.chat.file_added') }}:</strong> {{ comment.attachment.name || comment.attachment.filename }} {{ $t('proposals.chat.file_deleted_suffix') }}
              </div>
              <div class="caption" v-else>
                <strong>{{ $t('proposals.chat.file_added') }}:</strong> <a @click="handleAttachmentClick">{{ comment.attachment.name || comment.attachment.filename }}</a>
                <v-progress-circular v-if="loading" class="ml-2" style="margin-top: -3px;" size="12" width="1" color="primary" indeterminate />
              </div>
            </template>
            <span v-if="comment.creator">{{ whoCreatedComment(comment) }} <strong>'{{ voteStatus(comment.voteType) }}'</strong>, {{ $t('proposals.chat.comment') }}:</span> {{ singleChatMessageText }}
          </template>
        </v-card-text>
        <v-card-subtitle v-if="!comment.deleted" class="caption text-right pt-0 pb-0"
                         :class="isUsersOwnComment ? 'secondary--text text--darken-2' : 'grey--text text--lighten-1'">
          {{ $formatCalendarTimeStamp(comment.created) }}
        </v-card-subtitle>
      </v-card>
    </v-col>
    <v-col cols="2" align-self="center"
           :order="isUsersOwnComment ? 0 : 1"
           :class="isUsersOwnComment ? 'text-right' : '' ">
      <v-menu v-if="!comment.deleted" :left="!isUsersOwnComment" :right="isUsersOwnComment">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="mx-1" icon>
            <v-icon>mdi mdi-dots-vertical</v-icon>
          </v-btn>

        </template>
        <v-list dense>
          <v-list-item @click="showAllUsersThatHasSeenTheComment">
            <v-list-item-title >{{ $t('proposals.chat.show_who_has_seen') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isUsersOwnComment && !comment.deleted" @click="deleteComment">
            <v-list-item-title  >{{ $t('proposals.chat.delete_a_message') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="12" class="pt-1 px-2" order="2" :class="isUsersOwnComment ? 'text-right' : ''" :style="{height: commentLastSeenUsers.length > 0 ? '28px' : '0'}">
      <template v-for="user in commentLastSeenUsers">
        <v-tooltip top :key="user.id">
          <template v-slot:activator="{ on }">
            <v-avatar v-on="on"
                      :color="$randomizeColorFromString(user ? user.firstName + user.lastName + user.email + user.phone : '', 60)"
                      size="24"
                      style="margin-right: -4px;">
              <span class="white--text caption font-weight-bold">{{ userInitials(user) }}</span>
            </v-avatar>
          </template>
          {{ userFullName(user) }}
        </v-tooltip>
      </template>
      <confirm-dialog ref="confirmDialog" />
      <who-has-seen-comment-dialog ref="whoHasSeenCommentDialog" />
    </v-col>
  </v-row>
</template>

<script>
import tenantApi from "@/api/tenant";
import moment from 'moment';

import ConfirmDialog from "@/components/ConfirmDialog";
import WhoHasSeenCommentDialog from "@/components/Tenant/Common/WhoHasSeenCommentDialog";

export default {
  name: "SingleChatMessage",
  components: {ConfirmDialog, WhoHasSeenCommentDialog},
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    proposal: {
      type: Object,
      default: () => {},
    },
    thread: {
      type: Object,
      default: () => {},
    },
    disableImageLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    voteStatus(voteType) {
      switch (voteType) {
        case 'APPROVE':
          return 'Kyllä';
        case 'DENY':
          return 'Ei';
        case 'MOVE_TO_MEETING':
          return 'Siirrä kokoukseen';
      }
    },
    whoCreatedComment(comment) {
      const roles = comment.creator.tenantRoles.find(r => r.tenant === this.$tenant);
      if (roles.roleSet.includes('tenant_housing_manager')) {
        return this.$t('proposals.chat.housing_manager_voted')+' '+comment.user.firstName+' '+comment.user.lastName+' '+this.$t('proposals.chat.for_user')
      } else if (roles.roleSet.includes('tenant_housing_coordinator')) {
        return this.$t('proposals.chat.housing_coordinator_voted')+' '+comment.user.firstName+' '+comment.user.lastName+' '+this.$t('proposals.chat.for_user')
      }
    },
    async downloadAttachment() {
      const link = document.createElement('a');
      link.href = await this.$createFileUrl(this.comment.attachment);
      link.download = this.comment.attachment.name || this.comment.attachment.filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    async showImage() {
      if (this.disableImageLoading) return;

      this.loading = true;
      this.$emit('image-loading');

      try {
        const imageUrl = await this.$createFileUrl(this.comment.attachment);
        this.$emit('open-image', imageUrl);
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
      this.$emit('image-loaded');
    },
    handleAttachmentClick() {
      let filetype = this.comment.attachment.contentType ? this.comment.attachment.contentType.split("/") : '';

      if (this.proposal) {
        this.$set(this.comment.attachment, 'proposalId', this.proposal.id);
      } else if (this.thread) {
        this.$set(this.comment.attachment, 'threadId', this.thread.id);
      }

      if (filetype[0] === 'image') {
        this.showImage();
      } else if (filetype[0] === 'video') {
        // todo
      } else {
        this.downloadAttachment();
      }
    },
    showAllUsersThatHasSeenTheComment() {
      this.$refs.whoHasSeenCommentDialog.open(this.usersThatHaveSeenComment);
    },
    userInitials(user) {
      return this.userFullName(user).split(' ').map(n => n.slice(0, 1)).join('');
    },
    userFullName(user) {
      if (!user) {
        return '';
      } else if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`;
      } else {
        return user.firstName || user.lastName || user.username;
      }
    },
    async deleteComment() {
      const deleteConfirmed = await this.$refs.confirmDialog.open(this.$t('proposals.chat.delete_dialog.message_delete_dialog_title'), this.$t('proposals.chat.delete_dialog.message_delete_dialog_text'), { color: 'warning', agree: this.$t('proposals.chat.delete_dialog.delete_yes'), cancel: this.$t('proposals.chat.delete_dialog.delete_no') });
      if (!deleteConfirmed) return;

      try {
        if (this.proposal) {
          await tenantApi.deleteComment(this.proposal.id, this.comment.id);
        } else if (this.thread) {
          await tenantApi.deleteMessage(this.thread.id, this.comment.id);
        }
        this.$showSuccessNotification(this.$t('proposals.chat.own_chat_message_delete_confirm'));
        this.$emit('deleted')
      } catch (err) {
        this.$handleApiError(err);
      }
    },
  },
  computed: {
    commentLastSeenUsers() {
      const r = this.proposal ? this.proposal.commentReads : this.thread.commentReads;
      return r.filter(r => r.user.id !== this.$userInfo.id && r.targetId === this.comment.id)
        .map(r => r.user);
    },
    usersThatHaveSeenComment() {
      const r = this.proposal ? this.proposal.commentReads : this.thread.commentReads;
      return r.filter(r => r.user.id !== this.$userInfo.id && moment(r.date).isAfter(moment(this.comment.updated)))
        .map(r => r.user);
    },
    isUsersOwnComment() {
      return this.comment.user && this.comment.user.id === this.$userInfo.id;
    },
    singleChatMessageColor() {
      if (this.comment.deleted) return 'blue-grey lighten-5';
      if (this.isUsersOwnComment) {
        return 'secondary lighten-1';
      }
      return '';
    },
    singleChatMessageText() {
      if (this.comment.deleted) {
        return this.$t('proposals.chat.chat_message_deleted_text_in_message');
      }
      return this.comment.message;
    },
    singleChatMessageTextStyles() {
      const styleClasses = [];

      if (this.isUsersOwnComment && !this.comment.deleted) styleClasses.push('secondary--text text--darken-4');
      if (this.comment.deleted) {
        styleClasses.push('pb-3');
      } else {
        styleClasses.push('pb-0');
      }
      return styleClasses;
    },
  }
}
</script>

<style scoped>

</style>
