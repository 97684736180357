<template>
  <v-card>
    <v-card-subtitle v-if="voteStatus !== 'ENDED'" class="pb-0 text-uppercase secondary--text text--darken-2">
      {{ $t('proposals.voting_score_manager') }}
    </v-card-subtitle>
    <v-card-text class="pb-1">
      {{ $t('proposals.housing_manager_info_for_voting') }}
    </v-card-text>
    <v-card-text class="pt-0">
      <v-card-subtitle v-if="filteredTenantUsers.length > 0" class="pa-0 pt-4 secondary--text text--darken-2">
        {{ $t('proposals.not_voted_users') }}: <strong>{{ filteredTenantUsers.length }}</strong>
      </v-card-subtitle>
      <v-list class="pt-0" dense color="transparent">

        <template v-if="filteredTenantUsers.length > 0">
          <template v-for="user in filteredTenantUsers">
            <v-list-item class="px-0 pb-2" :key="'housing_manager_view '+user.id">
              <v-list-item-title>{{ user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email }}</v-list-item-title>
              <v-list-item-icon style="min-width: 100px;">
                <v-hover v-slot="{ hover }"><v-icon @click="submitVote('APPROVE', user)" :color="hover ? 'success' : 'grey lighten-1'">mdi mdi-check-circle-outline</v-icon></v-hover>
                <div class="pl-3"><v-hover v-slot="{ hover }"><v-icon @click="submitVote('DENY', user)" :color="hover ? 'error' : 'grey lighten-1'">mdi mdi-close-circle-outline</v-icon></v-hover></div>
                <div class="pl-3"><v-hover v-slot="{ hover }"><v-icon @click="submitVote('MOVE_TO_MEETING', user)" :color="hover ? 'warning' : 'grey lighten-1'" >mdi mdi-arrow-right-bold-circle-outline</v-icon></v-hover></div>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </template>

        <v-card-subtitle v-if="votes.length > 0" class="pa-0 pt-4 secondary--text text--darken-2">
          {{ $t('proposals.voted_users') }}: <strong>{{ votes.length }}</strong>
        </v-card-subtitle>

        <template v-if="votes.length > 0">
          <template v-for="vote in votes">
            <v-list-item class="px-0 pb-2" :key="vote.id">
              <v-list-item-content>
                <v-list-item-title>{{ vote.user ? vote.user.firstName+' '+vote.user.lastName : vote.user.email }}</v-list-item-title>
                <v-list-item-subtitle v-if="vote.creator" class="text-wrap">{{ $t('proposals.voted') }}: {{ vote.creator ? vote.creator.firstName+' '+vote.creator.lastName : vote.creator.email }}<span v-if="vote.comment"><br>{{$t('proposals.comment') }}: {{ vote.comment && vote.comment.message }} </span></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon v-if="(vote.creator && vote.creator.id) && isManagerOrCoordinator(vote.creator)" style="min-width: 100px; height: 38px">
                <div class=""><v-hover v-slot="{ hover }"><v-icon @click="submitVoteByManagerOrCoordinator('APPROVE', vote.user)" :color="hover || vote.type === 'APPROVE' ? 'success' : 'grey lighten-1'">mdi mdi-check-circle-outline</v-icon></v-hover></div>
                <div class="pl-3"><v-hover v-slot="{ hover }"><v-icon @click="submitVoteByManagerOrCoordinator('DENY', vote.user)" :color="hover || vote.type === 'DENY'  ? 'error' : 'grey lighten-1'">mdi mdi-close-circle-outline</v-icon></v-hover></div>
                <div class="pl-3"><v-hover v-slot="{ hover }"><v-icon @click="submitVoteByManagerOrCoordinator('MOVE_TO_MEETING', vote.user)" :color="hover || vote.type === 'MOVE_TO_MEETING'  ? 'warning' : 'grey lighten-1'" >mdi mdi-arrow-right-bold-circle-outline</v-icon></v-hover></div>
              </v-list-item-icon>

              <v-list-item-icon v-else style="min-width: 100px;">
                <v-icon :color="vote.type === 'APPROVE' ? 'success' : 'grey lighten-1'">mdi mdi-check</v-icon>
                <v-icon :color="vote.type === 'DENY' ? 'error' : 'grey lighten-1'" class="pl-3">mdi mdi-close</v-icon>
                <v-icon :color="vote.type === 'MOVE_TO_MEETING' ? 'warning' : 'grey lighten-1'" class="pl-3">mdi mdi-arrow-right-bold</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </template>

      </v-list>
    </v-card-text>

    <CommentWhenVotingDialog :housingmanager="true" ref="commentWhenVotingDialog" :proposal="proposal"/>

  </v-card>
</template>
<script>
import tenantApi from "@/api/tenant";
import CommentWhenVotingDialog from "@/components/Tenant/SingleProposal/CommentWhenVotingDialog";

export default {
  name: "VotingScoreHousingManager",
  components: {CommentWhenVotingDialog},
  props: {
    proposal: {
      type: Object,
      default: () => {},
    },
    votes: {
      type: Array,
      default: () => {},
    },
    voteStatus: {
      type: String,
      default: 'OPEN',
    },
  },
  data() {
    return {
      generatingReport: false,
      tenantUsers: [],
      currentTenant: {},
      userId: null,
    };
  },
  methods: {
    isManagerOrCoordinator(creator) {
      const rolesInThisTenant = creator.tenantRoles.find(t => t.tenant === this.tenant)
      return rolesInThisTenant.roleSet.findIndex(r => r === 'tenant_housing_manager' || r === 'tenant_housing_coordinator') > -1;
    },
    handleReload() {
      this.$emit('handle-reload');
    },
    async submitVoteByManagerOrCoordinator(type, user) {
      const isManagerOrCoordinatorInThisTenant = this.isManagerOrCoordinator(this.$userInfo);
      console.log(isManagerOrCoordinatorInThisTenant)

      if (isManagerOrCoordinatorInThisTenant) {
        let confirmVoting;
        if (this.voteStatus === 'OPEN' || this.voteStatus === 'IN_VOTE') {
          confirmVoting = await this.$refs.commentWhenVotingDialog.open(type, user);
          if (!confirmVoting) return;
          if (confirmVoting.commented) this.$emit('update-proposal');
        }

        this.voteLoading = true;
        this.voteKey = type;

        try {
          await this.$tenantApi.vote(this.proposal.id, type, user, confirmVoting?.comment);
          this.$showSuccessNotification(this.$t('proposals.vote_has_been_registered'));
          this.$emit('update-votes');
        } catch(error) {
          this.$handleApiError(error, this.$t('errors.error_in_voting'));
        }

        this.voteLoading = false;
        this.voteKey = '';
      }
    },
    async submitVote(type, user) {
      let confirmVoting;
      if (this.voteStatus === 'OPEN' || this.voteStatus === 'IN_VOTE') {
        confirmVoting = await this.$refs.commentWhenVotingDialog.open(type, user);
        if (!confirmVoting) return;
        if (confirmVoting.commented) this.$emit('update-proposal');
      }

      this.voteLoading = true;
      this.voteKey = type;

      try {
        await this.$tenantApi.vote(this.proposal.id, type, user, confirmVoting?.comment);
        this.$showSuccessNotification(this.$t('proposals.vote_has_been_registered'));
        this.$emit('update-votes');
      } catch(error) {
        this.$handleApiError(error, this.$t('errors.error_in_voting'));
      }

      this.voteLoading = false;
      this.voteKey = '';
    },
    async getTenantUsers() {
      try {
        const users = await tenantApi.loadTenantUsers(1)
        this.tenantUsers = users;
      } catch (error) {
        this.$handleApiError(error);
      }
    },
  },
  computed: {
    filteredTenantUsers() {
      const votedUsersEmailList = this.votes.map(u => u.user.email);

      return this.tenantUsers.filter(u => {
        const hasNotVoted = votedUsersEmailList.indexOf(u.email) === -1;
        const isNotManagerOrCoordinator = u.tenantRoles.find(t => t.tenant === this.tenant).roleSet.findIndex(r => r === 'tenant_housing_manager' || r === 'tenant_housing_coordinator') === -1;
        return hasNotVoted && isNotManagerOrCoordinator;
      });
    },
    tenant() {
      return this.$tenant
    },
    voteResultClass() {
      if (this.voteStatus === 'ENDED') {
        if (this.proposal.result === 'APPROVE') return 'approved';
        if (this.proposal.result === 'DENY') return 'denied';
        return 'moved';
      }
      return '';
    },
    alertColor() {
      if (this.proposal.result === 'APPROVE') return 'success';
      if (this.proposal.result === 'DENY') return 'error';
      return 'warning';
    },
  },
  mounted() {
    //this.userId = this.$userInfo.id
    this.getTenantUsers()
    //this.currentTenant = Object.assign({}, this.tenant);
  }
}
</script>

<style lang="scss" scoped>
</style>
