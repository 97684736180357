<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <template v-slot:activator="{ on }">
      <v-fab-transition>
        <v-btn
          v-on="on"
          color="primary"
          dark
          v-show="$vuetify.breakpoint.smAndUp || ready"
          :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">person_add</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('tenants.add_new_user') }}</span>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ $t('tenants.add_new_user') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">
          <v-text-field
            :rules="[$rules.email, $rules.spacesNotAllowed]"
            validate-on-blur
            class="mt-5 email"
            :label="$t('user_management.email')"
            autocomplete="username"
            type="email"
            @blur="validateEmailAndPhone = true"
            v-model="email"
          ></v-text-field>

          <v-text-field
            :rules="[$rules.phone]"
            validate-on-blur
            class="mt-2 phone"
            :label="$t('user_management.phone')"
            autocomplete="phone"
            type="tel"
            @blur="validateEmailAndPhone = true"
            v-model="phone"
          ></v-text-field>

          <v-text-field
            :rules="[$rules.required]"
            validate-on-blur
            class="mt-2 first-name"
            :label="$t('user_management.first_name')"
            autocomplete="first-name"
            type="text"
            v-model="firstName"
          ></v-text-field>

          <v-text-field
            :rules="[$rules.required]"
            validate-on-blur
            class="mt-2 last-name"
            :label="$t('user_management.last_name')"
            autocomplete="family-name"
            type="text"
            v-model="lastName"
          ></v-text-field>

          <v-text-field
            class="mt-2"
            :label="$t('user_management.street_address')"
            type="text"
            v-model="streetAddress"
          ></v-text-field>
          <v-text-field
            class="mt-2"
            :label="$t('user_management.postal_code')"
            type="text"
            v-model="postalCode"
          ></v-text-field>
          <v-text-field
            class="mt-2"
            :label="$t('user_management.city')"
            type="text"
            v-model="city"
          ></v-text-field>

          <v-select :rules="[$rules.required]"
                    :label="$t('user_management.role.title')"
                    :items="translatedRoles"
                    v-model="role"
                    class="mt-2 role"></v-select>

          <v-checkbox :label="$t('user_management.send_invitation_message')" v-model="invitationMessage" />

        </v-form>

        <v-alert :value="emailOrPhoneIsRequired" type="error" outlined  class="mt-4">
          {{ $t('validation.email_or_phone_required') }}
        </v-alert>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="addNewUserToTenant"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('add') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import tenantApi from "../../../api/tenant";

  export default {
    name: "AddUserDialog",
    props: {
      roles: {
        type: Array,
        default: () => [],
      }
    },
    data() {
      return {
        dialog: false,
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        streetAddress: '',
        postalCode: '',
        city: '',
        showAlert: false,
        ready: false,
        role: '',
        invitationMessage: true,
        validateEmailAndPhone: false,
      }
    },
    methods: {
      async addNewUserToTenant() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }

        this.showAlert = false;

        if (this.validateEmailAndPhone) {
          if (this.accountType !== 'COGNITO' && this.emailOrPhoneIsRequired) {
            return;
          }
        } else {
          this.validateEmailAndPhone = true;
          return;
        }

        try {
          const newUser = await tenantApi.addNewUserToTenant({
            email: this.email.length > 0 ? this.email : null,
            firstName: this.firstName,
            lastName: this.lastName,
            streetAddress: this.streetAddress,
            postalCode: this.postalCode,
            city: this.city,
            phone: this.phone.length > 0 ? this.phone : null,
            roleSet: [this.role],
          });
          this.$showSuccessNotification(this.$t('tenants.user_add_success'));

          if (this.invitationMessage) {
            if (this.email.length > 0) {
              this.$emit('send-invitation', newUser.id, 'EMAIL');
            } else if (this.phone.length > 0) {
              this.$emit('send-invitation', newUser.id, 'SMS');
            }
          }

          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('tenants.user_add_fail'));
        }
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.emailOrPhone = '';
          this.role = null;
          this.firstName = '';
          this.lastName = '';
          this.streetAddress = '';
          this.postalCode = '';
          this.city = '';
          this.showAlert = false;
          this.validateEmailAndPhone = false;
          this.$refs.form.resetValidation();
          this.$emit('on-dismiss');
        }
      }
    },
    computed: {
      translatedRoles() {
        const roles = [];
        this.roles.forEach(role => {
          if (role !== 'tenant_housing_manager') {
            roles.push({value: role, text: this.$t(`user_management.role.${role}`)});
          }
        });
        return roles;
      },
      emailOrPhoneIsRequired() {
        if (this.validateEmailAndPhone) {
          return !(this.email.length > 0 || this.phone.length > 0);
        }
        return false;
      }
    },
    mounted() {
      if (this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          this.ready = true
        }, 300);
      } else {
        this.ready = true;
      }
    }
  }
</script>

<style scoped>

</style>
