import axios from 'axios';

/*
 * Used for api calls for current user.
 * for example: profile, get my tenants, ...
 */
const userApi = {

  async createSubscription(newSubscription) {
    await axios.post('/api/user/subscription', newSubscription);
  },
  async validateSubscription(subscription) {
    const response = await axios.post('/api/user/subscription/validate', subscription);
    return response.data;
  },
  async enableNotifications() {
    await axios.post('/api/user/subscription/enable');
  },
  async disableNotifications() {
    await axios.post('/api/user/subscription/disable');
  },
  async updateEnabledNotifications(enabledNotifications) {
    const response = await axios.post('/api/user/enabled_notifications', enabledNotifications);
    return response.data;
  },
  async getMe() {
    const response = await axios.get('/api/user');
    return response.data;
  },
  async updateUserInfo(email, firstName, lastName, phone, streetAddress, postalCode, city) {
    const data = {
      email,
      firstName,
      lastName,
      phone,
      streetAddress,
      postalCode,
      city,
    }
    const response = await axios.post('/api/user', data);
    return response.data;
  },
  async uploadProfileImage(file, imageFilename) {
    const formData = new FormData();
    formData.append('file', file, imageFilename);
    const response = await axios.post(`/api/user/image`, formData);
    return response.data;
  },
  async logout() {
    await axios.post('/api/public/logout');
  },
  async sendInvitationMessage(userId, welcomeType) {
    const response = await axios.post(`/api/user/${userId}/welcome/${welcomeType}`);
    return response.data;
  },
};

export default userApi;
