<template>
  <v-container fluid fill-height class="primary py-0">
    <div class="background-image"></div>
    <v-row no-gutters class="fill-height" align="center" style="z-index: 1;" v-if="!$userInfo">
      <v-col cols="12" md="6" offset-md="3" lg="4" offset-lg="4" xl="4" offset-xl="4"
             class="login-holder px-3 pt-5 px-xl-16">

        <div class="text-center pb-5">
          <v-avatar color="white" size="120" class="elevation-5">
            <img style="height: auto; width: 80%;" src="/img/Hemman-isannointi-logo.svg"/>
          </v-avatar>
        </div>

        <div class="headline white--text text-center mb-8">{{ $t('title') }}</div>

        <v-row>
          <v-card color="transparent" elevation="0" dark style="width: 100%;" class="ma-0 pa-0">
                <username-password-login-view :uiComponentProperties="uiComponentProperties" />
          </v-card>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import UiEventBus from '../UiEventBus'
import { components as amplifyComponents, AmplifyEventBus } from 'aws-amplify-vue'
import UserService from '@/service/user.service';
import userApi from "@/api/user";
import UsernamePasswordLoginView from "@/components/Login/UsernamePasswordLoginView";

export default {
  name: "LoginView",
  data() {
    return {
      username: '',
      password: '',
      subject: '',
      uiComponentProperties: {
        rounded: false,
        text: true,
        outlined: true,
      },
      signInConfig: {
        header: this.$t('login.login'),
        isSignUpDisplayed: false,
      },
      signUpConfig: {
        defaultCountryCode: '358',
        signUpFields: [
          {
            label: this.$Amplify.I18n.get('Username'),
            key: 'username',
            required: true,
            type: 'string',
            displayOrder: 1
          },
          {
            label: this.$Amplify.I18n.get('Email'),
            key: 'email',
            required: true,
            type: 'string',
            displayOrder: 2
          },
        ],
        hiddenDefaults: ['phone_number', 'username']
      },
      authConfig: {},
      forgotPasswordConfig: {},
      isSignUp: false,
      isConfirmSignUp: false,
      isForgotPassword: false,
      isRequireNewPassword: false,
    }
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: {},
        signUpConfig: {},
        confirmSignUpConfig: {},
        confirmSignInConfig: {},
        forgotPasswordConfig: {},
        mfaConfig: {},
        requireNewPasswordConfig: {}
      };
      return Object.assign(defaults, this.authConfig)
    }
  },
  methods: {
    async getCurrentUser() {
      try {
        const cognitoUser = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.$store.commit('setLoadingOverlay', true);
        UserService.cognitoLogin(cognitoUser);
        const me = await userApi.getMe();
        UserService.setUserInfo(me);
        UiEventBus.$emit('loginSuccess', true);
      } catch (error) {
        this.$handleApiError(error);
      }
    },
  },
  created() {
    AmplifyEventBus.$on('localUser', user => {
      this.user = user;
      this.options.signInConfig.username = this.user.username;
      this.options.confirmSignInConfig.user = this.user;
      this.options.confirmSignUpConfig.username = this.user.username;
      this.options.requireNewPasswordConfig.user = this.user;
    });
    AmplifyEventBus.$on('authState', state => {
      this.isRequireNewPassword = state === 'requireNewPassword';
      this.isSignUp = state === 'signUp';
      this.isConfirmSignUp = state === 'confirmSignUp';
      this.isForgotPassword = state === 'forgotPassword';
      if (state === 'signedIn') {
        this.getCurrentUser();
      }
    });
  },
  mounted() {
    if (this.$isLoggedIn) {
      this.$store.commit('setLoadingOverlay', false);
      this.$router.push({ name: 'start' });
    }
  },
  components: {
    UsernamePasswordLoginView,
    ...amplifyComponents
  },
}
</script>

<style lang="scss" scoped>
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../assets/hemman_bg.jpg');
  background-size: cover;
  opacity: 0.1;
}

@media only screen and (min-width: 600px) {
  .background-image {
    opacity: 0.15;
  }
}
</style>
