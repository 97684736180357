<template>
  <v-col cols="12" class="pb-0" v-if="Object.keys(proposal).length > 0">
    <v-card class="proposal-card" :class="`${voteStatus} ${voteResultClass}`" :to="{name: 'single_proposal', params: { id: this.proposal.id, tab: 'info' }}">
      <v-card-title class="title">
        <div>
            <span>{{ proposal.title }}</span>
            <v-icon v-if="isUnread" color="secondary" class="ml-2 mb-2">mdi mdi-alert-circle</v-icon>
            <v-icon v-if="hasNewComments" color="secondary" class="ml-2 mb-2">mdi mdi-chat-alert</v-icon>
            <v-icon v-if="hasNewAttachments" color="secondary" class="ml-2 mb-2">mdi mdi-file-alert</v-icon>
        </div>
      </v-card-title>
      <v-card-subtitle>
        <div>{{ voteStatus === 'OPEN' ? $t('proposals.advance_voters') : $t('proposals.voters') }}: <strong>{{ proposal.votes.length }}</strong></div>
        <div>
          <template v-if="voteStatus === 'ENDED'">
            <template v-if="proposal.result === 'APPROVE'">
              <span class="success--text font-weight-bold">{{ $t('proposals.vote_results.approved') }}</span> <strong>{{ $formatDateNoTime(proposal.voteEndDate) }}</strong>
            </template>
            <template v-if="proposal.result === 'DENY'">
              <span class="error--text font-weight-bold">{{ $t('proposals.vote_results.denied') }}</span> <strong>{{ $formatDateNoTime(proposal.voteEndDate) }}</strong>
            </template>
            <template v-if="proposal.result === 'MOVE_TO_MEETING'">
              {{ $t('proposals.vote_results.moved_to_meeting') }} <strong>{{ $formatDateNoTime(proposal.voteEndDate) }}</strong>
            </template>
          </template>
          <template v-if="voteStatus === 'IN_VOTE'">
            {{ $t('proposals.voting_ends') }} <strong>{{ $formatCalendarTimeStamp(proposal.voteEndDate) }}</strong>
          </template>
          <template v-if="voteStatus === 'OPEN'">
            {{ $t('proposals.voting_starts') }} <strong>{{ $formatCalendarTimeStamp(proposal.voteStartDate) }}</strong>
          </template>
        </div>
      </v-card-subtitle>
      <v-card-text>
        {{ shortDescription }}
      </v-card-text>
      <template v-if="voteStatus === 'IN_VOTE' || voteStatus === 'OPEN'">
        <v-card-actions class="pt-0">
          <v-btn v-if="!userHasVoted && !$isHousingManager" :to="{ name: 'single_proposal', params: { id: this.proposal.id, tab: 'info', voteNow: 'vote' } }" text color="primary">{{ $t('proposals.vote') }}</v-btn>
          <v-btn :to="{ name: 'single_proposal', params: { id: this.proposal.id, tab: 'chat' } }" v-if="voteStatus === 'OPEN'" text color="secondary darken-2" class="primary--text">{{ $t('proposals.discuss') }}</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-col>
</template>

<script>
import moment from 'moment';

export default {
  name: "ProposalCard",
  props: {
    proposal: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    isUnread() {
      return this.voteStatus !== 'ENDED' && this.proposal.user?.id !== this.$userInfo.id && !this.proposal.reads.some(r => r.user?.id === this.$userInfo.id);
    },
    hasNewComments() {
      if (!this.proposal.newestComment || this.proposal.newestComment.user?.id === this.$userInfo.id) {
        return false;
      }
      const r = this.proposal.commentReads.find(r => r.user?.id === this.$userInfo.id);
      return r ? moment(this.proposal.newestComment.updated).isAfter(moment(r.date)) : true;
    },
    hasNewAttachments() {
      const totalCount = new Set(this.proposal.attachmentReads.map(r => r.targetId)).size;
      return this.proposal.attachmentReads.filter(r => r.user?.id === this.$userInfo.id).length < totalCount;
    },
    voteStatus() {
      const now = moment();
      if (now.isAfter(moment(this.proposal.voteEndDate))) {
        return 'ENDED';
      }
      if (now.isAfter(moment(this.proposal.voteStartDate))) {
        return 'IN_VOTE';
      }
      return 'OPEN';
    },
    voteResultClass() {
      if (this.voteStatus === 'ENDED') {
        if (this.proposal.result === 'APPROVE') return 'approved';
        if (this.proposal.result === 'DENY') return 'denied';
        return 'moved';
      }
      return '';
    },
    shortDescription() {
      if (this.proposal.description.length > 70) {
        return this.proposal.description.substring(0, 70) + '...';
      }
      return this.proposal.description;
    },
    userHasVoted() {
      if (this.proposal) {
        return this.proposal.votes.findIndex(vote => vote.user.id === this.$userInfo.id) > -1;
      }
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
.proposal-card {
  border-left: 6px solid #ccc;

  &.OPEN {
    border-left-color: #b9d982;
  }

  &.IN_VOTE {
    border-left-color: #004593;
  }

  &.ENDED {
    &.approved {
      border-left-color: #66bb6a;
    }
    &.denied {
      border-left-color: #F4511E;
    }
  }
}

</style>
