import 'vuetify/dist/vuetify.min.css'
import Vue from 'vue'
import i18n from './i18n.js';
import vuetify from './plugins/vuetify.plugin.js';
import Axios from 'axios' // set defaults before any other imports
import moment from 'moment';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import HelperPlugin from './plugins/helper.plugin'
import ValidationPlugin from './plugins/validation.plugin'
import RolesPlugin from './plugins/roles.plugin'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import UiEventBus from './UiEventBus'

import BackButton from './components/BackButton.vue';
import MainMenuButton from './components/MainMenuButton.vue';
import LoadingIndicator from './components/LoadingIndicator.vue';
import BasicPage from "@/components/BasicPage";

import UserService, { AUTH_TYPES } from './service/user.service';
import TenantService from './service/tenant.service';

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsmobile from './aws-exports'
import userApi from "./api/user";

Amplify.configure(awsmobile)

AmplifyModules.I18n.putVocabulariesForLanguage("fi", {
  'Username': 'Sähköposti',
  'Password': 'Salasana',
  'Forget your password? ': 'Unohditko salasanan?',
  'Reset password': 'Nollaa salasana',
  'Sign In': 'Kirjaudu sisään',
  'Enter your username': 'Sähköposti',
  'Enter your password': 'Salasana',
  'Reset your password': 'Nollaa salasanasi',
  'Back to Sign In': 'Takaisin kirjautumiseen',
  'Resend Code': 'Lähetä nollauskoodi uudestaan',
  'Send Code': 'Lähetä',
  'New Password': 'Uusi salasana',
  'Code': 'Koodi',
  'Submit': 'Tallenna',
  'Enter new password': 'Anna uusi salasana',
  'Username cannot be empty': 'Käyttäjätunnus ei saa olla tyhjä.',
  'Username/client id combination not found.': 'Käyttäjätunnusta ei löytynyt.',
  'User does not exist.': 'Väärä käyttäjätunnus tai salasana.',
  'Incorrect username or password.': 'Väärä käyttäjätunnus tai salasana.',
  'Custom auth lambda trigger is not configured for the user pool.': 'Jotain meni pieleen. Ole hyvä ja yritä uudelleen.',
  'user.completeNewPasswordChallenge is not a function': 'Jotain meni pieleen. Ole hyvä ja palaa takaisin kirjautumisen alkuun.',
});

AmplifyModules.I18n.setLanguage('fi');

Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(HelperPlugin);
Vue.use(ValidationPlugin);
Vue.use(RolesPlugin);

Vue.component('hb-back-button', BackButton);
Vue.component('hb-main-menu-button', MainMenuButton);
Vue.component('hb-loading-indicator', LoadingIndicator);
Vue.component('hb-basic-page', BasicPage);

const hasCustomBaseUrl = process.env.VUE_APP_API_BASEURL.indexOf('undefined') === -1;
if (hasCustomBaseUrl) {
  Axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL
}
Axios.defaults.withCredentials = true;
Axios.defaults.headers.common['Pragma'] = 'no-cache';
Axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store';
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.common['Accept'] = 'application/json';
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (!UserService.loggedIn && to.path !== '/' && to.path !== '/login' && to.path !== '/start') {
    sessionStorage.setItem('redirectPath', to.path);
  }
  next();
});

UiEventBus.$on('loginSuccess', (noRouteChange) => {
  if (noRouteChange)
    return;
  if (router.currentRoute.name !== 'start') {
    router.push({name: 'start'});
  }
});

UiEventBus.$on('userLoggedOut', () => {
  TenantService.clear();

  if (UserService.authType === AUTH_TYPES.COGNITO) {
    // this logout for remote (if not used e.g. local logout used then service has 'rememberme'-functionality)
    Amplify.Auth.signOut().then(() => {
      // this is client side only logout
      vue.$clearUserInfo();
      vue.$store.commit('setLoadingOverlay', true);
      vue.$store.commit('setLoggedIn', false);
      vue.$router.push({ name: 'login' });
    })
  } else {
    userApi.logout();
    vue.$clearUserInfo();
    vue.$store.commit('setLoadingOverlay', true);
    vue.$store.commit('setLoggedIn', false);
    vue.$router.push({ name: 'login' });
  }
});

async function tryRefreshSession() {
  const session = await Amplify.Auth.currentSession();
  const accessTokenExpires = moment(session.accessToken.payload.exp * 1000);
  const sessionInfo = {
    sub: session.idToken.payload.sub,
    email: session.idToken.payload.email,
    roles: '',
    accessToken: session.accessToken.jwtToken,
    idToken: session.idToken.jwtToken,
    refreshToken: session.refreshToken.token,
    accessTokenExpires
  };
  UserService.setSession(sessionInfo);
}

Axios.interceptors.request.use( async(config) => {
  UiEventBus.$emit('addProgress');
  if (UserService.authType === AUTH_TYPES.COGNITO) {
    // Check that session is valid, and if not, try to refresh the it
    let cognitoSession = UserService.getSession();
    let isValid = cognitoSession != null && moment().add(5, 'minutes').isBefore(moment(cognitoSession.accessTokenExpires));
    if (!isValid) {
      try {
        await tryRefreshSession()
        cognitoSession = UserService.getSession();
        config.headers.Authorization = 'Bearer ' + cognitoSession.idToken;
      } catch (error) {
        console.log(error);
      }
    } else if (isValid && cognitoSession != null) {
      config.headers.Authorization = 'Bearer ' + cognitoSession.idToken;
    }
  }
  return config;
},(error) => {
  UiEventBus.$emit('removeProgress');
  return Promise.reject(error)
});

async function checkSession() {
  try {
    await userApi.getMe();
  } catch (error) {
    if (error.response && (error.response.status === 401)) {
      UserService.clearUserInfo();
      router.push({ name: 'login' });
    }
  }
}

let vue;

async function init() {
  await checkSession();

  Axios.interceptors.response.use((response) => {
    UiEventBus.$emit('removeProgress');
    return response
  }, (error) => {
    if (error.response && (error.response.status === 401)) {
      UserService.clearUserInfo();
      if (router.currentRoute.name !== 'login' && router.currentRoute.name !== 'passwordlogin') {
        router.push({ name: 'login' });
      }
    }

    UiEventBus.$emit('removeProgress');
    return Promise.reject(error)
  });

  if (process.env.VUE_APP_SENTRY_DNS && process.env.NODE_ENV !== 'development') {
    const tracingOrigins = (process.env.VUE_APP_SENTRY_TRACING_ORIGINS || '').split(',');
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DNS,
      debug: true,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [...tracingOrigins, /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      logErrors: true,
    });
  } else {
    console.warn('No env.VUE_APP_SENTRY_DNS provided')
  }

  vue = new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');
}

init();
