<template>
  <v-dialog v-model="dialog" max-width="600" @keydown.esc="cancel" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ $t('proposals.decision_number') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="scroll-content">
        <v-card-text class="pt-5">
          <v-text-field outlined v-model="decisionNumber" :placeholder="`${$t('proposals.type_in_decision_number')}...`" :label="$t('proposals.decision_number')" hide-details />
          <v-textarea outlined v-model="resultText" :placeholder="`${$t('proposals.type_in_result_text')}...`" :label="$t('proposals.result_text')" style="margin-top: 8px" hide-details></v-textarea>
        </v-card-text>
        <v-card-text class="pb-0 mb-0" v-if="proposal.attachments.length > 0">{{ $t('proposals.which_files_to_pdf') }}</v-card-text>
        <v-card-text class="pt-2">
          <v-list three-line class="py-0">
            <template v-for="(file, index) in proposal.attachments">
              <FilesListItem :file="file"
                             :key="file.id"
                             :vote-status="voteStatus"
                             :showMenu="false"
                             :selectedFiles="includedAttachments"
                             @file-selected="handleFileSelected"
                             @open-image="handleImageView"
                             @open-video="handleVideoPlay"
                             :disable-attachment-loading="attachmentLoading"
                             @loading-attachment-link="attachmentLoading = true"
                             @loaded-attachment-link="attachmentLoading = false"/>
              <v-divider v-if="index < proposal.attachments.length - 1" :key="`${file.id}_divider`"></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </div>
      <v-divider></v-divider>

      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn :class="$vuetify.breakpoint.xsOnly ? 'mx-0' : ''"
               :block="$vuetify.breakpoint.xsOnly"
               color="default"
               :disabled="loading"
               @click.native="cancel">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn :disabled="decisionNumber.length === 0"
               :class="$vuetify.breakpoint.xsOnly ? 'mb-2 mx-0' : ''"
               :block="$vuetify.breakpoint.xsOnly"
               :loading="loading"
               color="primary"
               @click.native="saveDecisionNumber">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tenantApi from "@/api/tenant";
import FilesListItem from "@/components/Tenant/Common/FilesListItem";

export default {
  name: 'DecisionNumberDialog',
  components: {FilesListItem},
  props: {
    proposal: {
      type: Object,
      default: () => {},
    },
    voteStatus: {
      type: String,
      default: 'OPEN',
    }
  },
  data() {
    return {
      dialog: false,
      decisionNumber: '',
      resultText: '',
      loading: false,
      attachmentLoading: false,
      includedAttachments: [],
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.decisionNumber = this.proposal.decisionNumber || '';
      this.resultText = this.proposal.resultText || this.$t(`proposals.result_text_templates.${this.proposal.result}`, this.proposal);
      this.includedAttachments = [ ...this.proposal.includedAttachments ];
    },
    handleFileSelected(fileId, selected) {
      if (selected) {
        if (!this.includedAttachments.includes(fileId)) {
          this.includedAttachments.push(fileId);
        }
      } else {
        this.includedAttachments = this.includedAttachments.filter(id => id !== fileId);
      }
    },
    async saveDecisionNumber() {
      if (!this.decisionNumber) return;

      try {
        this.loading = true;
        const proposal = Object.assign({}, this.proposal);
        proposal.decisionNumber = this.decisionNumber;
        proposal.resultText = this.resultText;
        proposal.includedAttachments = this.includedAttachments;
        await tenantApi.updateProposal(proposal.id, proposal);
        this.$emit('decision-number-saved');
        this.dialog = false;
      } catch (err) {
        this.$handleApiError(err);
      }
      this.loading = false;
    },
    cancel() {
      this.dialog = false;
    },
    handleImageView(imageUrl) {
      this.$emit('handle-image-view', imageUrl)
    },
    handleVideoPlay(video) {
      this.$emit('handle-video-play', video);
    },
  },
}
</script>

<style scoped>

.scroll-content {
  max-height: calc(100vh - 360px);
  overflow: auto;
}

</style>
