const messages = {
  // English
  en: {},
  // Finnish
  fi: {
    title: 'hemman isännöinti',
    login: {
      username: 'Käyttäjätunnus',
      password: 'Salasana',
      login: 'Kirjaudu',
      sign_in: 'Kirjaudu sisään',
      login_failed: 'Kirjautuminen epäonnistui',
      logout: 'Kirjaudu ulos',
      forgot_password: 'Unohditko salasanasi?',
      sign_in_as_tenant: 'Kirjaudu hallituksen jäsenenä',
      sign_in_as_housing_manager: 'Kirjaudu isännöitsijänä',
      sign_in_with_code: 'Jos sinulla on jo koodi kirjaudu sisään tästä',
      back_to_code_sending: 'Takaisin koodin lähettämiseen',
      user_not_found:'Puhelinnumeroa tai sähköpostiosoitetta ei löydy.',
      code_is_not_right: 'Kirjautumiskoodi ei ole oikein',
      reset_password: 'Nollaa salasanasi.',
      by_clicking_you_agree: 'Painamalla "Kirjaudu sisään"-painiketta hyväksyt hemman isännöinnin',
      by_clicking_request_you_agree: 'Painamalla "Pyydä kirjautumisavainta"-painiketta hyväksyt hemman isännöinnin',
      send_code_advice: 'Anna puhelinnumerosi tai sähköpostiosoitteesi, niin saat kirjautumiskoodin viestinä.',
      code_sent_to_phone: 'Kirjautumiskoodi lähetetty puhelinnumeroosi.',
      code_sent_to_email: 'Kirjautumiskoodi lähetetty sähköpostiisi.',
      login_code: 'Kirjautumiskoodi',
      to_learn_more_about_privacy: 'Voit lukea kuinka hemman isännöinti kerää, käyttää, jakaa ja suojelee henkilökohtaisia tietojasi hemman isännöinnin',
      terms_of_service: 'käyttöehdot',
      privacy_policy: 'tietosuojakäytännöstä',
      send_code: 'Lähetä koodi sähköpostiin',
      back_to_sign_in: 'Takaisin kirjautumiseen',
      code: 'Koodi',
      new_password: 'Uusi salasana',
      change_password: 'Vaihda salasanasi',
      resend_code: 'Lähetä koodi uudelleen',
      password_change_required: 'Sinun tulee antaa uusi salasana jatkaaksesi sovelluksen käyttöä.',
      logging_in: 'Kirjaudutaan sisään',
      logging_out: 'Kirjaudutaan ulos',
      send_code_for_login:'Pyydä kirjautumisavainta',
      code_phone_or_email: 'Puhelinnumero tai sähköpostiosoite',
      reset_password_advice: 'Anna tunnuksesi, niin saat ohjeet salasanan vaihtamista varten sähköpostiisi.',
    },
    start: {
      title: 'Aloitus',
    },
    user_management: {
      title: 'Käyttäjien hallinta',
      search: 'Hae käyttäjistä',
      roles: 'Roolit',
      user: 'Käyttäjä',
      name: 'Nimi',
      members: 'Jäsenet',
      add_role_to_user: 'Lisää käyttäjälle rooli',
      manage_user: 'Hallitse käyttäjää',
      manage_user_roles: 'Hallitse käyttäjän rooleja',
      disable: 'Poista käyttäjä käytöstä',
      enable: 'Aseta käyttäjä aktiiviseksi',
      you_cannot_disable_yourself: 'Et voi poistaa omaa tunnustasi käytöstä.',
      you_cannot_enable_yourself: 'Et voi asettaa omaa tunnustasi aktiiviseksi.',
      user_state_set_to_enabled: 'Käyttäjä asetettu aktiiviseksi.',
      user_state_set_to_disabled: 'Käyttäjä poistettu käytöstä.',
      user_state_change_failed: 'Käyttäjän tilan vaihtaminen epäonnistui. Ole hyvä ja yritä uudelleen.',
      user_roles_updated: 'Käyttäjän roolit päivitetty.',
      user_roles_update_failed: 'Käyttäjän roolien päivittäminen epäonnistui. Ole hyvä ja yritä uudelleen.',
      create_new_user: 'Luo uusi käyttäjä',
      download_excel_button: 'Lataa käyttäjälista',
      download_excel: 'Lataa hallitusten jäsenten listaus Excel-tiedostona',
      user_type: 'Tilin tyyppi',
      shared_user: 'Jaettu käyttäjä',
      shared_user_hint: 'Valitse tämä kun kyseessä on laitekohtainen tili, jota käyttää useampi henkilö',
      user_info: 'Käyttäjän tiedot',
      user_roles: 'Käyttäjän roolit',
      user_roles_in_app: 'Käyttäjän rooli sovelluksessa',
      machine_id: 'Laitetunniste',
      email: 'Sähköposti',
      password: 'Salasana',
      first_name: 'Etunimi',
      last_name: 'Sukunimi',
      phone: 'Puhelinnumero',
      street_address: 'Katuosoite',
      postal_code: 'Postinumero',
      city: 'Postitoimipaikka',
      email_or_phone: 'Sähköposti tai puhelinnumero',
      user_created_success: 'Käyttäjä on luotu onnistuneesti.',
      user_creation_failed: 'Käyttäjän luominen epäonnistui. Ole hyvä ja yritä uudelleen.',
      no_username_or_email_given: 'Käyttäjälle luominen epäonnistui, koska käyttäjälle ei annettu sähköpostia tai käyttäjänimeä.',
      user_exists_with_username: 'Antamallasi käyttäjänimellä tai sähköpostiosoitteella on jo käyttäjä olemassa.',
      no_password_for_agent_user_given: 'Et määrittänyt salasanaa jaetulle käyttäjälle.',
      constraint_exception: {
        roleSet: 'Käyttäjälle tulee määritellä ainakin yksi rooli.',
      },
      filter: 'Suodata',
      filtered: 'Suodatettu',
      show_all: 'Näytetään kaikki',
      selections: 'valintaa',
      role: {
        title: 'Rooli',
        admin: 'Järjestelmän ylläpitäjä',
        basic: 'Järjestelmän peruskäyttäjä',
        agent: 'Laitekäyttäjä',
        tenant_admin: 'Ylläpitäjä',
        tenant_basic: 'Peruskäyttäjä',
        tenant_housing_manager: 'Isännöitsijä',
        tenant_housing_coordinator: 'Asumiskoordinaattori',
        tenant_chairman: 'Hallituksen puheenjohtaja',
        tenant_member: 'Hallituksen jäsen',
        tenant_substitute_member: 'Hallituksen varajäsen',
      },
      user_settings: 'Asetukset',
      user_info_updated: 'Käyttäjän tiedot päivitetty',
      user_info_update_failed: 'Käyttäjän tietojen päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      edit_user_settings: 'Muokkaa käyttäjän tietoja',
      send_invitation_message: 'Lähetä käyttäjälle kutsuviesti',
      send_invitation_email: 'Lähetä kutsuviesti sähköpostilla',
      send_invitation_sms: 'Lähetä kutsuviesti tekstiviestillä',
      welcome_message_type: {
        email: 'Sähköpostitse',
        phone: 'Puhelimitse',
      },
      account_type: {
        housing_manager: 'Isännöitsijä',
        housing_association: 'Taloyhtiön hallitus',
      },
      user_tenants: 'Käyttäjän taloyhtiöt',
      add_new_tenant: 'Lisää käyttäjälle taloyhtiö',
      tenant_role: 'Rooli taloyhtiössä',
      no_tenants: 'Ei löytynyt taloyhtiöitä',
    },
    tenants: {
      title: 'Taloyhtiöt',
      add_new_tenant: 'Lisää uusi taloyhtiö',
      add_new_user: 'Kutsu uusi käyttäjä',
      organization_name: 'Taloyhtiön nimi',
      organization: 'Taloyhtiö',
      city: 'Paikkakunta',
      tenant_created_success: 'Uusi taloyhtiö luotu',
      tenant_creation_failed: 'Uuden taloyhtiön luominen ei onnistunut. Ole hyvä ja yritä uudelleen',
      tenant_update_failed: 'Taloyhtiön päivitys ei onnistunut. Ole hyvä ja yritä uudelleen.',
      tenant_update_success: 'Taloyhtiö päivitetty.',
      organization_update_failed: 'Taloyhtiön tietojen päivitys ei onnistunut. Ole hyvä ja yritä uudelleen.',
      organization_update_success: 'Taloyhtiön tiedot päivitetty.',
      search: 'Hae taloyhtiöistä',
      status: 'Tila',
      settings: 'Asetukset',
      users: 'Käyttäjät',
      tenant_users: 'Taloyhtiöön linkitetyt käyttäjät',
      manage_tenant: 'Hallitse taloyhtiötä',
      enable: 'Aseta aktiiviseksi',
      disable: 'Poista käytöstä',
      edit_tenant: 'Muokkaa taloyhtiötä',
      edit_organization: 'Muokkaa taloyhtiötä',
      no_tenants: 'Ei taloyhtiötä',
      no_tenants_help: 'Et kuulu yhteenkään taloyhtiöön. Ole hyvä yritä uudelleen. Jos sivun uudelleen lataaminen ei auta, niin ota yhteys taloyhtiösi ylläpitäjään.',
      statuses: {
        ACTIVE: 'Aktiivinen',
        INACTIVE: 'Inaktiivinen',
      },
      choose_organization: 'Valitse taloyhtiö',
      user_add_success: 'Käyttäjän lisätty onnistuneesti.',
      user_add_fail: 'Käyttäjän lisääminen epäonnistui. Ole hyvä ja yritä uudelleen.',
      delete_user: 'Poista käyttäjä',
      user_delete_success: 'Käyttäjä poistettu taloyhtiöltä onnistuneesti.',
      user_delete_fail: 'Käyttäjän poistaminen taloyhtiöltä epäonnistui. Ole hyvä ja yritä uudelleen.',
      management_title: 'Taloyhtiön hallinta',
      organization_users: 'Taloyhtiön käyttäjät',
      all_users_for_app: 'Kaikki sovelluksen käyttäjät',
      no_users_in_app: 'Sovelluksesta ei löytynyt yhtään käyttäjää.',
      no_users_in_tenant: 'Taloyhtiöstä ei löytynyt yhtään käyttäjää.',
      current_tenant: 'Valittu taloyhtiö',
    },
    user_profile: {
      title: 'Omat asetukset',
      user_account: 'Käyttäjätili',
      push_notifications: 'Push-ilmoitukset',
      push_notifications_explained: 'Sallimalla push-ilmoitukset sovellus voi lähettää sinulle ilmoituksia vaikka sovellus olisi pois päältä.',
      account_notifications: "Käyttäjätilin ilmoitusasetukset",
      account_notifications_explained: "Käyttäjätilin asetus vaikuttaa kaikkiin laitteisiin joilla käytät sovellusta.",
      device_notifications: "Laitteen ilmoitusasetukset",
      allow_notifications: 'Salli ilmoitukset',
      allow_notifications_proposal_events: 'Salli ilmoitukset aloitteen tapahtumista',
      allow_notifications_chat: 'Salli ilmoitukset uusista kommenteista',
      allow_email_notifications_chat: 'Salli sähköposti-ilmoitukset uusista kommenteista',
      notifications_denied_in_browser_settings: 'Sinun tulisi sallia ilmoitukset selaimen asetuksista.',
      notifications_allowed_in_browser_settings: 'Ilmoitukset sallittu selaimen asetuksista. Voit estää ne selaimen asetuksista.',
      notifications_not_asked: 'Ilmoitukset eivät ole sallittu selaimen asetuksista. Kun sallit ne tässä, laitteesi kysyy sinulta vielä lupaa lähettää ilmoituksia.',
      email_notifications: 'Sähköposti-ilmoitukset',
      email_notifications_explained: 'Sallimalla sähköposti-ilmoitukset sovellus voi lähettää sinulle ilmoituksia sähköpostitse.',
      email_notifications_require_email: 'Sinun tulee asettaa omiin tietoihisi sähköpostiosoitteesi, jotta voit sallia sähköposti-ilmoitukset.',
      email_set_email_address: 'Muokkaa tietojasi',
      allow_email_notifications: 'Salli sähköposti-ilmoitukset aloitteen tapahtumista',
      sms_notifications: 'Tekstiviesti-ilmoitukset',
      sms_notifications_explained: 'Sallimalla tekstiviesti-ilmoitukset sovellus voi lähettää sinulle ilmoituksia tekstiviesteillä.',
      sms_notifications_require_phone_number: 'Sinun tulee asettaa omiin tietoihisi puhelinnumerosi, jotta voit sallia tekstiviesti-ilmoitukset.',
      sms_set_phone_number: 'Muokkaa tietojasi',
      allow_sms_notifications: 'Salli tekstiviesti-ilmoitukset aloitteen tapahtumista',
      change_password: 'Vaihda salasana',
      old_password: 'Nykyinen salasanasi',
      new_password: 'Uusi salasanasi',
      new_password_again: 'Uusi salasanasi uudelleen',
      new_password_guide: 'Valitse vahva salasana, joka ei ole käytössä muualla.',
      new_passwords_has_to_match: 'Tämän tulee olla sama kuin uusi salasanasi',
      password_must_be_at_least_6_characters: 'Salasanan tulee olla vähintään 6 merkkiä pitkä.',
      errors: {
        error_updating_push_notification_settings: 'Ilmoitusasetusten asettaminen epäonnistui. Ole hyvä ja yritä uudelleen myöhemmin.',
        new_passwords_do_not_match: 'Uusi salasanasi ja uudelleen kirjoitettu uusi salasanasi eivät vastaa toisiaan.',
        old_password_must_be_at_least_6_characters: 'Antamasi nykyinen salasanasi on väärin.',
        new_password_must_be_at_least_6_characters: 'Uusi salasanasi tulee olla vähintään 6 merkkiä pitkä.',
        failed_to_change_password: 'Salasanan vaihto epäonnistui.',
        NotAuthorizedException: 'Antamasi nykyinen salasana on väärin.',
        InvalidParameterException: 'Antamasi salasanat ovat virheellisiä.',
        InvalidPasswordException: 'Uusi salasanasi pitää olla vähintään 6 merkkiä pitkä. Salasanan tulee sisältää isoja sekä pieniä kirjaimia, vähintään yksi numero ja vähintään yksi erikoismerkki.',
        LimitExceededException: 'Olet yrittänyt vaihtaa salasanan liian monta kertaa lyhyessä ajassa. Ole hyvä ja odota hetki ennen kuin yrität uudelleen.',
      },
      change_image: 'Vaihda kuva',
      edit_profile: 'Muokkaa tietoja',
    },
    validation: {
      field_is_required: 'Kenttä on pakollinen',
      email_is_required: 'Kentän tulee olla sähköpostiosoite',
      phone_number_is_required: 'Kentän tulee olla puhelinnumero',
      spaces_are_not_allowed: 'Välilyönnit eivät ole sallittuja',
      check_all_required_fields: 'Tarkista, että olet täyttänyt kaikki pakolliset kentät',
      either_email_or_phone_required: 'Sinun tulee antaa käyttäjälle joko sähköpostiosoite tai puhelinnumero',
      field_is_required_for_housing_manager: 'Kenttä on pakollinen isännöitsijälle',
      user_details_already_in_use: 'Tällä tiedolla on jo olemassa käyttäjä.',
      email_or_phone_required: 'Sinun tulee antaa joko sähköposti tai puhelinnumero.',
    },
    errors: {
      error_loading_users: 'Käyttäjien hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_in_voting: 'Ääntä ei voitu rekisteröidä. Ole hyvä ja yritä uudelleen.',
    },
    menu_subheaders: {
      organization_admin: 'Taloyhtiön ylläpito',
      application_admin: 'Sovelluksen ylläpito',
    },
    proposals: {
      title: 'Aloite | Aloitteet',
      search: 'Hae aloitteista',
      vote_results: {
        approved: 'Hyväksytty',
        denied: 'Hylätty',
        moved_to_meeting: 'Käsittely siirretty kokoukseen',
      },
      long_vote_results: {
        APPROVE: 'Aloite hyväksytty',
        DENY: 'Aloite hylätty',
        MOVE_TO_MEETING: 'Käsittely siirretty kokoukseen',
      },
      voting_ends: 'Äänestys päättyy',
      voting_starts: 'Äänestys alkaa',
      vote: 'Äänestä',
      discuss: 'Keskustele',
      new_proposal: 'Luo uusi aloite',
      no_proposals: 'Ei aloitteita',
      no_proposals_found: 'Ei löytynyt yhtään aloitetta. Aloita luomalla ensimmäinen aloite painamalla "Luo uusi aloite"-painiketta. Muussa tapauksessa yritä ladata sivu uudelleen.',
      new_file: 'Lisää uusi tiedosto',
      no_files: 'Ei tiedostoja',
      no_files_found: 'Ei löytynyt yhtään tiedostoa. Aloita lisäämällä ensimmäinen tiedosto painamalla "Lisää tiedosto"-painiketta. Muussa tapauksessa yritä ladata sivu uudelleen.',
      no_files_found_and_vote_start: 'Ei löytynyt yhtään tiedostoa. Et voi lisätä aloitteelle enää uusia tiedostoja äänestyksen alun jälkeen.',
      subject: 'Päätettävä asia',
      description: 'Kuvaus',
      file_title: 'Tiedoston nimi',
      file_description: 'Tiedoston kuvaus',
      proposal_details: 'Aloitteen tiedot',
      proposal_timelimits: 'Aloitteen aikarajat',
      proposal_distribution: 'Aloitteen jakeluryhmät',
      discussion_time: 'Keskusteluaika (vuorokausissa)',
      voting_time: 'Äänestysaika (vuorokausissa)',
      attachments: {
        title: 'LIITETIEDOSTOT',
        button_text: 'Lisää tiedosto',
      },
      publish: 'Julkaise aloite',
      save_changes: 'Tallenna muutokset',
      info_and_voting: 'Tiedot ja äänestys',
      discussion: 'Päätöskeskustelu',
      distribution: 'Jakelu',
      advance_voters: 'Ennakkoäänestäneitä',
      voters: 'Äänestäneitä',
      voting_score: 'Äänestystilanne',
      voting_score_manager: 'Äänestystilanne ja hallituksen jäsenen puolesta äänestäminen',
      voting_result: 'Äänestystulos',
      errors: {
        error_validation_message: 'Tarkista, että olet täyttänyt kaikki pakolliset kentät.',
      },
      chat: {
        show_who_has_seen: 'Kommentin nähneet henkilöt',
        enter_a_message: 'Syötä viesti',
        delete_a_message: 'Poista viesti',
        no_chat_messages_title: 'Ei viestejä',
        no_chat_messages_text: 'Ei löytynyt yhtään viestiä. Aloita keskustelu kirjoittamalla viestisi alla olevaan viestikenttään. Muussa tapauksessa yritä ladata sivu uudelleen.',
        own_chat_message_delete_confirm: 'Viesti poistettu!',
        chat_message_deleted_text_in_message: 'Viesti poistettu.',
        delete_dialog: {
          message_delete_dialog_title: 'Poista viesti',
          message_delete_dialog_text: 'Haluatko varmasti poistaa tämän viestin?',
          delete_yes: 'Poista',
          delete_no: 'Älä poista',
        },
        show_who_has_seen_dialog: {
          close_button: 'Sulje',
        },
        proposal_cannot_be_commented: 'Aloitetta ei voi enää kommentoida',
        just_right_now: 'Juuri nyt',
        file_added: 'Tiedosto lisätty',
        file_deleted: 'Tiedosto poistettu',
        file_deleted_suffix: '(poistettu)',
        housing_manager_voted: 'Isännöitsijä äänesti henkilön',
        housing_coordinator_voted: 'Asumiskoordinaatti äänesti henkilön',
        for_user: 'puolesta',
        comment: 'kommentti',
      },
      edit_proposal: 'Muokkaa aloitetta',
      delete_proposal: 'Poista aloite',
      edit_times_help: 'Keskusteluun varattu aika tarkoittaa kuinka monta päivää päätettävästä asiasta on aikaa keskustella aloitteen luomisesta. Äänestykseen varattu aika tarkoittaa kuinka monta päivää aloitteesta voidaan äänestää keskusteluun varatun ajan päätyttyä.',
      proposal_delete_dialog: {
        title: 'Aloitteen poisto',
        message: 'Haluatko varmasti poistaa tämän aloitteen?',
        delete_yes: 'Poista',
        delete_no: 'Älä poista',
        proposal_delete_confirmed: 'Aloite poistettu.',
      },
      file_delete_dialog: {
        title: 'Tiedoston poisto',
        message: 'Haluatko varmasti poistaa tämän tiedoston?',
        delete_yes: 'Poista',
        delete_no: 'Älä poista',
        file_delete_confirmed: 'Tiedosto poistettu.',
      },
      show_who_has_downloaded: 'Tiedoston ladanneet henkilöt',
      nobody_has_downloaded_file: 'Ketään ei ole vielä ladannut tiedostoa',
      nobody_has_seen_comment: 'Ketään ei ole vielä nähnyt kommenttia',
      vote_in_advance: 'Äänestä ennakkoon',
      voting_starts_in: 'Äänestäminen alkaa',
      voting_ends_in: 'Äänestäminen päättyy',
      vote_in_advance_help: 'Voit antaa ennen sitä alustavan äänen, jota voit muuttaa vielä varsinaisen äänestyksen alettua. Ennakkoääntä ei tarvitse käydä erikseen enää vahvistamassa äänestyksen alettua.',
      vote_has_been_registered: 'Äänesi on rekisteröity.',
      no_votes_yet: 'Kukaan ei ole vielä äänestänyt.',
      you_have_already_voted: 'Olet jo äänestänyt',
      vote_or_skip: 'Kirjoita kommentti tai ohita kommentointi!',
      voted: 'Äänestänyt',
      leave_comment: 'Jätä kommentti',
      housing_manager_info_for_voting: 'Äänestyksen ollessa auki voit isännöitsijänä tai asumiskoordinaattorina äänestää sellaisten henkilöiden puolesta, jotka eivät vielä ole ääntä antaneet.',
      not_voted_users: 'Äänestämättä',
      voted_users: 'Äänestäneet',
      you_are_voting: 'Olet äänestämässä ',
      for_user_1: 'henkilön',
      for_user_2: 'puolesta',
      you_cannot_alter_your_vote_after_this: 'Et voi muuttaa ääntäsi enää sen jälkeen kun olet painanut "Tallenna" tai "Ohita kommentointi".',
      vote_keys_for_buttons: {
        APPROVE: 'Kyllä',
        DENY: 'Ei',
        MOVE_TO_MEETING: 'Siirrä kokoukseen',
      },
      comment: 'Kommentti',
      write_comment: 'Kirjoita kommentti',
      comment_when_voting_help: 'Halutessasi voit vielä jättää kommentin antamastasi äänestä.',
      decision_date: 'Päätöspäivä',
      give_decision_number: 'Anna päätösnumero',
      add_time_for_voting: 'Avaa aloite uudelleen',
      add_comments_to_report: 'Liitä keskustelu pöytäkirjaan',
      generate_report: 'Lataa pöytäkirja',
      decision_number: 'Päätösnumero',
      type_in_decision_number: 'Syötä päätösnumero',
      result_text: 'Päätösteksti',
      type_in_result_text: 'Syötä päätösteksti',
      result_text_templates: {
        APPROVE: 'Päätettiin hyväksyä aloite \'{title}\'.',
        DENY: 'Päätettiin hylätä aloite \'{title}\'.',
        MOVE_TO_MEETING: 'Päätettiin siirtää aloitteen \'{title}\' käsittely kokoukseen.',
      },
      which_files_to_pdf: 'Valitse pöytäkirjalle lisättävät tiedostot',
      files: {
        title: 'Tiedostot',
        download_file: 'Lataa tiedosto',
        who_has_seen_the_file: 'Tiedoston nähneet henkilöt',
        delete_file: 'Poista tiedosto',
      },
      add_file_dialog: {
        title: 'Lisää tiedosto',
        add_file: 'Lisää tiedosto',
        cancel: 'Peruuta',
        file_name: 'Tiedostonimi',
        file_description: 'Kirjoita kommentti',
      },
      add_image_to_chat_dialog: {
        add_image: 'Lähetä kuva',
        title: 'Lähetä kuva',
        cancel: 'Peruuta'
      },
      uploading_file: 'Ladataan tiedostoa',
      creator: 'Aloitteen laatija',
      about_voting_process: {
        title: 'Tietoa äänestysprosessista',
        open_proposal: 'Aloitteen ensimmäisessä vaiheessa se on vielä avoin, tällöin aloitteesta voidaan keskustella, sitä voidaan muokata ja sille voidaan lisätä liitetiedostoja. Aloitteen ollessa avoin, sitä voi kuitenkin jo äänestää ennakkoon. Ennakkoääntä voi myöhemmin vielä vaihtaa, mutta sitä ei tarvitse erikseen käydä vahvistamassa varsinaisen äänestyksen alettua.',
        in_voting_proposal: 'Äänestyksen alettua aloite sulkeutuu kommentoinnin, muokkaamisen ja tiedostojen lisäämisen sekä poistamisen osalta. Tällöin rekisteröidyt äänet ovat lopullisia. Halutessaan aloitetta voi vielä kommentoida äänestämisen yhteydessä, jos esimerkiksi haluaa antaa tarkentavaa tietoa miksi on äänestänyt kuten äänesti.',
        closed_proposal: 'Äänestyksen loputtua aloite sulkeutuu kokonaan ja aloitteesta muodostuu päätös, jos päätösvaltaisuuteen tarvittavat kriteerit täyttyvät. Päätökselle annetaan päätösnumero ja päätöksestä muodostetaan pöytäkirja, joka sisältää kaiken oleellisen tiedon päätöksestä, kuten tarvittavat liitetiedostot ja keskustelun.',
      }
    },
    threads: {
      title: 'Yleinen keskustelu | Yleiset keskustelut',
      search: 'Hae keskusteluista',
      discuss: 'Keskustele',
      new_thread: 'Luo uusi keskustelu',
      edit_thread: 'Muokkaa keskustelua',
      close_thread: 'Sulje keskustelu',
      thread_closed: 'Keskustelu suljettu',
      open_thread: 'Avaa keskustelu',
      thread_opened: 'Keskustelu avattu',
      delete_thread: 'Poista keskustelu',
      created_date: 'Aloitettu',
      newest_comment_date: 'Uusin viesti',
      no_threads: 'Ei keskusteluja',
      no_threads_found: 'Ei löytynyt yhtään keskustelua. Aloita luomalla ensimmäinen keskustelu painamalla "Luo uusi keskustelu"-painiketta. Muussa tapauksessa yritä ladata sivu uudelleen.',
      new_file: 'Lisää uusi tiedosto',
      no_files: 'Ei tiedostoja',
      no_files_found: 'Ei löytynyt yhtään tiedostoa. Aloita lisäämällä ensimmäinen tiedosto painamalla "Lisää tiedosto"-painiketta. Muussa tapauksessa yritä ladata sivu uudelleen.',
      no_files_found_and_discussion_end: 'Ei löytynyt yhtään tiedostoa. Et voi lisätä keskusteluun enää uusia tiedostoja keskusteluajan päättymisen jälkeen.',
      subject: 'Keskustelun aihe',
      description: 'Keskustelun aloitus',
      file_title: 'Tiedoston nimi',
      file_description: 'Tiedoston kuvaus',
      thread_details: 'Keskustelun tiedot',
      thread_timelimits: 'Keskustelun aikarajat',
      thread_distribution: 'Keskustelun jakeluryhmät',
      discussion_time: 'Keskusteluaika (vuorokausissa)',
      attachments: {
        title: 'LIITETIEDOSTOT',
        button_text: 'Lisää tiedosto',
      },
      publish: 'Julkaise keskustelu',
      save_changes: 'Tallenna muutokset',
      discussion: 'Keskustelu',
      distribution: 'Jakelu',
      uploading_file: 'Ladataan tiedostoa',
      creator: 'Keskustelun aloittaja',
      errors: {
        error_validation_message: 'Tarkista, että olet täyttänyt kaikki pakolliset kentät.',
      },
      chat: {
        show_who_has_seen: 'Viestin nähneet henkilöt',
        enter_a_message: 'Syötä viesti',
        delete_a_message: 'Poista viesti',
        no_chat_messages_title: 'Ei viestejä',
        no_chat_messages_text: 'Ei löytynyt yhtään viestiä. Aloita keskustelu kirjoittamalla viestisi alla olevaan viestikenttään. Muussa tapauksessa yritä ladata sivu uudelleen.',
        own_chat_message_delete_confirm: 'Viesti poistettu!',
        chat_message_deleted_text_in_message: 'Viesti poistettu.',
        delete_dialog: {
          message_delete_dialog_title: 'Poista viesti',
          message_delete_dialog_text: 'Haluatko varmasti poistaa tämän viestin?',
          delete_yes: 'Poista',
          delete_no: 'Älä poista',
        },
        show_who_has_seen_dialog: {
          close_button: 'Sulje',
        },
        thread_cannot_be_commented: 'Keskusteluun ei voi lisätä uusia viestejä',
        just_right_now: 'Juuri nyt',
        file_added: 'Tiedosto lisätty',
        file_deleted: 'Tiedosto poistettu',
        file_deleted_suffix: '(poistettu)',
      },
      edit_times_help: 'Keskusteluun varattu aika tarkoittaa kuinka monta päivää aiheesta voidaan keskustella.',
      files: {
        title: 'Tiedostot',
        download_file: 'Lataa tiedosto',
        who_has_seen_the_file: 'Tiedoston nähneet henkilöt',
        delete_file: 'Poista tiedosto',
      },
    },
    push_notifications: {
      do_you_want_to_allow_push_notifications: "Haluatko sallia ilmoitukset?",
      push_notifications_explained: "Sovellus lähettää ilmoituksia aloitteisiin liittyvistä tapahtumista.",
      info_for_agreeing: "Kun painat \"Hyväksyn ilmoitukset\", niin laitteesi pyytää vielä erikseen lupaa ilmoitusten lähettämiseen.",
      info_for_declining: 'Jos et juuri nyt halua ilmoituksia, mutta muutat mielesi myöhemmin, saat ne päälle "Omat asetukset"-sivulta.',
      i_do_not_agree: "En halua",
      i_agree: "Hyväksyn ilmoitukset"
    },
    cancel: 'Peruuta',
    save: 'Tallenna',
    search: 'Hae',
    try_again: 'Yritä uudelleen',
    update_available: 'Päivitys saatavilla',
    install_update: 'Asenna',
    generic_error: 'Tapahtui virhe.',
    add: 'Lisää',
    reload: 'Lataa uudelleen',
    edit: 'Muokkaa',
    remove: 'Poista',
    tags: 'Tägit',
    yes: 'Kyllä',
    no: 'Ei',
    create_new: 'Luo uusi',
    show_details: 'Näytä tarkemmat tiedot',
    hide_details: 'Piilota tarkemmat tiedot',
    previous: 'Edellinen',
    next: 'Seuraava',
    done: 'Valmis',
    send: 'Lähetä',
    change_image: 'Vaihda kuva',
    add_image: 'Lisää kuva',
    delete_image: 'Poista kuva',
    publish: 'Julkaise',
    skip_commenting: 'Ohita kommentointi',
    close: 'Sulje',
    replay: 'Toista uudelleen',
    picture_in_picture: 'Kuva kuvassa',
  },
  // German
  de: {},
  // Swedish
  sv: {},
};

export default messages;
