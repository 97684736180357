<template>
  <hb-basic-page :title="parseInt(id) === -1 ? $t('proposals.new_proposal') : proposal.title"
                 :loading="loading"
                 :fill-height="loading"
                 :back-button="{show: true, fallback: 'proposals'}"
                 name="edit-proposal-view">
    <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="pb-15" v-scroll="onScroll">

      <v-row v-if="!loading">
        <v-col cols="12">
          <v-card>
            <v-card-subtitle class="text-uppercase secondary--text text--darken-2">{{ $t('proposals.proposal_details') }}</v-card-subtitle>
            <v-card-text>
              <v-form ref="form">
                <v-text-field
                  :disabled="shouldEditingBeDisabled || savingProposal"
                  :label="$t('proposals.subject')" v-model="proposal.title"
                  :rules="[$rules.required]"
                  validate-on-blur
                  type="text"
                />
                <v-textarea
                  :disabled="shouldEditingBeDisabled || savingProposal"
                  rows="1" :label="$t('proposals.description')" v-model="proposal.description" hide-details auto-grow />
              </v-form>
            </v-card-text>
          </v-card>

          <v-card class="mt-3">
            <v-card-subtitle class="text-uppercase secondary--text text--darken-2">{{ $t('proposals.proposal_timelimits') }}</v-card-subtitle>
            <v-card-text>
              <div class="caption pb-2">{{ $t('proposals.edit_times_help') }}</div>
              <v-subheader class="pa-0">{{ $t('proposals.discussion_time') }}</v-subheader>
              <v-slider
                :disabled="shouldEditingBeDisabled || savingProposal"
                v-model="discussionTimeInDays" min="1" max="14">
                <template v-slot:label>
                  <v-avatar color="primary" size="36" class="white--text time-slider-label">{{ discussionTimeInDays }}</v-avatar>
                </template>
              </v-slider>
              <v-subheader class="pa-0">{{ $t('proposals.voting_time') }}</v-subheader>
              <v-slider
                :disabled="shouldEditingBeDisabled || savingProposal"
                v-model="votingTimeInDays" min="1" max="14">
                <template v-slot:label>
                  <v-avatar color="primary" size="36" class="white--text time-slider-label">{{ votingTimeInDays }}</v-avatar>
                </template>
              </v-slider>
            </v-card-text>
          </v-card>

          <v-card class="mt-3" v-if="parseInt(id) === -1">
            <v-card-subtitle class="text-uppercase secondary--text text--darken-2">{{ $t('proposals.attachments.title') }}</v-card-subtitle>
            <v-card-text>

              <template v-for="(file, index) in files">
                  <v-card :key="index" style="margin-bottom: 22px;" color="grey lighten-4">
                    <v-col cols="12">
                      <v-row align="center">
                        <v-icon style="padding-left: 8px">attachment</v-icon>
                          <div class="flex-grow-1">
                            <v-text-field
                              v-model="file.name"
                              dense
                              :disabled="shouldEditingBeDisabled || savingProposal"
                              hide-details
                              style="padding: 20px 14px 8px 14px;"
                              :label="$t('proposals.file_title')"
                              validate-on-blur
                              type="text"
                            ></v-text-field>

                            <v-text-field
                              v-model="file.fileDescription"
                              :label="$t('proposals.file_description')"
                              dense
                              :disabled="shouldEditingBeDisabled || savingProposal"
                              hide-details
                              class="pa-3"
                              auto
                            ></v-text-field>
                          </div>
                        <v-btn :disabled="shouldEditingBeDisabled || savingProposal"
                               icon
                               small
                               style="margin-right: 8px"
                               @click="deleteFile(index)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-card>
              </template>

              <v-btn :block="$vuetify.breakpoint.xsOnly"
                     :disabled="shouldEditingBeDisabled || savingProposal"
                     class="mt-2"
                     color="grey lighten-2"
                     @click="addFiles">
                {{ $t('proposals.attachments.button_text') }}
              </v-btn>

              <input type="file" style="display:none" accept="*" :multiple="true" ref="hiddenFilesInput" @change="fileSelected">
            </v-card-text>
          </v-card>
          <v-card class="mt-3">
            <v-card-subtitle class="text-uppercase secondary--text text--darken-2">{{ $t('proposals.proposal_distribution') }}</v-card-subtitle>
            <v-card-text>
              <template v-for="group in distributionGroups">
                <v-checkbox v-model="proposal.distribution"
                            :disabled="shouldEditingBeDisabled || group.disabled || savingProposal"
                            :label="group.label" :value="group.value" hide-details :key="group.value" />
              </template>
            </v-card-text>
          </v-card>
          <v-card class="mt-3" v-if="userCanVote">
            <v-card-subtitle class="text-uppercase secondary--text text--darken-2">
              {{ $t('proposals.vote_in_advance') }}
              <v-btn style="margin-top: -6px;" small icon @click="openAboutVotingProcessDialog"><v-icon size="24" color="info">mdi mdi-information-outline</v-icon></v-btn>
            </v-card-subtitle>
            <v-card-text>
              <template v-for="type in Object.keys(voteTypes)">
                <v-btn :key="type"
                       block
                       :outlined="voteKey !== type"
                       :color="voteTypes[type].color"
                       class="mb-3"
                       @click="voteKey = type">
                  <v-icon v-if="voteKey === type" left>mdi mdi-check</v-icon>
                  {{ type ? $t('proposals.vote_keys_for_buttons.' + type) : ''}}
                </v-btn>
              </template>
            </v-card-text>
          </v-card>
          <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('proposals.errors.error_validation_message') }}</v-alert>
        </v-col>
      </v-row>

      <v-fade-transition>
        <v-btn
          v-if="!shouldEditingBeDisabled"
          color="primary"
          dark
          v-show="ready"
          fixed
          bottom
          right
          rounded
          style="transition: all 0.2s ease-in-out"
          :fab="!scrollingUp"
          large
          :loading="savingProposal"
          @click="saveProposal">
          <v-icon :left="scrollingUp">mdi mdi-check</v-icon>
          <span v-show="scrollingUp">{{ parseInt(id) === -1 ? $t('proposals.publish') : $t('proposals.save_changes') }}</span>
        </v-btn>
      </v-fade-transition>

      <v-snackbar v-model="filesUploading"
                  dark
                  top
                  :timeout="-1"
                  max-width="100%"
                  content-class="file-upload-snackbar-content">
        <v-list class="py-0" color="transparent">
          <v-list-item v-for="(file, index) in files"  :key="`${file.fileName}_${index}`" class="px-0">
            <v-list-item-content>
              <v-list-item-title class="caption text-truncate">{{ $t('proposals.uploading_file') }} <strong>{{ file.name }}</strong></v-list-item-title>
              <v-list-item-subtitle>
                <v-progress-linear
                  color="secondary"
                  :value="file.uploadingProgress"
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-snackbar>

    </v-col>
    <AboutVotingProcessDialog ref="aboutVotingProcessDialog" />
  </hb-basic-page>
</template>

<script>
import moment from 'moment';
import tenantApi from '@/api/tenant';
import AboutVotingProcessDialog from '@/components/Tenant/SingleProposal/AboutVotingProcessDialog';

export default {
  name: "EditProposalView",
  components: {AboutVotingProcessDialog},
  props: {
    id: {
      type: [String, Number],
      default: -1,
    },
    viewMode: {
      type: [String],
      default: ''
    },
  },
  data() {
    return {
      loading: true,
      ready: false,
      showAlert: false,
      subtleLoading: false,
      scrollingUp: true,
      offsetTop: 0,
      votingTimeInDays: 7,
      discussionTimeInDays: 7,
      proposalTemplate: {
        id: -1,
        title: '',
        description: '',
        voteStartDate: null,
        voteEndDate: null,
        distribution: ['tenant_member', 'tenant_chairman', 'tenant_housing_manager', 'tenant_housing_coordinator'],
      },
      proposal: {},
      distributionGroups: [
        { value: 'tenant_chairman', label: this.$t('user_management.role.tenant_chairman'), disabled: false },
        { value: 'tenant_member', label: this.$t('user_management.role.tenant_member'), disabled: false },
        { value: 'tenant_substitute_member', label: this.$t('user_management.role.tenant_substitute_member'), disabled: false },
        { value: 'tenant_housing_manager', label: this.$t('user_management.role.tenant_housing_manager'), disabled: true },
        { value: 'tenant_housing_coordinator', label: this.$t('user_management.role.tenant_housing_coordinator'), disabled: true },
      ],
      files: [],
      savingProposal: false,
      filesUploading: false,
      voteKey: '',
      voteTypes: {
        APPROVE: {color: 'success'},
        DENY: {color: 'error'},
        MOVE_TO_MEETING: {color: 'warning'},
      },
    }
  },
  methods: {
    openAboutVotingProcessDialog() {
      this.$refs.aboutVotingProcessDialog.open();
    },
    onScroll(e) {
      this.scrollingUp = this.offsetTop > e.target.scrollingElement.scrollTop;
      this.offsetTop = e.target.scrollingElement.scrollTop;
    },
    async saveProposal() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      this.savingProposal = true;
      try {
        let p;
        if (this.id === -1) {
          this.proposal.voteStartDate = moment().add(this.discussionTimeInDays, 'days');
          this.proposal.voteEndDate = moment().add(this.discussionTimeInDays + this.votingTimeInDays, 'days');
          p = await tenantApi.createProposal(this.proposal);
          if (this.voteKey) {
            await tenantApi.vote(p.id, this.voteKey);
          }
          if (this.files.length > 0) {
            this.filesUploading = true;
            for (const f of this.files) {
              this.$set(f, 'uploadingProgress', 0);
              const handleUploadProgress = (progressEvent) => {
                f.uploadingProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              };
              await tenantApi.uploadAttachment(p.id, f, handleUploadProgress);
            }
            this.filesUploading = false;
          }
          this.savingProposal = false;
          this.$router.replace({ name: 'single_proposal', params: { id: p.id } });
        } else {
          this.proposal.voteStartDate = moment(this.proposal.created).add(this.discussionTimeInDays, 'days');
          this.proposal.voteEndDate = moment(this.proposal.created).add(this.discussionTimeInDays + this.votingTimeInDays, 'days');
          p = await tenantApi.updateProposal(this.proposal.id, this.proposal);
          this.$router.go(-1);
        }
      } catch (err) {
        this.$handleApiError(err);
      }
      this.filesUploading = false;
      this.savingProposal = false;
    },
    async reloadProposalData() {
      try {
        this.proposal = await tenantApi.getProposal(this.id);
        const voteStartDate = moment(this.proposal.voteStartDate);
        this.discussionTimeInDays = moment.duration(voteStartDate.diff(this.proposal.created)).asDays();
        const voteEndDate = moment(this.proposal.voteEndDate);
        this.votingTimeInDays = moment.duration(voteEndDate.diff(this.proposal.created)).asDays() - moment.duration(voteStartDate.diff(this.proposal.created)).asDays();
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    addFiles() {
      this.$refs.hiddenFilesInput.click();
    },
    deleteFile(index) {
      this.files.splice(index, 1);
    },
    async fileSelected(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i += 1) {
        const f = files[i];
        const asDataUrl = await this.toBase64(f);
        this.files.push({ file: f, dataUrl: asDataUrl, name: f.name, type: f.type, filename: f.name, fileDescription: '' });
      }
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
  },
  async mounted() {
    this.$vuetify.goTo(0, { duration: 0 });

    if (this.$vuetify.breakpoint.xsOnly) {
      setTimeout(() => {
        this.ready = true
      }, 300);
    } else {
      this.ready = true;
    }

    if (parseInt(this.id) > -1) {
      await this.reloadProposalData();
    } else {
      this.proposal = Object.assign({}, this.proposalTemplate);
    }

    this.loading = false;
  },
  computed: {
    userCanVote() {
      return this.id < 0 && !this.$isHousingManager && !this.$isHousingCoordinator;
    },
    voteStatus() {
      const now = moment();
      if (this.proposal.voteEndDate && now.isAfter(moment(this.proposal.voteEndDate))) {
        return 'ENDED';
      }
      if (this.proposal.voteStartDate && now.isAfter(moment(this.proposal.voteStartDate))) {
        return 'IN_VOTE';
      }
      return 'OPEN';
    },
    shouldEditingBeDisabled() {
      if (this.voteStatus === 'ENDED' || this.voteStatus === 'IN_VOTE') {
        return true;
      }
      if (this.$isAdmin || this.proposal.id === -1) {
        return false;
      }
      return this.proposal.user && (this.proposal.user.id !== this.$userInfo.id);
    }
  }
}
</script>

<style lang="scss" scoped>
  .time-slider-label {
    font-size: 1.15rem;
    line-height: 1rem;
    margin-top: -8px;
  }
</style>

<style lang="scss">
  .file-upload-snackbar-content {
    max-width: 100%;
  }
</style>
