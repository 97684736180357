<template>
  <hb-basic-page :loading="true">

  </hb-basic-page>
</template>

<script>
import TenantService from '@/service/tenant.service';

export default {
  name: 'RedirectToTenantStartView',
  mounted() {
    if (this.$isLoggedIn) {
      const redirectPath = sessionStorage.getItem('redirectPath');
      if (redirectPath) {
        if (redirectPath.startsWith('/t/')) {
          TenantService.setTenant(redirectPath.split('/')[2]);
        }
        this.$router.push(redirectPath, () => {
          sessionStorage.removeItem('redirectPath');
        });
      } else if (this.$tenant) {
        this.$router.push({name: 'proposals', params: {tenantId: this.$tenant}});
      }
      // else user is logged in & has no tenant, NoTenantsView is shown
    } else if (this.$isLoggedIn && !this.$tenant && this.$isAdmin) {
      this.$router.push({ name: 'tenants' });
    } else {
      this.$router.push({ name: 'login' });
    }
  }
}
</script>

<style scoped>

</style>
