<template>
  <v-snackbar v-model="dialog" top multi-line :timeout="-1" light>
    <div>
      <div class="subtitle-1 font-weight-bold accent--text">{{ $t('push_notifications.do_you_want_to_allow_push_notifications') }}</div>
      <p class="my-1">{{ $t('push_notifications.push_notifications_explained') }}</p>
      <p class="my-1">{{ $t('push_notifications.info_for_agreeing') }}</p>
      <p class="my-1">{{ $t('push_notifications.info_for_declining') }}</p>

      <div class="action-buttons">
        <v-btn color="error" small outlined @click="cancel()">{{ $t('push_notifications.i_do_not_agree') }}</v-btn>
        <v-btn color="primary" small outlined @click="agree()">{{ $t('push_notifications.i_agree') }}</v-btn>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
import Notifications from "@/notifications";
import userApi from "@/api/user";

export default {
  name: 'PushNotificationConfirm',
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    agree() {
      this.dialog = false;
      this.enableNotifications();
    },
    async enableNotifications() {
      const notificationPermission = await Notifications.registerNotifications();
      if (notificationPermission) {
        try {
          const notifications = this.$userInfo.enabledNotifications;
          if (!notifications.includes('PUSH_PROPOSAL')) {
            notifications.push('PUSH_PROPOSAL');
          }
          if (!notifications.includes('PUSH_COMMENT')) {
            notifications.push('PUSH_COMMENT');
          }
          await userApi.updateEnabledNotifications(notifications);
          this.pushNotificationsEnabled = true;
          this.notificationBrowserPermissions = Notifications.getNotificationBrowserPermissions();
        } catch (error) {
          console.log(error);
          this.$showErrorNotification(this.$t('errors.error_updating_push_notification_settings'));
        }
      } else {
        this.notificationBrowserPermissions = Notifications.getNotificationBrowserPermissions();
      }
    },
    cancel() {
      this.dialog = false;
    },
  },
}
</script>

<style lang="scss">
.action-buttons {
  margin-top: 15px;
  margin-bottom: 10px;

  .v-btn {
    margin-left: 0 !important;
    margin-right: 15px !important;
  }
}
</style>
