<template>
  <v-card v-if="Object.keys(proposal).length > 0">
    <v-card-subtitle class="pb-0 text-uppercase secondary--text text--darken-2">
      {{ voteStatus === 'OPEN' ? $t('proposals.vote_in_advance') : $t('proposals.vote') }}
      <v-btn style="margin-top: -6px;" small icon @click="openAboutVotingProcessDialog"><v-icon size="24" color="info">mdi mdi-information-outline</v-icon></v-btn>
    </v-card-subtitle>
    <v-card-text class="pt-0">
      <template v-if="voteStatus === 'OPEN'">
        {{ $t('proposals.voting_starts_in') }} <strong>{{ $formatCalendarTimeStamp(proposal.voteStartDate) }}</strong>.
        {{ $t('proposals.vote_in_advance_help') }}
      </template>
      <template v-else>
        {{ $t('proposals.voting_ends_in') }} <strong>{{ $formatCalendarTimeStamp(proposal.voteEndDate) }}</strong>.
      </template>
    </v-card-text>
    <v-card-text class="pt-0">
      <template v-for="type in Object.keys(voteTypes)">
        <v-btn :loading="voteKey === type && voteLoading"
               :disabled="voteKey !== type && voteLoading || userCannotVoteAnymore"
               :key="type"
               block
               :outlined="usersOwnVote && usersOwnVote.type !== type"
               :color="voteTypes[type].color"
               class="mb-3"
               @click="submitVote(type)">
          <v-icon v-if="usersOwnVote && usersOwnVote.type === type" left>mdi mdi-check</v-icon>
          {{ type ? $t('proposals.vote_keys_for_buttons.' + type) : ''}}
        </v-btn>
      </template>
    </v-card-text>

    <CommentWhenVotingDialog :proposal="proposal" ref="commentWhenVotingDialog" />
    <AboutVotingProcessDialog ref="aboutVotingProcessDialog" />

  </v-card>
</template>
<script>
import moment from 'moment';
import CommentWhenVotingDialog from "@/components/Tenant/SingleProposal/CommentWhenVotingDialog";
import AboutVotingProcessDialog from "@/components/Tenant/SingleProposal/AboutVotingProcessDialog";

export default {
  name: "Vote",
  components: {AboutVotingProcessDialog, CommentWhenVotingDialog},
  props: {
    proposal: {
      type: Object,
      default: () => {},
    },
    voteStatus: {
      type: String,
      default: 'OPEN',
    },
    usersOwnVote: {
      type: [Boolean, Object],
      default: false,
    }
  },
  data() {
    return {
      voteLoading: false,
      voteKey: '',
      voteTypes: {
        APPROVE: {color: 'success'},
        DENY: {color: 'error'},
        MOVE_TO_MEETING: {color: 'warning'},
      }
    }
  },
  methods: {
    openAboutVotingProcessDialog() {
      this.$refs.aboutVotingProcessDialog.open();
    },
    async submitVote(type) {
      let confirmVoting;
      if (this.voteStatus === 'IN_VOTE') {
        confirmVoting = await this.$refs.commentWhenVotingDialog.open(type);
        if (!confirmVoting) return;
        if (confirmVoting.commented) this.$emit('update-proposal');
      }

      this.voteLoading = true;
      this.voteKey = type;

      try {
        await this.$tenantApi.vote(this.proposal.id, type, null, confirmVoting?.comment);
        this.$showSuccessNotification(this.$t('proposals.vote_has_been_registered'));
        this.$emit('update-votes');
      } catch(error) {
        this.$handleApiError(error, this.$t('errors.error_in_voting'));
      }

      this.voteLoading = false;
      this.voteKey = '';
    },
  },
  computed: {
    voteResultClass() {
      if (this.voteStatus === 'ENDED') {
        if (this.proposal.voteResult === 1) return 'approved';
        if (this.proposal.voteResult === 2) return 'denied'
        return 'moved';
      }
      return '';
    },
    userCannotVoteAnymore() {
      if (this.usersOwnVote.updated) {
        return moment(this.usersOwnVote.updated).isAfter(this.proposal.voteStartDate);
      }
      return false;
    }
  }
}
</script>

<style scoped>

</style>
