<template>
  <hb-basic-page :title="thread ? thread.title : ''"
                 :loading="loading"
                 :fill-height="true"
                 :no-container-padding="true"
                 :no-gutters="true"
                 :back-button="{show: true, fallback: 'threads'}"
                 :context-menu="contextMenuItems"
                 color="secondary darken-1"
                 name="single-thread-view">
    <template v-slot:app-bar-extension>
      <v-tabs background-color="secondary darken-2">
        <v-tabs-slider color="secondary lighten-1"></v-tabs-slider>
        <v-tab v-for="item in tabs" :key="item.name" class="font-weight-bold" style="text-transform: none;" replace :to="item.route">
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </template>

    <v-col class="fill-height" v-scroll="onScroll">
      <v-tabs-items v-model="selectedTab" class="fill-height transparent" v-if="thread">
        <v-tab-item style="height: 100%;" value="chat">
          <ThreadChat :thread="thread"
                      :is-active="selectedTab === 'chat'"
                      :vote-status="discussionStatus"
                      @handle-image-view="handleImageView" />

        </v-tab-item>
        <v-tab-item style="height: 100%; width: 100%;" value="files">
          <v-container fluid :class="thread.attachments.length === 0 ? 'fill-height' : ''">
            <v-row :class="thread.attachments.length === 0 ? 'fill-height' : ''">
              <v-col :class="thread.attachments.length === 0 ? 'fill-height' : ''" cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" >
                <Files :scrolling-up="scrollingUp"
                       :thread="thread"
                       invert-colors
                       :vote-status="discussionStatus"
                       @file-uploaded="reloadData"
                       @file-deleted="reloadData"
                       @handle-video-play="handleVideoPlay"
                       @handle-image-view="handleImageView" />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>

      <confirm-dialog ref="confirmDialog" />

      <ImageViewer ref="imageViewer" :viewer-options="viewerOptions" :image-to-display="imageToDisplay" />

      <VideoPlayer ref="videoPlayer" :options="videoPlayerOptions" />

    </v-col>
  </hb-basic-page>
</template>

<script>
import ThreadChat from '@/components/Tenant/SingleThread/ThreadChat';
import tenantApi from '@/api/tenant';
import ConfirmDialog from '@/components/ConfirmDialog';
import Files from "@/components/Tenant/Common/Files";
import VideoPlayer from "@/components/VideoPlayer";
import ImageViewer from "@/components/ImageViewer";
import moment from 'moment';

export default {
  name: "SingleThreadView",
  components: {ImageViewer, Files, ConfirmDialog, ThreadChat, VideoPlayer},
  props: {
    id: {
      type: [String, Number],
      default: -1,
    },
    tab: {
      type: [String],
      default: 'chat',
    },
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      scrollingUp: true,
      offsetTop: 0,
      selectedTab: 'chat',
      tabs: [
        { name: this.$t('threads.discussion'), route: { name: 'single_thread', params: { id: this.id, tab: 'chat' } } },
        { name: this.$t('threads.files.title'), route: { name: 'single_thread', params: { id: this.id , tab: 'files' } } },
      ],
      thread: null,
      ready: false,
      imageToDisplay: [],
      viewerOptions: {
        inline: false,
        button: false,
        navbar: false,
        title: false,
        toolbar: false,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
      },
      videoPlayerOptions: {
        muted: true,
        controls: true,
        autoplay: true,
        language: 'fi',
        aspectRatio: this.$vuetify.breakpoint.smAndUp ? '16:9' : undefined,
        fill: this.$vuetify.breakpoint.xsOnly,
      }
    }
  },
  methods: {
    async reloadData() {
      try {
        this.thread = await tenantApi.getThread(this.id);
      } catch (err) {
        this.$handleApiError(err);
        this.$router.replace({ name: 'threads' });
      }
    },
    onScroll(e) {
      this.scrollingUp = this.offsetTop > e.target.scrollingElement.scrollTop;
      this.offsetTop = e.target.scrollingElement.scrollTop;
    },
    editThread() {
      this.$router.push({ name: 'edit_thread', params: {id: this.thread.id.toString()} });
    },
    async closeThread() {
      if (!(this.thread.user && this.thread.user.id === this.$userInfo.id) && !(this.$isAdmin)) return;
      try {
        this.thread = await tenantApi.updateThread(this.id, { ...this.thread, discussionEndTime: moment() });
        this.$showSuccessNotification(this.$t('threads.thread_closed'));
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    async openThread() {
      if (!(this.thread.user && this.thread.user.id === this.$userInfo.id) && !(this.$isAdmin)) return;
      try {
        this.thread = await tenantApi.updateThread(this.id, { ...this.thread, discussionEndTime: null });
        this.$showSuccessNotification(this.$t('threads.thread_opened'));
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    async deleteThread() {
      if (!(this.thread.user && this.thread.user.id === this.$userInfo.id) && !(this.$isAdmin)) return;
      const deleteConfirmed = await this.$refs.confirmDialog.open(this.$t('threads.thread_delete_dialog.title'), this.$t('threads.thread_delete_dialog.message'), { color: 'warning', agree: this.$t('threads.thread_delete_dialog.delete_yes'), cancel: this.$t('threads.thread_delete_dialog.delete_no') });
      if (!deleteConfirmed) return;
      try {
        this.thread = await tenantApi.deleteThread(this.id);
        this.$router.replace({ name: 'threads' });
        this.$showSuccessNotification(this.$t('threads.thread_delete_dialog.thread_delete_confirmed'));
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    handleImageView(imageUrl) {
      if (this.imageToDisplay.length > 0) {
        URL.revokeObjectURL(this.imageToDisplay[0]);
      }
      this.imageToDisplay = [imageUrl];
      this.$refs.imageViewer.show();
    },
    handleVideoPlay(video) {
      this.$refs.videoPlayer.openPlayer({
        type: video.contentType,
        src: video.link,
      });
    },
  },
  watch: {
    selectedTab(val) {
      if (val !== this.tab) {
        this.$router.replace({ name: 'single_thread', params: { id: this.id, tab: val } });
      }
      this.reloadData();
    },
    tab(val) {
      if (val !== this.selectedTab) {
        this.selectedTab = val;
      }
    },
  },
  computed: {
    creator() {
      if (this.thread.user) {
        if (this.thread.user.firstName && this.thread.user.lastName) return `${this.thread.user.firstName} ${this.thread.user.lastName}`;
        if (this.thread.user.email) return this.thread.user.email;
        if (this.thread.user.phone) return this.thread.user.phone;
      }
      return '';
    },
    discussionStatus() {
      if (this.thread && moment().isAfter(moment(this.thread.discussionEndTime))) {
          return 'ENDED';
      }
      return 'OPEN';
    },
    contextMenuItems() {
      const contextMenuItems = [];
      if (this.thread) {
        if ((this.thread.user && this.thread.user.id === this.$userInfo.id) || this.$isAdmin) {
          contextMenuItems.push({
            callback: this.editThread,
            text: this.$t('threads.edit_thread'),
            icon: 'mdi mdi-pencil'
          });
          if (this.thread.discussionEndTime) {
            contextMenuItems.push({
              callback: this.openThread,
              text: this.$t('threads.open_thread'),
              icon: 'mdi mdi-check'
            });
          } else {
            contextMenuItems.push({
              callback: this.closeThread,
              text: this.$t('threads.close_thread'),
              icon: 'mdi mdi-close'
            });
          }
          contextMenuItems.push({
            callback: this.deleteThread,
            text: this.$t('threads.delete_thread'),
            icon: 'mdi mdi-delete'
          });
        }
      }
      return contextMenuItems;
    },
  },
  async mounted() {
    if (this.$vuetify.breakpoint.xsOnly) {
      setTimeout(() => {
        this.ready = true
      }, 300);
    } else {
      this.ready = true;
    }
    await this.reloadData();
    this.loading = false;
    this.selectedTab = this.tab;
  }
}
</script>

<style lang="scss">
.v-slide-group__prev--disabled {
  display: none !important;
}
</style>
