<template>
  <v-card>
    <v-card-subtitle class="pb-0 text-uppercase secondary--text text--darken-2">{{ $t('proposals.description') }}</v-card-subtitle>
    <v-card-text style="white-space: pre-wrap;">
      {{ proposal.description }}
    </v-card-text>
    <v-card-subtitle class="pb-0 text-uppercase secondary--text text--darken-2">{{ $t('proposals.creator') }}</v-card-subtitle>
    <v-card-text>
      {{ creator }}
    </v-card-text>
    <v-card-subtitle class="pb-0 text-uppercase secondary--text text--darken-2">{{ $t('proposals.distribution') }}</v-card-subtitle>
    <v-card-text>
      {{ distribution }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ProposalDetails",
  props: {
    proposal: {
      type: [Object],
      default: () => {},
    }
  },
  computed: {
    distribution() {
      return this.proposal.distribution.map(role => this.$t('user_management.role.' + role)).join(', ');
    },
    creator() {
      if (this.proposal.user) {
        if (this.proposal.user.firstName && this.proposal.user.lastName) return `${this.proposal.user.firstName} ${this.proposal.user.lastName}`;
        if (this.proposal.user.email) return this.proposal.user.email;
        if (this.proposal.user.phone) return this.proposal.user.phone;
      }
      return '';
    }
  },
}
</script>

<style scoped>

</style>
