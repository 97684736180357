<template>
  <v-dialog v-model="dialog" max-width="600" @keydown.esc="cancel" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ $t('proposals.add_time_for_voting') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="mx-8 my-4">
        <v-subheader class="pa-0">{{ $t('proposals.discussion_time') }}</v-subheader>
        <v-slider
          :disabled="savingProposal"
          v-model="discussionTimeInDays" min="1" max="14">
          <template v-slot:label>
            <v-avatar color="primary" size="36" class="white--text time-slider-label">{{ discussionTimeInDays }}</v-avatar>
          </template>
        </v-slider>
        <v-subheader class="pa-0">{{ $t('proposals.voting_time') }}</v-subheader>
        <v-slider
          :disabled="savingProposal"
          v-model="votingTimeInDays" min="1" max="14">
          <template v-slot:label>
            <v-avatar color="primary" size="36" class="white--text time-slider-label">{{ votingTimeInDays }}</v-avatar>
          </template>
        </v-slider>
      </div>
      <v-divider></v-divider>

      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn :class="$vuetify.breakpoint.xsOnly ? 'mx-0' : ''"
               :block="$vuetify.breakpoint.xsOnly"
               color="default"
               :disabled="savingProposal"
               @click.native="cancel">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
               :class="$vuetify.breakpoint.xsOnly ? 'mb-2 mx-0' : ''"
               :block="$vuetify.breakpoint.xsOnly"
               :loading="savingProposal"
               color="primary"
               @click.native="timeChange">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tenantApi from "@/api/tenant";
import moment from "moment";

export default {
  name: 'AddTimeForVoteDialog',
  components: {},
  props: {
    proposal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      savingProposal: false,
      votingTimeInDays: 7,
      discussionTimeInDays: 7,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },

    async timeChange() {
      if (!this.proposal) return;

      try {
        this.savingProposal = true;

        this.proposal.result = null;
        this.proposal.resultText = null;
        this.proposal.voteStartDate = moment(moment()).add(this.discussionTimeInDays, 'days');
        this.proposal.voteEndDate = moment(moment()).add(this.discussionTimeInDays + this.votingTimeInDays, 'days');
        await tenantApi.updateProposal(this.proposal.id, this.proposal);

        this.dialog = false;
      } catch (err) {
        this.$handleApiError(err);
      }
      this.savingProposal = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
}
</script>

<style scoped>

</style>
