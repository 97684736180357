<template>
  <v-col cols="12" class="pb-0" v-if="Object.keys(thread).length > 0">
    <v-card class="thread-card" :class="discussionStatus" :to="{name: 'single_thread', params: { id: this.thread.id, tab: 'chat' }}">
      <v-card-title class="title">
        <div>
          <span>{{ thread.title }}</span>
          <v-icon v-if="isUnread" color="secondary" class="ml-2 mb-2">mdi mdi-alert-circle</v-icon>
          <v-icon v-if="hasNewComments" color="secondary" class="ml-2 mb-2">mdi mdi-chat-alert</v-icon>
          <v-icon v-if="hasNewAttachments" color="secondary" class="ml-2 mb-2">mdi mdi-file-alert</v-icon>
        </div>
      </v-card-title>
      <v-card-subtitle>
        <div>{{ $t('threads.created_date') }} <strong>{{ createdDate }}</strong></div>
        <div v-if="newestCommentDate">{{ $t('threads.newest_comment_date') }} <strong>{{ newestCommentDate }}</strong></div>
      </v-card-subtitle>
      <v-card-text>
        {{ shortDescription }}
      </v-card-text>
      <template v-if="discussionStatus === 'OPEN'">
        <v-card-actions class="pt-0">
          <v-btn :to="{ name: 'single_thread', params: { id: this.thread.id, tab: 'chat' } }" text color="primary">{{ $t('threads.discuss') }}</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-col>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ThreadCard',
  props: {
    thread: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    createdDate() {
      return this.thread.created ? this.$formatDateNoTime(this.thread.created) : '';
    },
    newestCommentDate() {
      return this.thread.newestComment ? this.$formatDateNoTime(this.thread.newestComment.created) : '';
    },
    isUnread() {
      return this.discussionStatus === 'OPEN' && this.thread.user?.id !== this.$userInfo.id && !this.thread.reads.some(r => r.user?.id === this.$userInfo.id);
    },
    hasNewComments() {
      if (!this.thread.newestComment || this.thread.newestComment.user?.id === this.$userInfo.id) {
        return false;
      }
      const r = this.thread.commentReads.find(r => r.user?.id === this.$userInfo.id);
      return r ? moment(this.thread.newestComment.updated).isAfter(moment(r.date)) : true;
    },
    hasNewAttachments() {
      const totalCount = new Set(this.thread.attachmentReads.map(r => r.targetId)).size;
      return this.thread.attachmentReads.filter(r => r.user?.id === this.$userInfo.id).length < totalCount;
    },
    discussionStatus() {
      if (this.thread && moment().isAfter(moment(this.thread.discussionEndTime))) {
        return 'ENDED';
      }
      return 'OPEN';
    },
    shortDescription() {
      if (this.thread.description.length > 70) {
        return this.thread.description.substring(0, 70) + "...";
      }
      return this.thread.description;
    },
  }
}
</script>

<style lang="scss" scoped>
.thread-card {
  border-left: 6px solid #eee;

  &.OPEN {
    border-left-color: #b9d982;
  }
}

</style>
