<template>
  <div style="overflow: auto; position: absolute; top: 0; left: 0; right: 0; bottom: 0;" class="primary">

    <div class="background-image"></div>

    <v-container fluid fill-height class="py-0">
      <v-row class="fill-height" align="center" style="z-index: 1;" v-if="!$userInfo">
        <v-col cols="12" md="6" offset-md="3" lg="4" offset-lg="4" xl="4" offset-xl="4"
               class="login-holder px-3 pt-5 px-xl-16">

          <div class="text-center pb-16">
              <img style="height: auto; width: 60%;" src="/img/Hemman-isannointi-logo-white.svg"/>
          </div>

          <v-row no-gutters>
            <v-col cols="12">
              <v-card color="transparent" elevation="0" dark style="width: 100%;" class="ma-0 pa-0">
                <v-card-text class="pa-0 ma-0">
                  <v-row no-gutters>
                    <template v-if="loginType === 'CODE'">
                      <login-with-code-view :uiComponentProperties="uiComponentProperties" @signInAsHousingManager="loginType = 'COGNITO'"/>
                    </template>

                    <template v-else-if="loginType === 'COGNITO'">
                      <custom-sign-in v-if="!isSignUp && !isForgotPassword && !isRequireNewPassword"
                                      :is-confirm-signup="isConfirmSignUp"
                                      v-bind:signInConfig="signInConfig"
                                      :uiComponentProperties="uiComponentProperties"
                                      @signInAsTenant="loginType = 'CODE'"/>

                      <custom-require-new-password v-if="isRequireNewPassword"
                                                   v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"
                                                   :uiComponentProperties="uiComponentProperties"/>

                      <custom-forgot-password class="forgot-password"
                                              v-if="isForgotPassword"
                                              v-bind:forgotPasswordConfig="forgotPasswordConfig"
                                              :uiComponentProperties="uiComponentProperties"/>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>

  </div>
</template>
<script>
import UiEventBus from '../UiEventBus'
import { components as amplifyComponents, AmplifyEventBus } from 'aws-amplify-vue'
import CustomSignIn from '../components/Login/SignIn.vue';
import CustomForgotPassword from '../components/Login/ForgotPassword.vue';
import CustomRequireNewPassword from '../components/Login/RequireNewPassword.vue';
import UserService from '@/service/user.service';
import userApi from "@/api/user";
import LoginWithCodeView from "@/components/Login/LoginWithCodeView";

export default {
  name: "LoginView",
  data() {
    return {
      loginType: 'CODE',
      loginTypes: ['CODE', 'PASSWORD', 'COGNITO'],
      username: '',
      password: '',
      subject: '',
      uiComponentProperties: {
        rounded: false,
        text: true,
        outlined: true,
      },
      signInConfig: {
        header: this.$t('login.login'),
        isSignUpDisplayed: false,
      },
      signUpConfig: {
        defaultCountryCode: '358',
        signUpFields: [
          {
            label: this.$Amplify.I18n.get('Username'),
            key: 'username',
            required: true,
            type: 'string',
            displayOrder: 1
          },
          {
            label: this.$Amplify.I18n.get('Email'),
            key: 'email',
            required: true,
            type: 'string',
            displayOrder: 2
          },
        ],
        hiddenDefaults: ['phone_number', 'username']
      },
      authConfig: {},
      forgotPasswordConfig: {},
      isSignUp: false,
      isConfirmSignUp: false,
      isForgotPassword: false,
      isRequireNewPassword: false,
    }
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: {},
        signUpConfig: {},
        confirmSignUpConfig: {},
        confirmSignInConfig: {},
        forgotPasswordConfig: {},
        mfaConfig: {},
        requireNewPasswordConfig: {}
      };
      return Object.assign(defaults, this.authConfig)
    }
  },
  methods: {
    async getCurrentUser() {
      try {
        const cognitoUser = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.$store.commit('setLoadingOverlay', true);
        UserService.cognitoLogin(cognitoUser);
        const me = await userApi.getMe();
        UserService.setUserInfo(me);
        UiEventBus.$emit('loginSuccess', true);
      } catch (error) {
        this.$handleApiError(error);
      }
    },
  },
  created() {
    AmplifyEventBus.$on('localUser', user => {
      this.user = user;
      this.options.signInConfig.username = this.user.username;
      this.options.confirmSignInConfig.user = this.user;
      this.options.confirmSignUpConfig.username = this.user.username;
      this.options.requireNewPasswordConfig.user = this.user;
    });
    AmplifyEventBus.$on('authState', state => {
      this.isRequireNewPassword = state === 'requireNewPassword';
      this.isSignUp = state === 'signUp';
      this.isConfirmSignUp = state === 'confirmSignUp';
      this.isForgotPassword = state === 'forgotPassword';
      if (state === 'signedIn') {
        this.getCurrentUser();
      }
    });
  },
  mounted() {
    if (this.$isLoggedIn) {
      this.$store.commit('setLoadingOverlay', false);
      this.$router.push({ name: 'start' });
    }
  },
  components: {
    LoginWithCodeView,
    CustomSignIn,
    CustomForgotPassword,
    CustomRequireNewPassword,
    ...amplifyComponents
  },
}
</script>

<style lang="scss" scoped>
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../assets/hemman_bg.jpg');
  background-size: cover;
  opacity: 0.1;
}

@media only screen and (min-width: 600px) {
  .background-image {
    opacity: 0.15;
  }
}
</style>
