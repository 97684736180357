import userApi from './api/user';

// From https://github.com/GoogleChromeLabs/web-push-codelab/blob/master/app/scripts/main.js
function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function registerNotifications(callback, resubscribe) {
  try {
    // Check that features is enabled
    if (!('Notification' in window) || !('serviceWorker' in navigator)) {
      return false;
    }

    if (Notification.permission === 'default') {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        return false;
      }
    }

    if (callback) {
      callback(Notification.permission);
    }

    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    if (!subscription || resubscribe) {
      if (subscription && subscription.unsubscribe) {
        console.log('unsubscribing');
        await subscription.unsubscribe();
      }
      const vapidPublicKey = 'BPSkThMPPXAZYM0Uc4uXOXZVUorWvSPmcV1EcbQuYvr5qNapBEVrmOCi3HuxuEsItFT-vTJI-wwaIMBT2Hpj1Dc';
      const subscriptionOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(vapidPublicKey),
      };

      const newSubscription = await registration.pushManager.subscribe(subscriptionOptions);
      console.log('subscription created', newSubscription);
      try {
        await userApi.createSubscription(newSubscription);
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log('subscription already exists', subscription);
    }
    return true;
  } catch (error) {
    console.log(error);
  }
}

function getNotificationBrowserPermissions() {
  if (!('Notification' in window) || !('serviceWorker' in navigator)) {
    return 'disabled';
  }
  return Notification.permission;
}

async function getSubscription() {
  if (('Notification' in window) && ('serviceWorker' in navigator)) {
    const registration = await navigator.serviceWorker.ready;
    return await registration.pushManager.getSubscription();
  }
  return null;
}

async function validateSubscription() {
  if (('Notification' in window) && ('serviceWorker' in navigator)) {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    if (!subscription) {
      return false;
    }
    // check from backend
    return userApi.validateSubscription(subscription);
  }
  return false;
}

export default { registerNotifications, getNotificationBrowserPermissions, getSubscription, validateSubscription };
