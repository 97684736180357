<template>
  <v-container fluid :fill-height="loading || fillHeight" :class="name" :pa-0="noContainerPadding">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;"
                       :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2"
                       height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <v-app-bar :color="color ? color : 'primary'" dark fixed app :extended="!!$slots['app-bar-extension'] || showCurrentTenantInSystemBar" :extension-height="extensionHeight">

      <template v-if="$vuetify.breakpoint.mdAndDown && !showBackButton">
        <hb-main-menu-button/>
      </template>

      <template v-if="showSearch">
        <v-toolbar-title style="margin-left: 0; width: 100%;" class="title">
          <v-text-field
            :placeholder="searchTitle"
            v-model="searchPhrase"
            class="topbar-search"
            style="font-size: inherit;"
            single-line
            full-width
            hide-details
          >
            <v-icon style="cursor: pointer; margin-top: -3px;" slot="append">search</v-icon>
          </v-text-field>
        </v-toolbar-title>
      </template>

      <template v-else>
        <hb-back-button v-if="showBackButton" :fallback="this.backButton.fallback"></hb-back-button>
        <v-toolbar-title style="margin-left: 0; width: 100%;" class="title">
          {{ title }}
        </v-toolbar-title>

        <template v-if="contextMenu && contextMenu.length > 0">
          <v-spacer></v-spacer>

          <v-menu dark>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon>
                <v-icon>mdi mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-for="item in contextMenu" :key="item.text" @click="item.callback">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </template>

      <template v-slot:extension>
        <v-container class="pa-0" fluid>
          <v-row no-gutters>
            <v-col cols="12" v-if="!!$slots['app-bar-extension']" >
              <slot name="app-bar-extension"></slot>
            </v-col>
            <v-col cols="12" v-if="showCurrentTenantInSystemBar">
              <v-system-bar height="24" color="white" class="px-4 secondary--text text--darken-2" >
                <div style="font-size: 12px; max-width: 100%;" class="text-truncate">{{ $t('tenants.current_tenant') }}: <strong>{{ currentTenant.name }}</strong></div>
              </v-system-bar>
            </v-col>
          </v-row>
        </v-container>
      </template>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;" :no-gutters="noGutters" :class="fillHeight ? 'fill-height' : ''">
        <slot></slot>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
export default {
  props: ['name', 'title', 'loading', 'subtleLoading', 'backButton', 'searchTitle', 'noGutters', 'noContainerPadding', 'fillHeight', 'contextMenu', 'color'],
  name: 'BasicPage',
  data() {
    return {
      searchPhrase: '',
    }
  },
  computed: {
    showBackButton() {
      return this.backButton != null && this.backButton.show;
    },
    showSearch() {
      return this.searchTitle != null;
    },
    possibleTenants() {
      if (this.$userInfo?.tenants) {
        return this.$userInfo.tenants;
      }
      return [];
    },
    showCurrentTenantInSystemBar() {
      return this.possibleTenants.length > 1 && this.$vuetify.breakpoint.mdAndDown && !!this.$route.params.tenantId;
    },
    extensionHeight () {
      if (this.$slots['app-bar-extension']) {
        return this.showCurrentTenantInSystemBar ? 68 : 44;
      } else {
        return this.showCurrentTenantInSystemBar ? 24 : 0;
      }
    },
    currentTenant() {
      if (this.possibleTenants) {
        const tenantIndex = this.possibleTenants.findIndex(tenant => tenant.id === this.$tenant);
        if (tenantIndex > -1) {
          return this.possibleTenants[tenantIndex];
        }
      }
      return {};
    }
  },
  watch: {
    searchPhrase(to) {
      this.$emit('handle-search', to);
    }
  }
}
</script>

<style lang="scss">
  .topbar-search {
    .v-input__slot {
      padding: 0 !important;

      &:after {
        display: none;
      }
    }
  }

  .v-toolbar__extension {
    padding: 4px 0;
  }
</style>
