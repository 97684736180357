<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" @keydown.esc="close">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="white--text" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ $t('proposals.about_voting_process.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0 scroll-content">
          <v-list class="px-0">
            <v-list-item>
              <v-list-item-icon style="margin-right: 16px;">
                <v-avatar size="24" color="secondary" class="white--text font-weight-bold">1</v-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  {{ $t('proposals.about_voting_process.open_proposal') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon style="margin-right: 16px;">
                <v-avatar size="24" color="secondary" class="white--text font-weight-bold">2</v-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  {{ $t('proposals.about_voting_process.in_voting_proposal') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon style="margin-right: 16px;">
                <v-avatar size="24" color="secondary" class="white--text font-weight-bold">3</v-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  {{ $t('proposals.about_voting_process.closed_proposal') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
          <v-spacer></v-spacer>
          <v-btn :class="$vuetify.breakpoint.xsOnly ? 'mx-0' : ''" :block="$vuetify.breakpoint.xsOnly" color="default" @click="close">
            {{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AboutVotingProcessDialog',
  props: {
    proposal: {
      type: [Object],
      default: () => null,
    },
    message: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
}
</script>

<style scoped lang="scss">
.scroll-content {
  max-height: calc(100vh - 250px);
  overflow: auto;
}
</style>
