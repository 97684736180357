<template>
  <hb-basic-page :search-title="`${$t('proposals.search')}...`"
                 :loading="loading"
                 :fill-height="proposals.length === 0"
                 @handle-search="handleSearch"
                 name="proposals-view">

    <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="pb-15" v-scroll="onScroll">

      <PushNotificationConfirm ref="pushNotificationConfirm" />

      <v-row v-if="!loading && proposals.length > 0">
        <template v-for="proposal in filteredProposals">
          <ProposalCard :key="proposal.id" :proposal="proposal" />
        </template>
      </v-row>

      <no-content-view v-if="!loading && proposals.length === 0"
                       :button="{callback: newProposal, text: $t('proposals.new_proposal'), icon: 'mdi mdi-plus' }"
                       :title="$t('proposals.no_proposals')"
                       :description="$t('proposals.no_proposals_found')"
                       icon="mdi mdi-file-document-edit-outline" />

      <v-fade-transition>
        <v-btn
          color="primary"
          dark
          v-show="ready && proposals.length > 0"
          fixed
          bottom
          right
          rounded
          style="transition: all 0.2s ease-in-out"
          :fab="!scrollingUp"
          @click="newProposal"
          large
        >
          <v-icon :left="scrollingUp">mdi mdi-plus</v-icon>
          <span v-show="scrollingUp">{{ $t('proposals.new_proposal') }}</span>
        </v-btn>
      </v-fade-transition>
    </v-col>

  </hb-basic-page>
</template>

<script>
import ProposalCard from "@/components/Tenant/Proposals/ProposalCard";
import NoContentView from "@/components/NoContentView";
import tenantApi from '@/api/tenant';
import moment from 'moment';
import PushNotificationConfirm from "@/components/PushNotificationConfirm";
import Notifications from '@/notifications';

export default {
  name: "ProposalsView",
  components: {PushNotificationConfirm, NoContentView, ProposalCard},
  data() {
    return {
      loading: true,
      ready: false,
      searchPhrase: '',
      offsetTop: 0,
      scrollingUp: true,
      proposals: []
    }
  },
  methods: {
    async reloadData() {
      try {
        this.proposals = await tenantApi.getProposals();
        this.sortProposals();
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    sortProposals() {
      this.proposals.sort((a, b) => {
        const as = this.voteStatus(a);
        const bs = this.voteStatus(b);
        if (as === bs) {
          return as === 'OPEN' ?
            a.voteStartDate.localeCompare(b.voteStartDate) :
            a.voteEndDate.localeCompare(b.voteEndDate)
        } else {
          const statusesOrder = ['IN_VOTE', 'OPEN', 'ENDED'];
          return statusesOrder.indexOf(as) - statusesOrder.indexOf(bs);
        }
      });
    },
    handleSearch(searchPhrase) {
      this.searchPhrase = searchPhrase;
    },
    onScroll(e) {
      this.scrollingUp = this.offsetTop > e.target.scrollingElement.scrollTop;
      this.offsetTop = e.target.scrollingElement.scrollTop;
    },
    newProposal() {
      this.$router.push({ name: 'new_proposal' });
    },
    voteStatus(proposal) {
      const now = moment();
      if (now.isAfter(moment(proposal.voteEndDate))) {
        return 'ENDED';
      }
      if (now.isAfter(moment(proposal.voteStartDate))) {
        return 'IN_VOTE';
      }
      return 'OPEN';
    },
  },
  computed: {
    filteredProposals() {
      return this.proposals.filter(p => {
        const text = `${p.title || ''} ${p.description || ''}`.toLowerCase();
        const words = this.searchPhrase.toLowerCase().split(' ');
        return words.every(w => text.includes(w));
      });
    },
  },
  async mounted() {
    if (this.$vuetify.breakpoint.xsOnly) {
      setTimeout(() => {
        this.ready = true
      }, 300);
    } else {
      this.ready = true;
    }
    await this.reloadData();
    this.loading = false;

    this.$nextTick(async () => {
      const notificationPermissions = Notifications.getNotificationBrowserPermissions();
      if (!localStorage.getItem('pushNotificationsAsked') && this.$refs.pushNotificationConfirm) {
        this.$refs.pushNotificationConfirm.open();
        localStorage.setItem('pushNotificationsAsked', 'true');
      } else if (notificationPermissions === 'granted') {
        const subscriptionValid = await Notifications.validateSubscription();
        if (!subscriptionValid) {
          await Notifications.registerNotifications(null, true);
        }
      }
    });
  },
}
</script>

<style scoped>

</style>
