<template>
    <v-list-item :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''" @click.prevent="showFilePreview">
      <v-list-item-avatar tile >
        <v-icon v-if="!loading" size="30" :color="invertColors ? 'primary lighten-1' : 'secondary darken-1'">{{ fileIcon }}</v-icon>
        <v-progress-circular v-else size="26" width="2" color="primary lighten-1" indeterminate />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-wrap" style="width: 0;">
          {{ file.name || file.filename }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $formatDateAndTime(file.updated) }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ file.user ? file.user.firstName && file.user.lastName ? `${file.user.firstName} ${file.user.lastName}` : file.user.email ? file.user.email : file.user.phone : '' }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="file.fileDescription && file.fileDescription.length > 0" class="caption">
          {{ file.fileDescription }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="showMenu">
        <v-menu left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon small>
              <v-icon>mdi mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="downloadAttachment">
              <v-list-item-title >{{ $t('proposals.files.download_file') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openWhoHasSeenTheFileDialog">
              <v-list-item-title>{{ $t('proposals.files.who_has_seen_the_file') }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="voteStatus === 'OPEN'" @click="deleteFile">
              <v-list-item-title>{{ $t('proposals.files.delete_file') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
      <v-checkbox v-if="!showMenu" style="margin: 12px 12px 0px 12px !important" :input-value="isSelected" @change="updateFileSelected" @click.stop="() => {}"/>
    </v-list-item>
</template>

<script>

export default {
  name: "FilesListItem",
  props: {
    file: {
      type: Object,
      default: () => {},
    },
    disableAttachmentLoading: {
      type: Boolean,
      default: false,
    },
    voteStatus: {
      type: String,
      default: 'OPEN',
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    selectedFiles: {
      type: Array,
      default: () => [],
    },
    invertColors: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    isSelected() {
      return this.selectedFiles.includes(this.file.id);
    },
    fileIcon() {
      if (this.file.contentType === 'text/csv') return 'far fa-file-excel';
      if (this.file.contentType === 'text/javascript') return 'far fa-file-code';

      let filetype = this.file.contentType ? this.file.contentType.split("/") : '';
      switch (filetype[0]) {
        case 'audio': return 'far fa-file-audio';
        case 'text': return 'far fa-file-alt';
        case 'image': return 'far fa-file-image';
        case 'video': return 'far fa-file-video';
        case 'application': {
          switch (filetype[1]) {
            case 'pdf':
              return 'far fa-file-pdf';
            case 'msword':
              return 'far fa-file-word';
            case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
              return 'far fa-file-word';
            case 'x-httpd-php':
              return 'far fa-file-code';
            case 'vnd.ms-powerpoint':
              return 'far fa-file-powerpoint';
            case 'vnd.openxmlformats-officedocument.presentationml.presentation':
              return 'far fa-file-powerpoint';
            case 'vnd.ms-excel':
              return 'far fa-file-excel';
            case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              return 'far fa-file-excel';
            case 'zip':
              return 'far fa-file-archive';
            case 'x-7z-compressed':
              return 'far fa-file-archive';
            case 'vnd.rar':
              return 'far fa-file-archive';
            case 'json':
              return 'far fa-file-code';
          }
        }
      }
      return 'far fa-file';
    },
  },
  methods: {
    updateFileSelected(value) {
      this.$emit('file-selected', this.file.id, value);
    },
    async downloadAttachment() {
      const link = document.createElement('a');
      link.href = await this.$createFileUrl(this.file);
      link.download = this.file.name || this.file.filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    async showFilePreview() {
      if (this.disableAttachmentLoading) return;
      let filetype = this.file.contentType ? this.file.contentType.split("/") : '';

      if (filetype[0] === 'image' || filetype[0] === 'video') {
        this.loading = true;
        this.$emit('loading-attachment-link');

        try {
          if (filetype[0] === 'image') {
            const attachmentUrl = await this.$createFileUrl(this.file);
            this.$emit('open-image', attachmentUrl);
          } else if (filetype[0] === 'video') {
            const attachmentUrl = await this.$getFileUrl(this.file);
            this.$emit('open-video', {link: attachmentUrl, contentType: this.file.contentType});
          }
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
        this.$emit('loaded-attachment-link');
      }
    },
    openWhoHasSeenTheFileDialog() {
      this.$emit('who-has-downloaded-files', this.file.id);
    },
    deleteFile() {
      this.$emit('delete-file', this.file.id)
    }
  },
}
</script>

<style scoped>

</style>
