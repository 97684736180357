/* eslint-disable no-console */

import { register } from 'register-service-worker';

function addMsgListener(sw) {
  if (sw == null) {
    return;
  }

  sw.addEventListener('message', event => {
    if (event.data && event.data.type === 'RELOAD_WINDOW') {
      window.location.reload();
    }
  });
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}sw.js`,
    {
      ready(/*registration*/) {
        console.log(
          'App is being served from cache by a service worker.\n'
          + 'For more details, visit https://goo.gl/AFskqB',
        );
      },
      registered () {
        console.log('Service worker has been registered.')
      },
      cached() {
        console.log('Content has been cached for offline use.');
      },
      updated(registration) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        console.log('New content is available; please refresh.');
      },
      offline() {
        console.log('No internet connection found. App is running in offline mode.');
      },
      error(error) {
        console.error('Error during service worker registration:', error);
      },
    });

  if ('serviceWorker' in navigator) {
    addMsgListener(navigator.serviceWorker);
  }
}

