<template>
  <v-dialog v-model="dialog" max-width="600" @keydown.esc="cancel()">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ $t('proposals.chat.show_who_has_seen') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="cancel()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0 scroll-content">
        <v-list dense>
          <v-list-item v-if="usersList.length === 0">
            <v-list-item-title>{{ $t('proposals.nobody_has_seen_comment') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="user in usersList" :key="user.id">
            <v-list-item-title>{{ user.lastName ? `${user.firstName} ${user.lastName}` : user.email.length > 0 ? user.email : user.phone }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn :class="$vuetify.breakpoint.xsOnly ? 'mx-0' : ''" :block="$vuetify.breakpoint.xsOnly" color="default" @click.native="cancel()">{{$t('close')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'WhoHasSeenCommentDialog',
  data() {
    return {
      dialog: false,
      usersList: [],
    };
  },
  methods: {
    open(usersList) {
      this.usersList = usersList;
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
  },
}
</script>

<style scoped lang="scss">
  .scroll-content {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
</style>
