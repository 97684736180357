/* eslint-disable no-param-reassign */
import Vue from 'vue';
import libphonenumber from "google-libphonenumber";

const ValidationPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $rules() {
          return {
            required: value => !!value || this.$t('validation.field_is_required'),
            email: email => {
              if ((email && email.length === 0) || !email) return true;
              let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return !!re.test(String(email).toLowerCase()) || this.$t('validation.email_is_required');
            },
            spacesNotAllowed: value => !(value && value.indexOf(" ") > -1) || this.$t('validation.spaces_are_not_allowed'),
            passwordHasToMatch: (value, match) => value === match || this.$t('user_profile.new_passwords_has_to_match'),
            mustBeAtLeast6Characters: value => !!value && value.length > 5 || this.$t('user_profile.password_must_be_at_least_6_characters'),
            phone: value => {
              if ((value && value.length === 0) || !value) return true;
              try {
                const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
                const number = phoneUtil.parseAndKeepRawInput(value, 'FI');
                return phoneUtil.isValidNumberForRegion(number, 'FI') || this.$t('validation.phone_number_is_required');
              } catch (error) {
                return this.$t('validation.phone_number_is_required');
              }
            },
            email_or_phone: value => {
              let isValid = this.$rules.email(value);
              if (typeof(isValid) === 'string') {
                isValid = this.$rules.phone(value);
                if (typeof(isValid) === 'string') {
                  isValid = this.$t('validation.either_email_or_phone_required');
                }
              }
              return isValid;
            }
          };
        },
      },
    });
  },
};

export default ValidationPlugin;
