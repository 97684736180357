<template>
  <v-dialog v-model="dialog"
            persistent
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ $t('user_management.edit_user_settings') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="dialog-content">

        <v-form ref="form">

          <v-subheader class="mt-5 pl-0 font-weight-bold secondary--text text--darken-1">{{ $t('user_management.user_info') }}</v-subheader>

          <!--            :rules="[$rules.required, $rules.email, $rules.spacesNotAllowed]"-->
          <v-text-field
            :disabled="currentUser.type === 'COGNITO'"
            :rules="[$rules.email, $rules.spacesNotAllowed]"
            validate-on-blur
            class="mt-5 email"
            :label="$t('user_management.email')"
            autocomplete="username"
            type="text"
            v-model="currentUser.email"
          ></v-text-field>

          <v-text-field
            :rules="[$rules.phone]"
            validate-on-blur
            class="mt-2 email"
            :label="$t('user_management.phone')"
            autocomplete="phone"
            type="text"
            v-model="currentUser.phone"
          ></v-text-field>

          <v-text-field
            :rules="[$rules.required]"
            class="mt-5 firstname"
            :label="$t('user_management.first_name')"
            validate-on-blur
            type="text"
            v-model="currentUser.firstName"
          ></v-text-field>

          <v-text-field
            :rules="[$rules.required]"
            class="mt-5 lastname"
            :label="$t('user_management.last_name')"
            validate-on-blur
            type="text"
            v-model="currentUser.lastName"
          ></v-text-field>
          <v-text-field
            class="mt-2"
            :label="$t('user_management.street_address')"
            type="text"
            v-model="currentUser.streetAddress"
          ></v-text-field>
          <v-text-field
            class="mt-2"
            :label="$t('user_management.postal_code')"
            type="text"
            v-model="currentUser.postalCode"
          ></v-text-field>
          <v-text-field
            class="mt-2"
            :label="$t('user_management.city')"
            type="text"
            v-model="currentUser.city"
          ></v-text-field>

          <div class="subtitle-1 black--text">{{ $t('user_profile.email_notifications') }}</div>
          <v-switch
            v-model="emailNotificationsEnabled"
            color="primary"
            class="mt-2 mb-3 ml-1"
            hide-details
            :label="$t('user_profile.allow_email_notifications')"
          />

          <div class="subtitle-1 black--text">{{ $t('user_profile.sms_notifications') }}</div>
          <v-switch
            v-model="smsNotificationsEnabled"
            color="primary"
            class="mt-2 mb-3 ml-1"
            hide-details
            :label="$t('user_profile.allow_sms_notifications')"
          />
        </v-form>

        <v-subheader class="pl-0 font-weight-bold secondary--text text--darken-1">{{ $t('user_management.user_tenants') }}</v-subheader>
        <template v-if="currentUser.tenantRoles && currentUser.tenantRoles.length > 0">
          <div class="mb-5">
            <v-card v-for="(tenant, index) in currentUser.tenantRoles" :key="index" elevation="0" color="grey lighten-3 mb-2">
              <v-card-text class="pa-0">
                <v-list-item class="px-0">
                  <v-list-item-content class="py-0">
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" class="pa-2 pb-1">
                        <v-autocomplete :label="$t('tenants.organization')" :no-data-text="$t('user_management.no_tenants')" hide-details outlined v-model="tenant.tenant" :items="allTenants" item-value="id" item-text="name"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" class="pa-2">
                        <v-select :label="$t('user_management.tenant_role')" multiple hide-details outlined v-model="tenant.roleSet" :items="translatedTenantRoles"></v-select>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                  <v-list-item-action class="ml-0 mr-2">
                    <v-btn @click="removeTenantFromUser(tenant, index)" icon><v-icon>close</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
            </v-card>
          </div>
        </template>
        <v-btn text outlined block @click="addNewTenantForUser">{{ $t('user_management.add_new_tenant') }}</v-btn>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}.</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :disabled="saving"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="saveUser"
               :loading="saving"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import adminApi from "../../api/admin";

  export default {
    name: "UserDetailsDialog",
    props: {
      allTenantRoles: {
        type: Array,
        default: () => [],
      },
      allTenants: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        dialog: false,
        saving: false,
        currentUser: {},
        showAlert: false,
        smsNotificationsEnabled: false,
        emailNotificationsEnabled: false,
      }
    },
    methods: {
      async saveUser() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }

        this.saving = true;

        try {
          await adminApi.updateUser(this.currentUser.id, {
            email: this.currentUser.email,
            firstName: this.currentUser.firstName,
            lastName: this.currentUser.lastName,
            streetAddress: this.currentUser.streetAddress,
            postalCode: this.currentUser.postalCode,
            city: this.currentUser.city,
            phone: this.currentUser.phone,
            tenantRoles: this.currentUser.tenantRoles,
            emailNotificationsEnabled: this.emailNotificationsEnabled,
            smsNotificationsEnabled: this.smsNotificationsEnabled
          });
          this.dialog = false;
          this.$showSuccessNotification(this.$t('user_management.user_info_updated'));
        } catch (err) {
          this.$handleApiError(this.$t('user_management.user_info_update_failed'));
        }
        this.saving = false;
      },
      openUser(user) {
        this.dialog = true;
        this.currentUser = Object.assign({}, user);
        this.emailNotificationsEnabled = user.enabledNotifications.includes('EMAIL_PROPOSAL');
        this.smsNotificationsEnabled = user.enabledNotifications.includes('SMS_PROPOSAL');
      },
      addNewTenantForUser() {
        this.currentUser.tenantRoles.push({tenant: '', roleSet: []});
      },
      removeTenantFromUser(tenant, index) {
        if (tenant.tenant && tenant.tenant.length > 0) {
          const tenantIndex = this.currentUser.tenantRoles.findIndex(t => t.tenant === tenant.tenant);
          if (tenantIndex > -1) index = tenantIndex;
        }
        this.currentUser.tenantRoles.splice(index, 1);
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.showAlert = false;
          this.$refs.form.reset();
          this.currentUser = {};
          this.$emit('on-dismiss');
        }
      }
    },
    computed: {
      translatedTenantRoles() {
        const roles = [];
        this.allTenantRoles.forEach(role => {
          roles.push({value: role, text: this.$t(`user_management.role.${role}`)});
        });
        return roles;
      },
    },
  }
</script>

<style lang="scss" scoped>
.dialog-content {
  max-height: calc(100vh - 56px);
  padding-bottom: 100px !important;
  overflow: auto;
}

@media only screen and (min-width: 600px) {
  .dialog-content {
    max-height: calc(100vh - 350px);
    padding-bottom: 50px !important;
    overflow: auto;
  }
}
</style>
