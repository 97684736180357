<template>
  <div class="chat-wrapper">
    <div class="chat-container">

      <div class="chat-content">
        <div class="chat-scroller-wrapper" id="chat-pop-up">
          <div class="chat-scroller" ref="chatScroller" dir="ltr" v-scroll.self="onScroll">
            <div class="chat-scroller-content">
              <v-fade-transition>
                <v-container v-show="messagesReady" fluid class="fill-height">
                  <v-row no-gutters class="fill-height">
                    <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="fill-height">
                      <div class="fill-height mt-4">
                        <template v-for="(comment, index) in comments">
                          <div class="top-spacing-ios-fix" v-if="index === 0 && browserIsIOSSafari" :key="`iosSpacingFix_${comment.id}`"></div>
                          <v-row class="scroll-target" :key="`comment_${comment.id}`" :ref="`comment_${comment.id}`"></v-row>
                          <SingleChatMessage :key="comment.id"
                                             :comment="comment"
                                             :proposal="proposal"
                                             @open-image="handleImageView"
                                             :disable-image-loading="imageLoading"
                                             @image-loading="imageLoading = true"
                                             @image-loaded="imageLoading = false"
                                             @deleted="reloadData"/>
                          <v-divider v-if="userLastSeenCommentId === comment.id && index < comments.length - 1" class="mt-3 mb-7" :key="`${comment.id}_divider`"></v-divider>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-fade-transition>
            </div>
          </div>
        </div>
      </div>

      <no-content-view v-if="!loading && comments.length === 0"
                       :title="$t('proposals.chat.no_chat_messages_title')"
                       :description="$t('proposals.chat.no_chat_messages_text')"
                       icon="mdi mdi-chat-alert-outline" />

      <div class="chat-input-wrapper">
        <v-slide-y-reverse-transition>
          <v-container fluid class="pa-3" v-if="ready">
            <v-row no-gutters>
              <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" style="background-color: rgb(241, 239, 227);">
                <v-row no-gutters>
                  <div class="flex-grow-1">
                      <v-textarea :disabled="sendingMessage || voteStatus !== 'OPEN'"
                                  no-resize
                                  rows="1"
                                  auto-grow
                                  v-model="message"
                                  :placeholder="voteStatus === 'OPEN' ? `${$t('proposals.chat.enter_a_message')}...` : $t('proposals.chat.proposal_cannot_be_commented')"
                                  solo
                                  class="chat-input"
                                  autocomplete="off"
                                  append-icon="mdi mdi-camera"
                                  @click:append="openImageAddDialog"
                                  hide-details>
                      </v-textarea>
                  </div>
                  <div style="width: 50px;">
                    <v-row no-gutters class="fill-height" align="end">
                      <v-btn :disabled="message.length === 0"
                             :loading="sendingMessage"
                             @click="sendComment"
                             elevation="2"
                             fab
                             color="primary"
                             class="ml-1">
                        <v-icon>mdi mdi-send</v-icon>
                      </v-btn>
                    </v-row>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-slide-y-reverse-transition>
      </div>
    </div>

    <image-add-dialog :proposal="proposal" ref="ImageAddDialog" :message="message" @file-uploaded="handleFileAdded" />

  </div>
</template>

<script>
import SingleChatMessage from "@/components/Tenant/Common/SingleChatMessage";
import tenantApi from '@/api/tenant';
import NoContentView from "@/components/NoContentView";
import ImageAddDialog from "@/components/Tenant/Common/ImageAddDialog";

export default {
  name: "ProposalChat",
  components: {NoContentView, SingleChatMessage, ImageAddDialog},
  props: {
    proposal: {
      type: [Object],
      default: () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    voteStatus: {
      type: String,
      default: 'OPEN',
    }
  },
  data() {
    return {
      loading: true,
      message: '',
      ready: false,
      messagesReady: false,
      sendingMessage: false,
      isScrolledToBottom: false,
      comments: [],
      scrollTop: false,
      pollMessages: false,
      imageLoading: false,
      files: [],
    }
  },
  methods: {
    handleImageView(imageUrl) {
      this.$emit('handle-image-view', imageUrl)
    },
    async reloadData() {
      try {
        this.comments = await tenantApi.getComments(this.proposal.id);
      } catch (err) {
        this.$handleApiError(err);
      }
      this.handleFirstAnimations();
    },
    async sendComment() {
      if (this.message.length === 0) return;
      try {
        this.sendingMessage = true;
        const comment = await tenantApi.createComment(this.proposal.id, {
          message: this.message
        });
        this.comments.push(comment);
        this.message = '';
      } catch (err) {
        this.$handleApiError(err);
      }

      this.sendingMessage = false;
      this.scrollToBottom();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.chatScroller.scrollTop = this.$refs.chatScroller.scrollHeight;
      });
    },
    scrollToMostRecentMessage() {
      if (this.userLastSeenCommentId) {
        if (this.$refs[`comment_${this.userLastSeenCommentId}`][0]) {
          this.$refs[`comment_${this.userLastSeenCommentId}`][0].scrollIntoView(true);
        }
      }
    },
    handleScrollOnTyping() {
      if (this.isScrolledToBottom) {
        this.scrollToBottom();
      }
    },
    handleFirstAnimations() {
      setTimeout(() => {
        this.ready = true;
      }, 300);
      setTimeout(() => {
        this.messagesReady = true;
        this.$nextTick(() => {
          if (this.scrollTop) {
              this.$refs.chatScroller.scrollTop = this.scrollTop;
          } else {
            this.scrollToMostRecentMessage();
          }
        })
      }, 500);
    },
    onScroll() {
      if (this.isActive) {
        if (this.$refs.chatScroller.scrollTop > 0) {
          this.scrollTop = this.$refs.chatScroller.scrollTop;
        }
      }
      this.isScrolledToBottom = this.$refs.chatScroller.scrollTop + 15 >= this.$refs.chatScroller.scrollHeight - this.$refs.chatScroller.offsetHeight;
    },
    initPolling() {
      if (!this.pollMessages) {
        this.pollMessages = setInterval(() => {
          this.reloadData();
        }, 5000);
      }
    },
    openImageAddDialog() {
      this.$refs.ImageAddDialog.open();
    },
    handleFileAdded() {
      this.message = '';
      this.reloadData();
    }
  },
  computed: {
    userLastSeenCommentId() {
      const lastSeenCommentIndex = this.proposal.commentReads.findIndex(cr => cr.user.id === this.$userInfo.id);
      if (lastSeenCommentIndex > -1) {
        return this.proposal.commentReads[lastSeenCommentIndex].targetId;
      }
      return false;
    },
    browserIsIOSSafari() {
      const userAgent = window.navigator.userAgent;
      return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
    }
  },
  watch: {
    message() {
      this.handleScrollOnTyping();
    },
    isActive(val) {
      if (val) {
        this.messagesReady = false;
        this.reloadData();
        this.initPolling();
      } else {
        this.ready = false;
        this.messagesReady = false;
        clearInterval(this.pollMessages);
        this.pollMessages = false;
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.pollMessages);
    this.pollMessages = false;
  },
  async mounted() {
    await this.reloadData();
    this.initPolling();
    this.loading = false;
  }
}
</script>

<style lang="scss">
  .chat-wrapper {
    height: 100%;
  }

  .chat-container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    min-height: 0;
    height: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;


    .chat-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      min-height: 0;
      min-width: 0;
      z-index: 0;
      flex-direction: column;

      .chat-scroller-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .chat-scroller {
        height: 100%;
        min-height: 100%;
        overflow: hidden scroll;
        //overflow-anchor: none;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;

        .chat-scroller-content {
          //overflow-anchor: none;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          -webkit-box-align: stretch;
          -ms-flex-align: stretch;
          align-items: stretch;
          min-height: 100%;
          display: flex;
          position: relative;
        }

      }
    }

    .chat-input-wrapper {
      position: relative;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      min-height: 56px;

      .chat-input {
        border-radius: 25px;

        &.v-textarea textarea {
          margin-top: 0 !important;
          padding-top: 14px;
          margin-bottom: 10px;
          line-height: 1.15rem;
          max-height: 300px !important;
        }

        .v-input__append-outer {
          margin: 0 !important;
        }
      }

    }
  }

  .top-spacing-ios-fix {
    height: 100px;
  }
</style>
