import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    loggingDisplayState: true,
    mainMenuOpen: false,
    notificationObject: {
      color: 'alert',
      message: '',
      timeOut: 4000,
      showButton: false,
      buttonText: '',
      callback: () => {},
    },
    loadingOverlay: false,
    bottomNavigation: false,
  },
  mutations: {
    setLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    toggleMainMenuState(state) {
      state.mainMenuOpen = !state.mainMenuOpen;
    },
    setPreviousRouteName(state, val) {
      state.previousRouteName = val;
    },
    setLoadingOverlay(state, payload) {
      state.loadingOverlay = payload;
    },
    setLoggingDisplayState(state, payload) {
      state.loggingDisplayState = payload;
    }
  },
  actions: {
    showNotification(context, payload) {
      context.state.notificationObject = {
        color: payload.color,
        message: payload.message,
        timeOut: payload.timeOut !== undefined ? payload.timeOut : 4000,
        showButton: payload.showButton !== undefined ? payload.showButton : false,
        buttonText: payload.buttonText !== undefined ? payload.buttonText : '',
        callback: payload.showButton && payload.callback ? payload.callback : () => {},
      };
    },
  }
})
