<template>
  <div>
    <v-btn v-if="isShown" @click="hide" small class="close-btn" icon><v-icon color="white">mdi mdi-close</v-icon></v-btn>
    <Viewer :options="viewerOptions"
            :images="imageToDisplay"
            @inited="viewerInited"
            class="viewer"
            ref="viewer">
      <template slot-scope="scope">
        <img v-for="src in scope.images" style="display: none;" :src="src" :key="src">
      </template>
    </Viewer>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.min.css';
import Viewer from 'v-viewer/src/component.vue';

export default {
  name: "ImageViewer",
  components: {Viewer},
  props: {
    viewerOptions: {
      type: Object,
      default: () => {},
    },
    imageToDisplay: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      viewer: null,
    }
  },
  methods: {
    viewerInited(viewer) {
      this.viewer = viewer;
    },
    show() {
      this.viewer.show();
    },
    hide() {
      this.viewer.hide();
    },
  },
  computed: {
    isShown() {
      if (this.viewer) return this.viewer.isShown;
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .close-btn {
    z-index: 9999;
    position: fixed;
    top: 10px;
    right: 10px;
  }
</style>
