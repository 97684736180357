import axios from 'axios';
/*
 * Used for tenant API calls.
 * for example: get tenants users, get tenants data
 *
 * X-TENANT-ID header required for ALL calls
 */

const tenantApi = {
  setSelectedTenant(tenant) {
    axios.defaults.headers.common['X-tenant-id'] = tenant;
  },
  async getCurrentTenant() {
    const response = await axios.get('/api/tenant');
    return response.data;
  },
  /**
   *
   * @param {!object} data
   * @param {!string} data.name
   * @returns {Promise<any>}
   */
  async updateCurrentTenant(data) {
    const requestData = {
      name: data.name,
      city: data.city,
    }
    const response = await axios.post('/api/tenant', requestData);
    return response.data;
  },
  async getHellos() {
    const response = await axios.get('/api/tenant/hello')
    return response.data;
  },
  async addHello(msg) {
    const response = await axios.post('/api/tenant/hello', { msg });
    return response.data;
  },
  async deleteHello(helloId) {
    const response = await axios.delete(`/api/tenant/hello/${helloId}`);
    return response.data;
  },
  async loadTenantUsers() {
    const response = await axios.get('/api/tenant/user');
    return response.data;
  },
  /**
   *
   * @param {!Object} data
   * @param {!string} data.firstName
   * @param {!string} data.lastName
   * @param {!string} data.email
   * @returns {Promise<any>}
   */
  async addNewUserToTenant(data) {
    const response = await axios.post('/api/tenant/user', {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      roleSet: data.roleSet,
    });
    return response.data;
  },
  async removeUserFromTenant(userId) {
    const response = await axios.delete(`/api/tenant/user/${userId}`);
    return response.data;
  },
  async getRoles() {
    const response = await axios.get('/api/tenant/roles');
    return response.data;
  },
  async addRole(id, role) {
    const response = await axios.post(`/api/tenant/user/${id}/role/${role}`);
    return response.data;
  },
  async deleteRole(id, role) {
    const response = await axios.delete(`/api/tenant/user/${id}/role/${role}`);
    return response.data;
  },

  async getProposal(id) {
    const response = await axios.get(`/api/tenant/proposal/${id}`);
    return response.data;
  },
  async getProposals() {
    const response = await axios.get('/api/tenant/proposal');
    return response.data;
  },
  async createProposal(proposal) {
    const response = await axios.post('/api/tenant/proposal', proposal);
    return response.data;
  },
  async uploadAttachment(proposalId, file, uploadProgressCallBack) {
    const formData = new FormData();
    formData.append('file', file.file, file.filename);
    formData.append('name', file.name);
    formData.append('type', file.type);
    formData.append('comment', file.fileDescription);
    const response = await axios.post(`/api/tenant/proposal/${proposalId}/attachment`, formData, { onUploadProgress: uploadProgressCallBack });
    return response.data;
  },
  async createFileAccessToken(attachment) {
    const response = await axios.get(`/api/tenant/proposal/${attachment.proposalId}/attachment/${attachment.id}/access`);
    return response.data;
  },
  async downloadAttachment(attachment) {
    const response = await axios.get(`/api/tenant/proposal/${attachment.proposalId}/attachment/${attachment.id}`, {responseType: 'blob'});
    return response.data;
  },
  async deleteAttachment(proposalId, attachmentId) {
    const response = await axios.delete(`/api/tenant/proposal/${proposalId}/attachment/${attachmentId}`);
    return response.data;
  },
  async updateProposal(id, proposal) {
    const response = await axios.post(`/api/tenant/proposal/${id}`, proposal);
    return response.data;
  },
  async deleteProposal(id) {
    const response = await axios.delete(`/api/tenant/proposal/${id}`);
    return response.data;
  },
  async getComments(proposalId) {
    const response = await axios.get(`/api/tenant/proposal/${proposalId}/comment`);
    return response.data;
  },
  async createComment(proposalId, comment) {
    const response = await axios.post(`/api/tenant/proposal/${proposalId}/comment`, comment);
    return response.data;
  },
  async deleteComment(proposalId, commentId) {
    const response = await axios.delete(`/api/tenant/proposal/${proposalId}/comment/${commentId}`);
    return response.data;
  },
  async getVotes(proposalId) {
    const response = await axios.get(`/api/tenant/proposal/${proposalId}/vote`);
    return response.data;
  },
  async vote(proposalId, voteType, user, comment) {
    const response = await axios.post(`/api/tenant/proposal/${proposalId}/vote`, { type: voteType, user, comment });
    return response.data;
  },
  async generateReport(proposalId, addComments) {
    const response = await axios.get(`/api/tenant/proposal/${proposalId}/report`, {
      responseType: 'blob',
      params: {
        comments: addComments,
      },
    });
    return response.data;
  },

  async getThread(id) {
    const response = await axios.get(`/api/tenant/chat/${id}`);
    return response.data;
  },
  async getThreads() {
    const response = await axios.get('/api/tenant/chat');
    return response.data;
  },
  async createThread(thread) {
    const response = await axios.post('/api/tenant/chat', thread);
    return response.data;
  },
  async updateThread(id, thread) {
    const response = await axios.post(`/api/tenant/chat/${id}`, thread);
    return response.data;
  },
  async deleteThread(id) {
    const response = await axios.delete(`/api/tenant/chat/${id}`);
    return response.data;
  },
  async getMessages(threadId) {
    const response = await axios.get(`/api/tenant/chat/${threadId}/comment`);
    return response.data;
  },
  async deleteMessage(threadId, messageId) {
    const response = await axios.delete(`/api/tenant/chat/${threadId}/comment/${messageId}`);
    return response.data;
  },
  async createMessage(threadId, message) {
    const response = await axios.post(`/api/tenant/chat/${threadId}/comment`, message);
    return response.data;
  },
  async uploadThreadAttachment(threadId, file, uploadProgressCallBack) {
    const formData = new FormData();
    formData.append('file', file.file, file.filename);
    formData.append('name', file.name);
    formData.append('type', file.type);
    formData.append('comment', file.fileDescription);
    const response = await axios.post(`/api/tenant/chat/${threadId}/attachment`, formData, { onUploadProgress: uploadProgressCallBack });
    return response.data;
  },
  async createThreadFileAccessToken(attachment) {
    const response = await axios.get(`/api/tenant/chat/${attachment.threadId}/attachment/${attachment.id}/access`);
    return response.data;
  },
  async downloadThreadAttachment(attachment) {
    const response = await axios.get(`/api/tenant/chat/${attachment.threadId}/attachment/${attachment.id}`, {responseType: 'blob'});
    return response.data;
  },
  async deleteThreadAttachment(threadId, attachmentId) {
    const response = await axios.delete(`/api/tenant/chat/${threadId}/attachment/${attachmentId}`);
    return response.data;
  },
};

export default tenantApi;
