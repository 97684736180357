<template>
  <v-app light id="app">

    <template v-if="loadingOverlay">
      <v-fade-transition>
        <div class="loading-overlay">
          <v-row class="fill-height" justify="center" align="center">
            <v-col cols="4" class="text-center secondary--text caption">
              <div class="loader"></div>
              <p style="margin-top: 10px" v-if="loggingDisplayState">{{ $t('login.logging_in') }}...</p>
              <p style="margin-top: 10px" v-else>{{ $t('login.logging_out') }}...</p>
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
    </template>

    <template v-if="showApplication">

      <v-snackbar v-model="snackbar" top multi-line :timeout="notification.timeOut" :color="notification.color">
        {{ notification.text }}
        <template v-slot:action>
          <template v-if="notification.showButton">
            <v-btn small outlined text @click="() => { notification.callback(); snackbar = false;}">
              {{ notification.buttonText }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn icon @click="snackbar = false">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </template>
      </v-snackbar>

      <v-navigation-drawer
        v-if="shouldMenuBeAvailable"
        v-model="menuOpenState"
        :mini-variant="miniMenu && $vuetify.breakpoint.lgAndUp"
        app
        fixed
        dark
        color="primary"
        width="300"

      >
        <v-list class="pa-0 elevation-4 primary" dark style="position: relative;">
          <div class="app-nav-title-bg"></div>
          <v-list-item @click="toggleMiniMenu" :style="{padding: miniMenu ? '5px' : '20px 10px 20px 23px'}" >
            <v-list-item-content class="py-0">
              <v-list-item-title class="font-weight-bold mb-0">
                <img :style="{width: miniMenu ? '100%' : '75%'}" src="/img/Hemman-isannointi-logo-white.svg" :alt="$t('title')" />
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon small>
                <v-icon>chevron_left</v-icon>
              </v-btn>
            </v-list-item-action>

          </v-list-item>
        </v-list>

        <v-list :dense="$vuetify.breakpoint.mdAndDown">

          <template v-if="$userInfo && $userInfo.tenants.length > 0">
            <TenantSelect :miniMenu="miniMenu" />
          </template>

          <template v-if="filteredPrimaryPages.length > 0">
            <v-list-item
              v-for="(page, index) in filteredPrimaryPages"
              :key="index+'primary'"
              :to="getPageLink(page)"
              @click="closeMenuIfOnSamePage(page.link)"
              exact
            >
              <v-list-item-action>
                <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <template v-if="filteredSecondaryPages.length > 0">
            <v-list-item
              v-for="(page, index) in filteredSecondaryPages"
              :key="index+'secondary'"
              @click="closeMenuIfOnSamePage(page.link)"
              :to="getPageLink(page)"
              exact
            >
              <v-list-item-action>
                <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider ></v-divider>
          </template>

          <template v-if="filteredTenantAdminPages.length > 0">
            <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.organization_admin') }}</v-subheader>
            <v-list-item
              v-for="(page, index) in filteredTenantAdminPages"
              :key="index+'tadmin'"
              @click="closeMenuIfOnSamePage(page.link)"
              :to="getPageLink(page)"
              exact>
              <v-list-item-action>
                <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <template v-if="filteredAdminPages.length > 0">
            <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.application_admin') }}</v-subheader>
            <v-list-item
              v-for="(page, index) in filteredAdminPages"
              :key="index+'admin'"
              @click="closeMenuIfOnSamePage(page.link)"
              :to="getPageLink(page)"
              exact>
              <v-list-item-action>
                <v-icon color="grey lighten-1">{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider ></v-divider>
          </template>

          <v-list-item :to="{name: 'userprofile'}" @click="closeMenuIfOnSamePage('userprofile')">
            <v-list-item-action>
              <v-icon color="grey lighten-1">person</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('user_profile.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon color="grey lighten-1">logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('login.logout') }}</v-list-item-title>
              <v-list-item-subtitle class="caption text-align-center" v-if="$isLoggedIn && !miniMenu">{{ $userInfo.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-list>

      </v-navigation-drawer>

      <v-main>
        <router-view/>
      </v-main>

    </template>
    <template v-else-if="showNoTenantsView">
      <NoTenantsView></NoTenantsView>
    </template>
  </v-app>
</template>

<script>
  import TenantSelect from "./components/TenantSelect.vue";
  import TenantService from './service/tenant.service';
  import NoTenantsView from "./views/NoTenantsView.vue";
  import UiEventBus from '@/UiEventBus';
  import * as Sentry from '@sentry/vue';

  export default {
    components: { TenantSelect, NoTenantsView },
    data() {
      return {
        notification: {
          color: '',
          text: '',
          buttonText: '',
          showButton: false,
          timeOut: -1,
          callback: () => {},
        },
        snackbar: false,
        miniMenu: false,
        bottomNavSelected: '',
        showBottomNav: true,
        menuOpenState: false,
        pages: [
          {
            title: this.$tc('proposals.title', 2),
            icon: 'mdi mdi-file-document-edit-outline',
            link: 'proposals',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
          {
            title: this.$tc('threads.title', 2),
            icon: 'mdi mdi-chat',
            link: 'threads',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: [],
            primary: true,
          },
        ],
        tenantPages: [
          {
            title: this.$t('tenants.management_title'),
            icon: 'settings',
            link: 'tenant_settings',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: ['tenant_admin'],
            primary: false,
          },
          {
            title: this.$t('tenants.organization_users'),
            icon: 'people',
            link: 'tenant_users',
            params: () => ({ tenantId: TenantService.tenant }),
            roles: ['tenant_admin'],
            primary: false,
          },
        ],
        adminPages: [
          {
            title: this.$t('user_management.title'),
            icon: 'people',
            link: 'users',
            roles: ['ADMIN'],
            primary: false,
          },
          {
            title: this.$t('tenants.title'),
            icon: 'business',
            link: 'tenants',
            roles: ['ADMIN'],
            primary: false,
          },
        ],
      }
    },
    methods: {
      getPageLink(page) {
        const link = {
          name: page.link,
        }

        if ('params' in page) {
          link.params = page.params();
        }
        return link;
      },
      logout() {
        this.$store.commit('setLoggingDisplayState', false);
        UiEventBus.$emit('userLoggedOut');
      },
      goToLink(name) {
        this.$router.push({name: name});
      },
      toggleMiniMenu() {
        if (this.$vuetify.breakpoint.lgAndUp) {
          this.miniMenu = !this.miniMenu;
        } else {
          this.$store.commit('toggleMainMenuState');
        }
      },
      closeMenuIfOnSamePage(link) {
        if (this.$vuetify.breakpoint.mdAndDown && this.$router.currentRoute.name === link) {
          this.menuOpenState = false;
        }
      },
      handleSelectedBottomNavItem(name) {
        const isPagePrimary = this.pages.findIndex(p => p.primary && p.link === name) > -1;
        if (isPagePrimary) {
          this.bottomNavSelected = name;
          this.showBottomNav = true;
        } else {
          this.showBottomNav = false;
        }
      },
    },
    watch: {
      $isLoggedIn(newValue, oldValue) {
        console.log('isLoggedInChange', newValue, oldValue);
        if (newValue === true) {
          UiEventBus.$emit('loginSuccess');
        }
      },
      loadingOverlay(to) {
        if (to) {
          setTimeout(() => {
            this.$store.commit('setLoadingOverlay', false);
          }, 2000);
        }
      },
      notificationObject(to) {
        this.notification.color = to.color;
        this.notification.text = to.message;
        this.snackbar = true;
        this.notification.buttonText = to.buttonText;
        this.notification.showButton = to.showButton;
        this.notification.timeOut = to.timeOut;
        this.notification.callback = to.callback;
      },
      menuOpenComputed(to) {
        this.menuOpenState = to;
      },
      menuOpenState(to) {
        if (to !== this.$store.state.mainMenuOpen) {
          this.$store.commit('toggleMainMenuState');
        }
      },
      $route(to, from) {
        if (from && from.name) this.$store.commit('setPreviousRouteName', from.name);
        this.handleSelectedBottomNavItem(to.name);
      },
      currentRouteName(val) {
        if (!this.$isLoggedIn && val !== 'login') {
          this.$router.push({name: 'login'});
        }
      },
    },
    computed: {
      showApplication() {
        return !this.$isLoggedIn || this.$isAdmin || (this.$userInfo && this.$userInfo.tenants.length > 0);
      },
      showNoTenantsView() {
        return this.$isLoggedIn && !this.$isAdmin && this.$userInfo && this.$userInfo.tenants.length === 0;
      },
      loadingOverlay() {
        return this.$store.state.loadingOverlay;
      },
      shouldMenuBeAvailable() {
        return this.$isLoggedIn;
      },
      notificationObject() {
        return this.$store.state.notificationObject;
      },
      menuOpenComputed() {
        let menuState = true;
        if (this.$vuetify.breakpoint.mdAndDown) {
          menuState = this.$store.state.mainMenuOpen;
        }
        return menuState;
      },
      filteredSecondaryPages() {
        if (!this.$tenant) {
          return [];
        }
        let pages = [];
        this.pages.forEach(page => {
          if (!page.primary) {
            pages.push(page);
          }
        })
        return pages;
      },
      filteredTenantAdminPages() {
        if (this.$tenant && this.$userInfo.tenantRoles.find(tr => tr.tenant === this.$tenant && tr.roleSet.includes('tenant_admin'))) {
          return this.tenantPages;
        }
        return [];
      },
      filteredAdminPages() {
        if (this.$isAdmin) {
          return this.adminPages;
        }
        return [];
      },
      filteredPrimaryPages() {
        if (!this.$tenant) {
          return [];
        }
        let pages = [];
        this.pages.forEach(page => {
          if (page.primary) {
            pages.push(page);
          }
        })
        return pages;
      },
      currentRouteName() {
        return this.$router.currentRoute.name;
      },
      loggingDisplayState() {
        return this.$store.state.loggingDisplayState;
      },
    },
    created() {
      // If application is opened from link, and some other tenant id has been saved in tenant service
      // This sets the one from URL
      if ('tenantId' in this.$router.currentRoute.params) {
        TenantService.setTenant(this.$router.currentRoute.params.tenantId);
      }
    },
    mounted() {
      if (this.$userInfo) {
        if (Sentry) {
          Sentry.setUser({ email: this.$userInfo.email });
        }
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.menuOpenState = true;
      }
      this.handleSelectedBottomNavItem(this.$router.currentRoute.name);
    },
  }
</script>

<style lang="scss">
@import "./sass/commonstyles.scss";
</style>

<style scoped>
.app-nav-title-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./assets/hemman_bg.jpg');
  background-size: cover;
  background-position-y: -70px;
  opacity: 0.1;
}
</style>

