import axios from 'axios';

/*
 * Public api calls
 * /api/public/**
 *
 * No authentication required for calls
 */
const publicApi = {
  async sendLoginCode(subject, loginType) {
    const data = {
      subject,
      loginType,
    }
    await axios.post('/api/public/login/code', data);
  }
}

export default publicApi;
