<template>
  <v-container pa-0 fluid fill-height>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <div class="text-center pl-5 pr-5">
            <v-icon size="100" color="secondary">{{ icon }}</v-icon>
            <div class="headline font-weight-bold mt-5 mb-5">{{ title }}</div>
            <div class="subtitle-1 mb-5">{{ description }}</div>
            <v-btn v-if="button" @click="button.callback" class="ma-2" color="primary"><v-icon left>{{ button.icon }}</v-icon> {{ button.text }}</v-btn>
            <v-btn v-if="showRefreshButton" @click="$reloadApp" class="ma-2" color="primary" text outlined><v-icon left>refresh</v-icon> {{ $t('reload') }}</v-btn>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NoContentView",
  props: ['title', 'description', 'icon', 'button', 'showRefreshButton'],
}
</script>

<style scoped>

</style>
