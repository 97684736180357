<template>
  <hb-basic-page :search-title="`${$t('threads.search')}...`"
                 :loading="loading"
                 :fill-height="threads.length === 0"
                 color="secondary darken-1"
                 @handle-search="handleSearch"
                 name="threads-view">

    <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" class="pb-15" v-scroll="onScroll">

      <PushNotificationConfirm ref="pushNotificationConfirm" />

      <v-row v-if="!loading && threads.length > 0">
        <template v-for="thread in filteredThreads">
          <ThreadCard :key="thread.id" :thread="thread" />
        </template>
      </v-row>

      <no-content-view v-if="!loading && threads.length === 0"
                       :button="{callback: newThread, text: $t('threads.new_thread'), icon: 'mdi mdi-plus' }"
                       :title="$t('threads.no_threads')"
                       :description="$t('threads.no_threads_found')"
                       icon="mdi mdi-file-document-edit-outline" />

      <v-fade-transition>
        <v-btn
          color="secondary darken-1"
          dark
          v-show="ready && threads.length > 0"
          fixed
          bottom
          right
          rounded
          style="transition: all 0.2s ease-in-out"
          :fab="!scrollingUp"
          @click="newThread"
          large
        >
          <v-icon :left="scrollingUp">mdi mdi-plus</v-icon>
          <span v-show="scrollingUp">{{ $t('threads.new_thread') }}</span>
        </v-btn>
      </v-fade-transition>
    </v-col>

  </hb-basic-page>
</template>

<script>
import ThreadCard from "@/components/Tenant/Threads/ThreadCard";
import NoContentView from "@/components/NoContentView";
import tenantApi from '@/api/tenant';
import PushNotificationConfirm from "@/components/PushNotificationConfirm";
import Notifications from '@/notifications';
import moment from 'moment';

export default {
  name: "ThreadsView",
  components: {PushNotificationConfirm, NoContentView, ThreadCard},
  data() {
    return {
      loading: true,
      ready: false,
      searchPhrase: '',
      offsetTop: 0,
      scrollingUp: true,
      threads: []
    }
  },
  methods: {
    async reloadData() {
      try {
        this.threads = await tenantApi.getThreads();
        this.sortThreads();
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    sortThreads() {
      this.threads.sort((a, b) => {
        const as = this.discussionStatus(a);
        const bs = this.discussionStatus(b);
        if (as === bs) {
          const ad = a.newestComment ? a.newestComment.created : a.created;
          const bd = b.newestComment ? b.newestComment.created : b.created;
          return bd.localeCompare(ad);
        } else {
          return as === 'OPEN' ? -1 : 1;
        }
      });
    },
    handleSearch(searchPhrase) {
      this.searchPhrase = searchPhrase;
    },
    onScroll(e) {
      this.scrollingUp = this.offsetTop > e.target.scrollingElement.scrollTop;
      this.offsetTop = e.target.scrollingElement.scrollTop;
    },
    newThread() {
      this.$router.push({ name: 'new_thread' });
    },
    discussionStatus(thread) {
      return moment().isAfter(moment(thread.discussionEndTime)) ? 'ENDED' : 'OPEN';
    },
  },
  computed: {
    filteredThreads() {
      return this.threads.filter(p => {
        const text = `${p.title || ''} ${p.description || ''}`.toLowerCase();
        const words = this.searchPhrase.toLowerCase().split(' ');
        return words.every(w => text.includes(w));
      });
    },
  },
  async mounted() {
    if (this.$vuetify.breakpoint.xsOnly) {
      setTimeout(() => {
        this.ready = true
      }, 300);
    } else {
      this.ready = true;
    }
    await this.reloadData();
    this.loading = false;

    this.$nextTick(async () => {
      const notificationPermissions = Notifications.getNotificationBrowserPermissions();
      if (!localStorage.getItem('pushNotificationsAsked')  && this.$refs.pushNotificationConfirm) {
        this.$refs.pushNotificationConfirm.open();
        localStorage.setItem('pushNotificationsAsked', 'true');
      } else if (notificationPermissions === 'granted') {
        const subscriptionValid = await Notifications.validateSubscription();
        if (!subscriptionValid) {
          await Notifications.registerNotifications(null, true);
        }
      }
    });
  },
}
</script>

<style scoped>

</style>
