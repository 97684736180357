<template>
  <v-col cols="12">

    <v-form>
      <v-text-field  v-model="username"
                     :label="$t('login.username')"
                     color="white"
                     :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
                     :outlined="uiComponentProperties.outlined"
                     autocomplete="username"
                     autofocus
                     hide-details
                     class="mb-8"
                     v-on:keyup.enter="login" />

      <v-text-field  v-model="password"
                     type="password"
                     :label="$t('login.password')"
                     color="white"
                     :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
                     :outlined="uiComponentProperties.outlined"
                     autocomplete="current-password"
                     hide-details
                     class="mb-8"
                     v-on:keyup.enter="login" />

      <div class="text-center">
        <v-btn large
               class="primary--text font-weight-bold"
               block
               color="secondary"
               :loading="signingIn"
               @click="login">
          <v-icon left>mdi mdi-login</v-icon>
          {{ $t('login.sign_in') }}</v-btn>

      </div>
    </v-form>

  </v-col>
</template>

<script>
import axios from "axios";
import UserService from "@/service/user.service";
import userApi from "@/api/user";
import UiEventBus from "@/UiEventBus";

export default {
  name: 'UsernamePasswordLoginView',
  props: ['uiComponentProperties'],
  data() {
    return {
      username: '',
      password: '',
      signingIn: false,
    }
  },
  methods: {

    async login() {
      this.signingIn = true;
      try {
        const formData = new FormData();
        formData.append("username", this.username);
        formData.append("password", this.password);
        await axios.post('/api/public/login', formData);
        UserService.springLogin();
        const me = await userApi.getMe();
        UserService.setUserInfo(me);
        UiEventBus.$emit('loginSuccess');
      } catch (error) {
        this.$handleApiError(error);
      }
      this.signingIn = false;
      //this.$router.push({name: 'passwordlogin'});
    },
  },
}
</script>

<style scoped>

</style>
