<template>
  <hb-basic-page :title="$t('tenants.management_title')"
              :loading="loading"
              :subtle-loading="subtleLoading"
              name="tenant-management">

        <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3">
          <v-card :color="$vuetify.breakpoint.xsOnly ? 'primary darken-1' : 'white'" :class="$vuetify.breakpoint.xsOnly ? 'white--text' : ''">
            <v-card-title primary-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''">
              <div>
                <v-avatar :size="$vuetify.breakpoint.xsOnly ? '68' : '48'" :color="tenant.enabled ? $randomizeColorFromString(tenant.id, 75, 70) : 'grey lighten-2'" class="tenant-avatar" :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-3'">
                  {{ tenant.name ? tenant.name.substring(0, 2) : '' }}
                </v-avatar>
              </div>
              <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="text-center text-sm-left">{{ tenant.name }}</h3>
                <div class="body-1 text-center text-sm-left"><span>{{ tenant.city }}</span></div>
                <div class="body-1 text-center text-sm-left"><span>{{ $t('tenants.statuses.' + tenant.status) }}</span></div>
              </div>
            </v-card-title>

            <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'justify-center pt-0' : ''">
              <v-btn class="ma-1" text :color="$vuetify.breakpoint.xsOnly ? 'primary lighten-3' : 'primary'" @click="openEditTenantDialog">{{ $t('tenants.edit_organization') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

    <tenant-details-dialog ref="tenantDetailsDialog" @on-dismiss="onTenantDetailsDialogDismiss" />

  </hb-basic-page>
</template>

<script>
  import TenantDetailsDialog from "../../components/Tenant/TenantManagement/TenantDetailsDialog";
  import tenantApi from "../../api/tenant";

  export default {
    name: "TenantManagementView",
    components: {
      TenantDetailsDialog: TenantDetailsDialog,
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        tenant: {},
      }
    },
    methods: {
      openEditTenantDialog() {
        this.$refs.tenantDetailsDialog.open(this.tenant);
      },
      onTenantDetailsDialogDismiss() {
        this.getTenant();
      },
      async getTenant() {
        if (this.tenant.id) {
          this.subtleLoading = true;
        } else {
          this.loading = true;
        }
        try {
          this.tenant = await tenantApi.getCurrentTenant();
        } catch (error) {
          this.$handleApiError(error);
        }
        this.loading = false;
        this.subtleLoading = false;
      },
    },
    mounted() {
      this.getTenant();
    },
  }
</script>

<style lang="scss">
  .user-avatar {
    overflow: hidden;
  }

  .users-header {
    .v-list__tile {
      height: 30px;
    }
  }
</style>
