<template>
  <v-card :class="voteResultClass">
    <v-card-subtitle v-if="voteStatus !== 'ENDED'" class="pb-0 text-uppercase secondary--text text--darken-2">
      {{ $t('proposals.voting_score') }}
    </v-card-subtitle>
    <v-card-text class="pb-0">
      <v-alert :value="voteStatus === 'ENDED'"
               :color="alertColor"
               icon="mdi mdi-scale-balance"
               prominent
               class="white--text font-weight-bold">
        {{ proposal.result !== null ? $t('proposals.long_vote_results.' + proposal.result) : ''}}
      </v-alert>
      <div v-if="voteStatus === 'ENDED'">
        {{ $t('proposals.decision_date') }}: <strong>{{ $formatDateNoTime(proposal.voteEndDate) }}</strong>
      </div>
      <div>
        {{ voteStatus === 'OPEN' ? $t('proposals.advance_voters') : $t('proposals.voters') }}: <strong>{{ votes.length }}</strong>
      </div>
      <div class="my-3">
        <div v-if="voteStatus === 'ENDED' && proposal.result !== null && ($isHousingManager || $isHousingCoordinator)">
          <v-btn @click="openDecisionNumberDialog" color="primary" outlined text small>{{ $t('proposals.give_decision_number') }}</v-btn>
        </div>
        <div class="mt-2" v-if="voteStatus === 'ENDED' && !proposal.decisionNumber && ($isHousingManager || isCreatorSameThanUser)">
          <v-btn @click="addTimeForVoting" color="primary" outlined text small>{{ $t('proposals.add_time_for_voting') }}</v-btn>
        </div>
        <div v-if="voteStatus === 'ENDED' && proposal.result !== null && proposal.decisionNumber" style="margin-top:8px">
          {{ $t('proposals.decision_number') }}: <strong>{{ proposal.decisionNumber }}</strong>
        </div>
        <div v-if="voteStatus === 'ENDED' && ($isHousingManager || $isHousingCoordinator) && proposal.decisionNumber" style="margin-top:8px">
          <v-checkbox
            :label="$t('proposals.add_comments_to_report')"
            v-model="addCommentsToReport"
          />
          <v-btn :loading="generatingReport" @click="generateReport" color="primary" outlined text small>{{ $t('proposals.generate_report') }}</v-btn>
        </div>
      </div>
    </v-card-text>
    <v-card-text class="pt-0">
      <v-list dense v-if="votes.length > 0" color="transparent">
        <template v-for="vote in votes">
          <v-list-item class="px-0" :key="vote.id">
            <v-list-item-title>{{ vote.user.firstName && vote.user.lastName ? `${vote.user.firstName} ${vote.user.lastName}` : vote.user.email }}</v-list-item-title>
            <v-list-item-icon style="min-width: 100px;">
              <v-icon :color="vote.type === 'APPROVE' ? 'success' : 'grey lighten-1'">mdi mdi-check</v-icon>
              <v-icon :color="vote.type === 'DENY' ? 'error' : 'grey lighten-1'" class="pl-3">mdi mdi-close</v-icon>
              <v-icon :color="vote.type === 'MOVE_TO_MEETING' ? 'warning' : 'grey lighten-1'" class="pl-3">mdi mdi-arrow-right-bold</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-list>
      <template v-else>
        {{ $t('proposals.no_votes_yet') }}
      </template>
    </v-card-text>

    <add-time-for-vote-dialog ref="addTimeForVoteDialog" :proposal="proposal"/>
    <DecisionNumberDialog ref="decisionNumberDialog" :proposal="proposal" @decision-number-saved="handleReload" @handle-image-view="handleImageView"/>
  </v-card>
</template>
<script>
import DecisionNumberDialog from "@/components/Tenant/SingleProposal/DecisionNumberDialog";
import AddTimeForVoteDialog from "@/components/Tenant/SingleProposal/AddTimeForVoteDialog";
export default {
  name: "VotingScore",
  components: {AddTimeForVoteDialog, DecisionNumberDialog},
  props: {
    proposal: {
      type: Object,
      default: () => {},
    },
    votes: {
      type: Array,
      default: () => {},
    },
    voteStatus: {
      type: String,
      default: 'OPEN',
    }
  },
  data() {
    return {
      generatingReport: false,
      addCommentsToReport: true,
    };
  },
  methods: {
    addTimeForVoting() {
      this.$refs.addTimeForVoteDialog.open();
    },
    openDecisionNumberDialog() {
      this.$refs.decisionNumberDialog.open();
    },
    handleReload() {
      this.$emit('handle-reload');
    },
    handleImageView(imageUrl) {
      this.$emit('handle-image-view', imageUrl)
    },
    handleVideoPlay(video) {
      this.$emit('handle-video-play', video);
    },
    async generateReport() {
      try {
        this.generatingReport = true;
        const a = await this.$tenantApi.generateReport(this.proposal.id, this.addCommentsToReport);
        const blob = new Blob([a], { type: a.type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Pöytäkirja';
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        this.$handleApiError(error);
      }
      this.generatingReport = false;
    },
  },
  computed: {
    voteResultClass() {
      if (this.voteStatus === 'ENDED') {
        if (this.proposal.result === 'APPROVE') return 'approved';
        if (this.proposal.result === 'DENY') return 'denied';
        return 'moved';
      }
      return '';
    },
    alertColor() {
      if (this.proposal.result === 'APPROVE') return 'success';
      if (this.proposal.result === 'DENY') return 'error';
      return 'warning';
    },
    isCreatorSameThanUser() {
      return this.proposal?.user?.id === this.$userInfo?.id
    }
  }
}
</script>

<style lang="scss" scoped>
.approved {
  background-color: #eeffef;
}
.denied {
  background-color: #fff5f1;
}
.moved {
  background-color: #fff6ea;
}
</style>
