var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.voteStatus !== 'ENDED')?_c('v-card-subtitle',{staticClass:"pb-0 text-uppercase secondary--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('proposals.voting_score_manager'))+" ")]):_vm._e(),_c('v-card-text',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.$t('proposals.housing_manager_info_for_voting'))+" ")]),_c('v-card-text',{staticClass:"pt-0"},[(_vm.filteredTenantUsers.length > 0)?_c('v-card-subtitle',{staticClass:"pa-0 pt-4 secondary--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('proposals.not_voted_users'))+": "),_c('strong',[_vm._v(_vm._s(_vm.filteredTenantUsers.length))])]):_vm._e(),_c('v-list',{staticClass:"pt-0",attrs:{"dense":"","color":"transparent"}},[(_vm.filteredTenantUsers.length > 0)?[_vm._l((_vm.filteredTenantUsers),function(user){return [_c('v-list-item',{key:'housing_manager_view '+user.id,staticClass:"px-0 pb-2"},[_c('v-list-item-title',[_vm._v(_vm._s(user.firstName && user.lastName ? ((user.firstName) + " " + (user.lastName)) : user.email))]),_c('v-list-item-icon',{staticStyle:{"min-width":"100px"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'success' : 'grey lighten-1'},on:{"click":function($event){return _vm.submitVote('APPROVE', user)}}},[_vm._v("mdi mdi-check-circle-outline")])]}}],null,true)}),_c('div',{staticClass:"pl-3"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'error' : 'grey lighten-1'},on:{"click":function($event){return _vm.submitVote('DENY', user)}}},[_vm._v("mdi mdi-close-circle-outline")])]}}],null,true)})],1),_c('div',{staticClass:"pl-3"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'warning' : 'grey lighten-1'},on:{"click":function($event){return _vm.submitVote('MOVE_TO_MEETING', user)}}},[_vm._v("mdi mdi-arrow-right-bold-circle-outline")])]}}],null,true)})],1)],1)],1)]})]:_vm._e(),(_vm.votes.length > 0)?_c('v-card-subtitle',{staticClass:"pa-0 pt-4 secondary--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('proposals.voted_users'))+": "),_c('strong',[_vm._v(_vm._s(_vm.votes.length))])]):_vm._e(),(_vm.votes.length > 0)?[_vm._l((_vm.votes),function(vote){return [_c('v-list-item',{key:vote.id,staticClass:"px-0 pb-2"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(vote.user ? vote.user.firstName+' '+vote.user.lastName : vote.user.email))]),(vote.creator)?_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.$t('proposals.voted'))+": "+_vm._s(vote.creator ? vote.creator.firstName+' '+vote.creator.lastName : vote.creator.email)),(vote.comment)?_c('span',[_c('br'),_vm._v(_vm._s(_vm.$t('proposals.comment'))+": "+_vm._s(vote.comment && vote.comment.message)+" ")]):_vm._e()]):_vm._e()],1),((vote.creator && vote.creator.id) && _vm.isManagerOrCoordinator(vote.creator))?_c('v-list-item-icon',{staticStyle:{"min-width":"100px","height":"38px"}},[_c('div',{},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover || vote.type === 'APPROVE' ? 'success' : 'grey lighten-1'},on:{"click":function($event){return _vm.submitVoteByManagerOrCoordinator('APPROVE', vote.user)}}},[_vm._v("mdi mdi-check-circle-outline")])]}}],null,true)})],1),_c('div',{staticClass:"pl-3"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover || vote.type === 'DENY'  ? 'error' : 'grey lighten-1'},on:{"click":function($event){return _vm.submitVoteByManagerOrCoordinator('DENY', vote.user)}}},[_vm._v("mdi mdi-close-circle-outline")])]}}],null,true)})],1),_c('div',{staticClass:"pl-3"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover || vote.type === 'MOVE_TO_MEETING'  ? 'warning' : 'grey lighten-1'},on:{"click":function($event){return _vm.submitVoteByManagerOrCoordinator('MOVE_TO_MEETING', vote.user)}}},[_vm._v("mdi mdi-arrow-right-bold-circle-outline")])]}}],null,true)})],1)]):_c('v-list-item-icon',{staticStyle:{"min-width":"100px"}},[_c('v-icon',{attrs:{"color":vote.type === 'APPROVE' ? 'success' : 'grey lighten-1'}},[_vm._v("mdi mdi-check")]),_c('v-icon',{staticClass:"pl-3",attrs:{"color":vote.type === 'DENY' ? 'error' : 'grey lighten-1'}},[_vm._v("mdi mdi-close")]),_c('v-icon',{staticClass:"pl-3",attrs:{"color":vote.type === 'MOVE_TO_MEETING' ? 'warning' : 'grey lighten-1'}},[_vm._v("mdi mdi-arrow-right-bold")])],1)],1)]})]:_vm._e()],2)],1),_c('CommentWhenVotingDialog',{ref:"commentWhenVotingDialog",attrs:{"housingmanager":true,"proposal":_vm.proposal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }