<template>
  <div>
    <input type="file" style="display:none" accept="*" :multiple="false" ref="hiddenFilesInput" @change="fileSelected" :persistent="uploading">
    <v-dialog v-model="dialog" max-width="600" @keydown.esc="cancel()">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="white--text" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ $t('proposals.add_file_dialog.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploading" icon dark @click="cancel()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-text-field
            v-model="file.name"
            :label="$t('proposals.add_file_dialog.file_name')"
            hide-details
            type="text"
            class="mb-4 text-subtitle-1"
            flat
            outlined
          ></v-text-field>
          <v-textarea
            v-model="file.fileDescription"
            :label="$t('proposals.add_file_dialog.file_description')"
            auto-grow
            outlined
            rows="3"
            hide-details
          ></v-textarea>
          <v-progress-linear
            color="secondary"
            :value="uploadPercentCompleted"
            rounded
            height="6"
            class="mt-5"
          ></v-progress-linear>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
          <v-spacer></v-spacer>
          <v-btn :disabled="uploading" :class="$vuetify.breakpoint.xsOnly ? 'mx-0' : ''" :block="$vuetify.breakpoint.xsOnly" color="default" @click.native="cancel()">
            {{ $t('proposals.add_file_dialog.cancel') }}</v-btn>
          <v-btn :loading="uploading" :class="$vuetify.breakpoint.xsOnly ? 'mx-0 mb-2' : ''" :block="$vuetify.breakpoint.xsOnly" color="primary" @click.native="addFile()">
            {{ $t('proposals.add_file_dialog.add_file') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import tenantApi from '@/api/tenant';

export default {
  name: 'FileAddDialog',
  props: {
    proposal: {
      type: [Object],
      default: () => null,
    },
    thread: {
      type: [Object],
      default: () => null,
    },
  },
  data() {
    return {
      dialog: false,
      uploading: false,
      file: {},
      uploadPercentCompleted: 0,
    };
  },
  methods: {
    open() {
      this.$refs.hiddenFilesInput.click();
    },
    cancel() {
      this.dialog = false;
    },
    async addFile() {
      try {
        this.uploading = true;
        const handleUploadProgress = (progressEvent) => {
          this.uploadPercentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        };
        if (this.proposal) {
          await tenantApi.uploadAttachment(this.proposal.id, this.file, handleUploadProgress);
        } else {
          await tenantApi.uploadThreadAttachment(this.thread.id, this.file, handleUploadProgress);
        }
        this.$emit('file-uploaded');
      } catch (err) {
        this.$handleApiError(err);
      }
      this.dialog = false;
      this.uploading = false;
    },
    async fileSelected(event) {
      const f = event.target.files[0];
      const asDataUrl = await this.toBase64(f);
      this.file = {file: f, dataUrl: asDataUrl, name: f.name, type: f.type, filename: f.name, fileDescription: ''};
      this.dialog = true;
      event.target.value = '';
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.file = {};
        this.uploadPercentCompleted = 0;
      }
    }
  },
}
</script>

<style scoped lang="scss">
.scroll-content {
  max-height: calc(100vh - 300px);
  overflow: auto;
}
</style>
