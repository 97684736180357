<template>
  <hb-basic-page :title="proposal ? proposal.title : ''"
                 :loading="loading"
                 :fill-height="true"
                 :no-container-padding="true"
                 :no-gutters="true"
                 :back-button="{show: true, fallback: 'proposals'}"
                 :context-menu="voteStatus === 'ENDED' || voteStatus === 'IN_VOTE' ? false : contextMenuItems"
                 name="single-proposal-view">
    <template v-slot:app-bar-extension>
      <v-tabs background-color="primary darken-1">
        <v-tabs-slider color="accent"></v-tabs-slider>
        <v-tab v-for="item in tabs" :key="item.name" class="font-weight-bold" style="text-transform: none;" replace :to="item.route">
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </template>

    <v-col class="fill-height" v-scroll="onScroll">
      <v-tabs-items v-model="selectedTab" class="fill-height transparent" v-if="proposal">
        <v-tab-item style="height: 100%;" value="info">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" >

                <VotingScore v-if="voteStatus === 'ENDED'"
                             :proposal="proposal"
                             :votes="votes"
                             class="mb-3"
                             @handle-reload="reloadData"
                             :vote-status="voteStatus"
                             @handle-image-view="handleImageView"/>

                <ProposalDetails :proposal="proposal"
                                 class="mb-3" />

                <Vote v-if="(voteStatus === 'OPEN' || voteStatus === 'IN_VOTE') && userCanVote"
                      ref="voteCard"
                      :proposal="proposal"
                      class="mb-3"
                      :vote-status="voteStatus"
                      :users-own-vote="usersOwnVote"
                      @update-proposal="reloadData"
                      @update-votes="reloadData" />

                <VotingScore v-if="(voteStatus === 'OPEN' && (!$isHousingManager && !$isHousingCoordinator)) || (voteStatus === 'IN_VOTE' && (!$isHousingManager && !$isHousingCoordinator))"
                             :proposal="proposal"
                             :votes="votes"
                             class="mb-3"
                             :vote-status="voteStatus"
                             @handle-image-view="handleImageView" />

                <voting-score-housing-manager v-if="(voteStatus === 'OPEN' && ($isHousingManager || $isHousingCoordinator)) || (voteStatus === 'IN_VOTE' && ($isHousingManager || $isHousingCoordinator))"
                                              :proposal="proposal"
                                              :votes="votes"
                                              class="mb-3"
                                              :vote-status="voteStatus"
                                              @update-proposal="reloadData"
                                              @update-votes="reloadData"
                                              />

              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item style="height: 100%;" value="chat">
          <ProposalChat :proposal="proposal"
                        :is-active="selectedTab === 'chat'"
                        :vote-status="voteStatus"
                        @handle-image-view="handleImageView" />
        </v-tab-item>
        <v-tab-item style="height: 100%; width: 100%;" value="files">
          <v-container fluid :class="proposal.attachments.length === 0 ? 'fill-height' : ''">
            <v-row :class="proposal.attachments.length === 0 ? 'fill-height' : ''">
              <v-col :class="proposal.attachments.length === 0 ? 'fill-height' : ''" cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3" >
                <Files :scrolling-up="scrollingUp"
                       :proposal="proposal"
                       :vote-status="voteStatus"
                       @file-uploaded="reloadData"
                       @file-deleted="reloadData"
                       @handle-video-play="handleVideoPlay"
                       @handle-image-view="handleImageView" />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>

      <confirm-dialog ref="confirmDialog" />

      <ImageViewer ref="imageViewer" :viewer-options="viewerOptions" :image-to-display="imageToDisplay" />

      <VideoPlayer ref="videoPlayer" :options="videoPlayerOptions" />

    </v-col>
  </hb-basic-page>
</template>

<script>
import ProposalDetails from '@/components/Tenant/SingleProposal/ProposalDetails';
import VotingScore from '@/components/Tenant/SingleProposal/VotingScore';
import ProposalChat from '@/components/Tenant/SingleProposal/ProposalChat';
import tenantApi from '@/api/tenant';
import ConfirmDialog from '@/components/ConfirmDialog';
import Vote from '@/components/Tenant/SingleProposal/Vote';
import moment from 'moment';
import Files from "@/components/Tenant/Common/Files";
import VideoPlayer from "@/components/VideoPlayer";
import ImageViewer from "@/components/ImageViewer";
import VotingScoreHousingManager from "@/components/Tenant/SingleProposal/VotingScoreHousingManager";

export default {
  name: "SingleProposalView",
  components: {
    VotingScoreHousingManager,
    ImageViewer, Files, Vote, ConfirmDialog, ProposalChat, VotingScore, ProposalDetails, VideoPlayer},
  props: {
    id: {
      type: [String, Number],
      default: -1,
    },
    tab: {
      type: [String],
      default: 'info',
    },
    voteNow: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      scrollingUp: true,
      offsetTop: 0,
      selectedTab: 'info',
      tabs: [
        { name: this.$t('proposals.info_and_voting'), route: { name: 'single_proposal', params: { id: this.id, tab: 'info' } } },
        { name: this.$t('proposals.discussion'), route: { name: 'single_proposal', params: { id: this.id, tab: 'chat' } } },
        { name: this.$t('proposals.files.title'), route: { name: 'single_proposal', params: { id: this.id , tab: 'files' } } },
      ],
      proposal: null,
      votes: [],
      ready: false,
      imageToDisplay: [],
      viewerOptions: {
        inline: false,
        button: false,
        navbar: false,
        title: false,
        toolbar: false,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
      },
      videoPlayerOptions: {
        muted: true,
        controls: true,
        autoplay: true,
        language: 'fi',
        aspectRatio: this.$vuetify.breakpoint.smAndUp ? '16:9' : undefined,
        fill: this.$vuetify.breakpoint.xsOnly,
      }
    }
  },
  methods: {
    async reloadData() {
      try {
        this.proposal = await tenantApi.getProposal(this.id);
        this.votes = this.proposal.votes;
      } catch (err) {
        this.$handleApiError(err);
        this.$router.replace({ name: 'proposals' });
      }
    },
    onScroll(e) {
      if (this.voteNow === 'vote') {
        this.$router.replace({name: 'single_proposal', params: { id: this.proposal.id, tab: 'info' }});
      }
      this.scrollingUp = this.offsetTop > e.target.scrollingElement.scrollTop;
      this.offsetTop = e.target.scrollingElement.scrollTop;
    },
    editProposal() {
      this.$router.push({ name: 'edit_proposal', params: {id: this.proposal.id.toString()} });
    },
    async deleteProposal() {
      if (!(this.proposal.user && this.proposal.user.id === this.$userInfo.id) && !(this.$isAdmin)) return;
      const deleteConfirmed = await this.$refs.confirmDialog.open(this.$t('proposals.proposal_delete_dialog.title'), this.$t('proposals.proposal_delete_dialog.message'), { color: 'warning', agree: this.$t('proposals.proposal_delete_dialog.delete_yes'), cancel: this.$t('proposals.proposal_delete_dialog.delete_no') });
      if (!deleteConfirmed) return;
      try {
        this.proposal = await tenantApi.deleteProposal(this.id);
        this.$router.replace({ name: 'proposals' });
        this.$showSuccessNotification(this.$t('proposals.proposal_delete_dialog.proposal_delete_confirmed'));
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    handleImageView(imageUrl) {
      if (this.imageToDisplay.length > 0) {
        URL.revokeObjectURL(this.imageToDisplay[0]);
      }
      this.imageToDisplay = [imageUrl];
      this.$refs.imageViewer.show();
    },
    handleVideoPlay(video) {
      this.$refs.videoPlayer.openPlayer({
        type: video.contentType,
        src: video.link,
      });
    },
  },
  watch: {
    selectedTab(val) {
      if (val !== this.tab) {
        this.$router.replace({ name: 'single_proposal', params: { id: this.id, tab: val } });
      }
      this.reloadData();
    },
    tab(val) {
      if (val !== this.selectedTab) {
        this.selectedTab = val;
      }
    },
  },
  computed: {
    voteStatus() {
      const now = moment();
      if (this.proposal) {
        if (now.isAfter(moment(this.proposal.voteEndDate))) {
          return 'ENDED';
        }
        if (now.isAfter(moment(this.proposal.voteStartDate))) {
          return 'IN_VOTE';
        }
      }
      return 'OPEN';
    },
    usersOwnVote() {
      return this.votes.filter(vote => vote.user.id === this.$userInfo.id)[0];
    },
    contextMenuItems() {
      const contextMenuItems = [];
      if (this.proposal) {
        if ((this.proposal.user && this.proposal.user.id === this.$userInfo.id) || this.$isAdmin) {
          contextMenuItems.push({
            callback: this.editProposal,
            text: this.$t('proposals.edit_proposal'),
            icon: 'mdi mdi-pencil'
          });
          contextMenuItems.push({
            callback: this.deleteProposal,
            text: this.$t('proposals.delete_proposal'),
            icon: 'mdi mdi-delete'
          });
        }
      }
      return contextMenuItems;
    },
    userCanVote() {
      return !this.$isHousingManager && !this.$isHousingCoordinator;
    }
  },
  async mounted() {
    if (this.$vuetify.breakpoint.xsOnly) {
      setTimeout(() => {
        this.ready = true
      }, 300);
    } else {
      this.ready = true;
    }
    await this.reloadData();
    this.loading = false;
    if (this.voteNow === 'vote' && this.proposal !== null) {
      this.$nextTick(() => {
        if (this.$refs.voteCard) {
          this.$vuetify.goTo(this.$refs.voteCard, {
            duration: 200,
            easing: 'easeInOutCubic',
          });
        }
      });
    }
    this.selectedTab = this.tab;
  }
}
</script>

<style lang="scss">
.v-slide-group__prev--disabled {
  display: none !important;
}
</style>
