<template>
  <hb-basic-page :title="$t('user_profile.title')"
              :loading="loading"
              :subtle-loading="subtleLoading"
              name="user-profile">

    <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3">
      <v-row>
        <v-col cols="12">
          <v-card v-if="user">
            <v-card-title primary-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''">
              <div>
                <v-avatar :size="$vuetify.breakpoint.xsOnly ? '68' : '48'" :color="user.enabled ? $randomizeColorFromString(user.firstName + user.lastName + user.email + user.phone) : 'grey lighten-2'" class="user-avatar" :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-3'">
                  <!--<img :src="$getGravatarUrl(user.email)" alt="Avatar" />-->
                  <v-img v-if="user.imageKey != null" :src="profileImageUrl"></v-img>
                  <v-icon v-else-if="!user.agentUser" color="white" style="margin-top: 8px;" :size="$vuetify.breakpoint.xsOnly ? '60' : '42'">fas fa-user</v-icon>
                  <v-icon v-else color="white" style="margin-top: 8px;" :size="$vuetify.breakpoint.xsOnly ? '68' : '46'">fas fa-users-cog</v-icon>
                </v-avatar>
              </div>
              <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="text-center text-sm-left">
                  {{ user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email ? user.email : user.phone }}
                </h3>
                <div class="body-1 text-center text-sm-left">
                  <span v-for="role in tenantRoles" :key="role" :style="{color: $randomizeColorFromString(role, 60, 45)}">
                  {{ getRoleText(role) }} </span>
                </div>
                <div class="body-1 text-center text-sm-left">
                  <span v-for="role in systemRoles" :key="role" :style="{color: $randomizeColorFromString(role, 60, 45)}">
                  {{ getRoleText(role) }} </span>
                </div>
              </div>
            </v-card-title>

            <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''">
              <!--<v-btn text color="primary" @click="changeImage">{{ $t('user_profile.change_image') }}</v-btn>-->
              <v-btn :block="$vuetify.breakpoint.xsOnly" text color="primary" @click="editUserProfile">{{ $t('user_profile.edit_profile') }}</v-btn>
              <v-btn :block="$vuetify.breakpoint.xsOnly" v-if="user.type === 'COGNITO'" class="ma-1" text color="primary" @click="openChangePasswordDialog">{{ $t('user_profile.change_password') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" class="pt-0">
          <v-card>
            <v-card-title class="pb-0">
              <div>
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="mb-0">{{ $t('user_profile.push_notifications') }}</h3>
              </div>
            </v-card-title>
            <v-card-text>
              {{ $t('user_profile.push_notifications_explained') }}
            </v-card-text>
            <v-card-text>
              <div class="subtitle-1 black--text">{{ $t('user_profile.account_notifications') }}</div>
              {{ $t('user_profile.account_notifications_explained') }}
            </v-card-text>
            <v-card-actions>
              <v-switch
                v-model="pushNotificationsEnabledForProposal"
                @change="updateEnabledNotifications"
                color="primary"
                class="mt-0 mb-2 ml-2"
                hide-details
                :label="$t('user_profile.allow_notifications_proposal_events')"
              ></v-switch>
            </v-card-actions>
            <v-card-actions>
              <v-switch
                v-model="pushNotificationsEnabledForChat"
                @change="updateEnabledNotifications"
                color="primary"
                class="mt-0 mb-2 ml-2"
                hide-details
                :label="$t('user_profile.allow_notifications_chat')"
              ></v-switch>
            </v-card-actions>
            <v-card-text>
              <div class="subtitle-1 black--text">{{ $t('user_profile.device_notifications') }}</div>
              <template v-if="pushNotificationsAreAllowedOnThisDevice">
                <p class="body-2">{{ $t('user_profile.notifications_allowed_in_browser_settings') }}</p>
              </template>
              <template v-if="pushNotificationsAreDeniedOnThisDevice">
                <p class="body-2">{{ $t('user_profile.notifications_denied_in_browser_settings') }}</p>
              </template>
              <template v-if="pushNotificationsHaveNotBeenAskedOnThisDevice">
                <p class="body-2">{{ $t('user_profile.notifications_not_asked') }}</p>
                <v-btn @click="enablePushNotifications" outlined small text>{{ $t('user_profile.allow_notifications') }}</v-btn>
              </template>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="pt-0">
          <v-card>
            <v-card-title class="pb-0">
              <div>
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="mb-0">{{ $t('user_profile.email_notifications') }}</h3>
              </div>
            </v-card-title>
            <v-card-text class="pb-2">
              {{ $t('user_profile.email_notifications_explained') }}
              <template v-if="!user.email || user.email.length === 0">
                {{ $t('user_profile.email_notifications_require_email') }}
              </template>
            </v-card-text>
            <v-card-actions v-if="!user.email || user.email.length === 0" class="pt-0">
              <v-btn text small color="primary" @click="editUserProfile">{{ $t('user_profile.email_set_email_address') }}</v-btn>
            </v-card-actions>
            <v-card-actions v-if="user.email && user.email.length > 0" >
              <v-switch
                v-model="emailNotificationsEnabled"
                @change="updateEnabledNotifications"
                color="primary"
                class="mt-0 mb-2 ml-2"
                hide-details
                :label="$t('user_profile.allow_email_notifications')"
              ></v-switch>
            </v-card-actions>
            <v-card-actions>
              <v-switch
                v-model="emailNotificationsEnabledForChat"
                @change="updateEnabledNotifications"
                color="primary"
                class="mt-0 mb-2 ml-2"
                hide-details
                :label="$t('user_profile.allow_email_notifications_chat')"
              ></v-switch>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" class="pt-0">
          <v-card>
            <v-card-title class="pb-0">
              <div>
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="mb-0">{{ $t('user_profile.sms_notifications') }}</h3>
              </div>
            </v-card-title>
            <v-card-text class="pb-2">
              {{ $t('user_profile.sms_notifications_explained') }}
              <template v-if="!user.phone || user.phone.length === 0">
                {{ $t('user_profile.sms_notifications_require_phone_number') }}
              </template>
            </v-card-text>
            <v-card-actions v-if="!user.phone || user.phone.length === 0" class="pt-0">
              <v-btn text small color="primary" @click="editUserProfile">{{ $t('user_profile.sms_set_phone_number') }}</v-btn>
            </v-card-actions>
            <v-card-actions v-if="user.phone && user.phone.length > 0">
              <v-switch
                v-model="smsNotificationsEnabled"
                @change="updateEnabledNotifications"
                color="primary"
                class="mt-0 mb-2 ml-2"
                hide-details
                :label="$t('user_profile.allow_sms_notifications')"
              ></v-switch>
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>
    </v-col>

    <input type="file" style="display:none" accept="image/*" :multiple="false" ref="imageFileInput" @change="onImageFileChanged">
    <edit-user-profile-dialog @user-profile-updated="handleUserProfileUpdated" ref="editUserProfileDialog"></edit-user-profile-dialog>
    <change-password-dialog :user="user" ref="changePasswordDialog" />

  </hb-basic-page>
</template>

<script>
  import ChangePasswordDialog from '@/components/UserProfile/ChangePasswordDialog.vue';
  import EditUserProfileDialog from "@/components/UserProfile/EditUserProfileDialog.vue";
  import UserService from '@/service/user.service';
  import userApi from "@/api/user";
  import Notifications from '@/notifications';

  export default {
    name: "UserProfile",
    components: {
      EditUserProfileDialog,
      ChangePasswordDialog: ChangePasswordDialog,
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        savingImage: false,
        pushNotificationsEnabled: false,
        pushNotificationsEnabledForChat: false,
        pushNotificationsEnabledForProposal: false,
        emailNotificationsEnabled: false,
        emailNotificationsEnabledForChat: false,
        smsNotificationsEnabled: false,
        notificationBrowserPermissions: false,
        enabledNotifications: [],
      }
    },
    methods: {
      changeImage() {
        this.$refs.imageFileInput.click();
      },
      async onImageFileChanged(fileAddedEvent) {
        const image = {
          file: fileAddedEvent.target.files[0],
          filename: fileAddedEvent.target.value.split('\\').pop()
        };

        this.savingImage = true;
        try {
          const user = await userApi.uploadProfileImage(image.file, image.filename);
          UserService.setUserInfo(user);
        } catch (error) {
          this.$handleApiError(error);
        }
        this.savingImage = false;
      },
      handleUserProfileUpdated(user) {
        UserService.setUserInfo(user);
      },
      editUserProfile() {
        this.$refs.editUserProfileDialog.openUser(this.user);
      },
      getRoleText(role) {
        return this.$t('user_management.role.' + role);
      },
      openChangePasswordDialog() {
        this.$refs.changePasswordDialog.open();
      },
      async enablePushNotifications() {
        const notificationPermission = await Notifications.registerNotifications((permission) => {
          this.notificationBrowserPermissions = permission;
        });
        if (notificationPermission) {
          try {
            await userApi.enableNotifications();
            const user = await userApi.getMe();
            UserService.setUserInfo(user);
          } catch (error) {
            console.log(error);
            this.$showErrorNotification(this.$t('errors.error_updating_push_notification_settings'));
          }
        }
        this.notificationBrowserPermissions = Notifications.getNotificationBrowserPermissions();
      },
      async updateEnabledNotifications() {
        try {
          const enabledNotifications = [];
          if (this.pushNotificationsEnabledForProposal) {
            enabledNotifications.push('PUSH_PROPOSAL');
          }
          if (this.pushNotificationsEnabledForChat) {
            enabledNotifications.push('PUSH_COMMENT');
          }
          if (this.emailNotificationsEnabled) {
            enabledNotifications.push('EMAIL_PROPOSAL');
          }
          if (this.smsNotificationsEnabled) {
            enabledNotifications.push('SMS_PROPOSAL')
          }
          if (this.emailNotificationsEnabledForChat) {
            enabledNotifications.push('EMAIL_COMMENT');
          }
          const user = await userApi.updateEnabledNotifications(enabledNotifications);
          this.enabledNotifications = user.enabledNotifications;
          this.pushNotificationsEnabledForChat = this.enabledNotifications.includes('PUSH_COMMENT');
          this.pushNotificationsEnabledForProposal = this.enabledNotifications.includes('PUSH_PROPOSAL');
          this.emailNotificationsEnabled = this.enabledNotifications.includes('EMAIL_PROPOSAL');
          this.emailNotificationsEnabledForChat = this.enabledNotifications.includes('EMAIL_COMMENT');
          this.smsNotificationsEnabled = this.enabledNotifications.includes('SMS_PROPOSAL');
          UserService.setUserInfo(user);
        } catch (error) {
          this.$handleApiError(error);
        }
      },
    },
    computed: {
      profileImageUrl() {
        return this.$getImageUrl(this.user.imageKey);
      },
      user() {
        if (this.$isLoggedIn)
          return this.$userInfo;

        return {};
      },
      tenantRoles() {
        const roles = this.user.roles.filter(role => !(role === 'tenant_admin' || role === 'tenant_basic'));
        return roles.filter(r => r.startsWith('tenant_'));
      },
      systemRoles() {
        return this.user.roles.filter(r => !r.startsWith('tenant_'));
      },
      pushNotificationsHaveNotBeenAskedOnThisDevice() {
        return this.notificationBrowserPermissions === 'default';
      },
      pushNotificationsAreDeniedOnThisDevice() {
        return !this.notificationBrowserPermissions || this.notificationBrowserPermissions === 'denied' || this.notificationBrowserPermissions === 'disabled';
      },
      pushNotificationsAreAllowedOnThisDevice() {
        return this.notificationBrowserPermissions === 'granted';
      },
    },
    async mounted() {
      await UserService.refreshUserInfo();
      this.loading = false;
      this.pushNotificationsEnabled = this.$userInfo.subscriptionEnabled;
      this.enabledNotifications = this.$userInfo.enabledNotifications;
      this.pushNotificationsEnabledForChat = this.enabledNotifications.includes('PUSH_COMMENT');
      this.pushNotificationsEnabledForProposal = this.enabledNotifications.includes('PUSH_PROPOSAL');
      this.emailNotificationsEnabled = this.enabledNotifications.includes('EMAIL_PROPOSAL');
      this.emailNotificationsEnabledForChat = this.enabledNotifications.includes('EMAIL_COMMENT');
      this.smsNotificationsEnabled = this.enabledNotifications.includes('SMS_PROPOSAL');
      this.notificationBrowserPermissions = Notifications.getNotificationBrowserPermissions();
    },
  }
</script>

<style lang="scss">
  .user-avatar {
    overflow: hidden;
  }

  .users-header {
    .v-list__tile {
      height: 30px;
    }
  }
</style>
