import Vue from 'vue';
import TenantService from './tenant.service';
import userApi from '@/api/user';

const AUTH_TYPES = {
  COGNITO: 'cognito',
  SPRING: 'spring',
};

export { AUTH_TYPES };

export default new Vue({
  data() {
    return {
      innerUserInfo: JSON.parse(localStorage.getItem("userInfo" )),
      innerSession: JSON.parse(localStorage.getItem("session" )),
      authType: localStorage.getItem("auth_type"),
      pollUserInfoInterval: null,
    }
  },
  computed: {
    loggedIn() {
      return this.innerUserInfo != null;
    },
  },
  methods: {
    cognitoLogin(cognitoSession) {
      this.setAuthType(AUTH_TYPES.COGNITO)
      this.setSession(cognitoSession);
    },
    springLogin() {
      this.setAuthType(AUTH_TYPES.SPRING)
      this.setSession(null);
    },
    setAuthType(authType) {
      localStorage.setItem('auth_type', authType);
      this.authType = authType;
    },
    setSession(session) {
      localStorage.setItem("session", JSON.stringify(session));
      this.innerSession = session;
    },
    getSession() {
      return this.innerSession;
    },
    setUserInfo(getMeResponse) {
      const currentTenant = TenantService.tenant;
      if (currentTenant == null || !getMeResponse.tenants.find(t => t.id === currentTenant)) {
        if (getMeResponse.tenants.length > 0) {
          TenantService.setTenant(getMeResponse.tenants[0].id);
          if (!sessionStorage.getItem('redirectPath')) {
            location.reload();
          }
        } else {
          TenantService.clear();
        }
      }

      const userInfo = {};
      userInfo.roles = getMeResponse.roles;
      userInfo.firstName = getMeResponse.firstName;
      userInfo.lastName = getMeResponse.lastName;
      userInfo.streetAddress = getMeResponse.streetAddress;
      userInfo.postalCode = getMeResponse.postalCode;
      userInfo.city = getMeResponse.city;
      userInfo.username = getMeResponse.username;
      userInfo.phone = getMeResponse.phone;
      userInfo.agentUser = getMeResponse.agentUser;
      userInfo.enabled = getMeResponse.enabled;
      userInfo.subject = getMeResponse.subject;
      userInfo.id = getMeResponse.id;
      userInfo.imageKey = getMeResponse.imageKey;
      userInfo.email = getMeResponse.email;
      userInfo.tenants = getMeResponse.tenants;
      userInfo.type = getMeResponse.type;
      userInfo.created = getMeResponse.created;
      userInfo.updated = getMeResponse.updated;
      userInfo.subscriptionEnabled = getMeResponse.subscriptionEnabled;
      userInfo.enabledNotifications = getMeResponse.enabledNotifications;
      userInfo.tenantRoles = getMeResponse.tenantRoles;

      userInfo.tenants.sort((a, b) => a.name.localeCompare(b.name));

      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      this.innerUserInfo = userInfo;
    },
    getUserInfo() {
      return this.innerUserInfo;
    },
    clearUserInfo() {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("session");
      localStorage.removeItem("auth_type");
      this.innerUserInfo = null;
      this.innerSession = null;
      this.authType = null;
    },
    async refreshUserInfo() {
      if (this.loggedIn) {
        const me = await userApi.getMe();
        this.setUserInfo(me);
      }
    },
    userTenantsChanged(me) {
      if (me.tenants.length !== this.innerUserInfo.tenants.length) {
        return true;
      }
      for (const t of me.tenants) {
        const t2 = this.innerUserInfo.tenants.find(t2 => t2.id === t.id);
        if (!t2 || t.updated !== t2.updated ||
          t.unreadProposalActivity !== t2.unreadProposalActivity ||
          t.unreadThreadActivity !== t2.unreadThreadActivity) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    this.pollUserInfoInterval = setInterval(async () => {
      if (this.loggedIn) {
        const me = await userApi.getMe();
        if (me && this.innerUserInfo) {
          if (me.updated !== this.innerUserInfo.updated || this.userTenantsChanged(me)) {
            this.setUserInfo(me);
          }
        }
      }
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.pollUserInfoInterval);
  }
})
