<template>
  <v-col cols="12">

    <v-alert class="text-left"
             :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : ''"
             :text="uiComponentProperties.text"
             :value="error"
             type="error">
      {{ error_message }}
    </v-alert>

    <v-alert class="text-left"
             :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : ''"
             :text="uiComponentProperties.text"
             :value="true"
             v-if="codeSent && loginType == 'SMS'"
             type="success">
      {{ $t('login.code_sent_to_phone') }}
    </v-alert>

    <v-alert class="text-left"
             :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : ''"
             :text="uiComponentProperties.text"
             :value="true"
             v-if="codeSent && loginType == 'EMAIL'"
             type="success">
      {{ $t('login.code_sent_to_email') }}
    </v-alert>

      <v-form v-if="!codeSent">

        <div class="white--text text-center mt-3">
          <p>{{ $t('login.send_code_advice') }} </p>
        </div>

        <v-text-field  v-model="username"
                       :label="$t('login.code_phone_or_email')"
                       color="white"
                       :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
                       :outlined="uiComponentProperties.outlined"
                       autocomplete="username"
                       autofocus
                       hide-details
                       class="mb-8"
                       v-on:keydown.enter.prevent="requestCode" />

        <div class="text-center">
          <v-btn v-on:keyup.enter="requestCode"
                 class="primary--text font-weight-bold"
                 block
                 :small="$vuetify.breakpoint.xsOnly"
                 color="secondary"
                 :loading="signingIn"
                 @click="requestCode">
            <v-icon left>mdi mdi-lock-open-outline</v-icon>
            {{ $t('login.send_code_for_login') }}</v-btn>

          <div class="text-center font-weight-light">
            <v-btn :rounded="uiComponentProperties.rounded"
                   small
                   block
                   color="secondary"
                   class="mt-3 font-weight-light"
                   text
                   @click="codeSent = true">
              {{ $t('login.sign_in_with_code') }}
            </v-btn>
          </div>
          <div class="text-center font-weight-light">
            <v-btn :rounded="uiComponentProperties.rounded"
                   small
                   block
                   color="secondary"
                   class="mt-1 font-weight-light"
                   text
                   @click="$emit('signInAsHousingManager')">
              {{ $t('login.sign_in_as_housing_manager') }}
            </v-btn>
          </div>
          <div class="login-guide white--text text-center caption mt-5">
            <p>{{ $t('login.by_clicking_request_you_agree') }} <a href="https://www.hemman.fi/tietosuojaseloste/" class="secondary--text" target="_blank">{{ $t('login.terms_of_service') }}</a>.</p>
            <p>{{ $t('login.to_learn_more_about_privacy') }} <a href="https://www.hemman.fi/tietosuojaseloste/" class="secondary--text" target="_blank">{{ $t('login.privacy_policy') }}</a>.</p>
          </div>

        </div>
      </v-form>

      <v-form v-else>
        <v-text-field  v-model="username"
                       :label="$t('login.code_phone_or_email')"
                       color="white"
                       :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
                       :outlined="uiComponentProperties.outlined"
                       autocomplete="username"
                       autofocus
                       hide-details
                       class="mb-4"
                       v-on:keydown.enter.prevent="requestCode"
                        />

        <v-text-field v-model="password"
                      :label="$t('login.login_code')"
                      color="white"
                      :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
                      :outlined="uiComponentProperties.outlined"
                      autocomplete="username"
                      autofocus
                      inputmode="number"
                      hide-details
                      class="mb-8"
                      v-on:keydown.enter.prevent="loginWithCode" />

        <div class="text-center">
          <v-btn v-on:keyup.enter="loginWithCode"
                 class="primary--text font-weight-bold"
                 block
                 :small="$vuetify.breakpoint.xsOnly"
                 color="secondary"
                 :loading="signingIn"
                 @click="loginWithCode">
            <v-icon left>mdi mdi-login</v-icon>
            {{ $t('login.sign_in') }}</v-btn>

          <div class="text-center font-weight-light mt-4">
            <v-btn :rounded="uiComponentProperties.rounded"
                   small
                   block
                   color="secondary"
                   class="mt-1 font-weight-light"
                   text
                   @click="codeSent = false">
              <v-icon size="20" left>mdi mdi-arrow-left</v-icon>
              {{ $t('login.back_to_code_sending') }}
            </v-btn>
          </div>

          <div class="login-guide white--text text-center caption mt-5">
            <p>{{ $t('login.by_clicking_you_agree') }} <a href="https://www.hemman.fi/tietosuojaseloste/" class="primary--text text--lighten-5" target="_blank">{{ $t('login.terms_of_service') }}</a>.</p>
            <p>{{ $t('login.to_learn_more_about_privacy') }} <a href="https://www.hemman.fi/tietosuojaseloste/" class="primary--text text--lighten-5" target="_blank">{{ $t('login.privacy_policy') }}</a>.</p>
          </div>

        </div>
      </v-form>

  </v-col>
</template>

<script>
import publicApi from "@/api/public";
import libphonenumber from "google-libphonenumber";
import axios from "axios";
import UserService from "@/service/user.service";
import userApi from "@/api/user";
import UiEventBus from "@/UiEventBus";

export default {
  name: "LoginWithCodeView",
  props: ['uiComponentProperties'],
  data() {
    return {
      codeSent: false,
      subject: '',
      username: '',
      password: '',
      signingIn: false,
      error: false,
      error_message: '',
    }
  },
  computed: {
    loginType() {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

      let loginType = null;
      try {
        phoneUtil.parseAndKeepRawInput(this.username, 'FI');
        loginType = 'SMS';
      } catch (ignored) { // is not valid
      }

      if (loginType == null && this.$isValidEmail(this.username)) {
        loginType = 'EMAIL';
      }

      return loginType;
    },
  },
  methods: {
    async loginWithCode() {
      this.signingIn = true;

      try {
        const formData = new FormData();

        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

        let loginType = null;
        try {
          phoneUtil.parseAndKeepRawInput(this.username, 'FI');
          loginType = 'SMS';
        } catch (ignored) { // is not valid
        }

        if (loginType == null && this.$isValidEmail(this.username)) {
          loginType = 'EMAIL';
        }

        if (loginType == null) {
          console.log('not sms or email', this.username);
          return;
        }

        formData.append("subject", this.username);
        formData.append("code", this.password);
        formData.append("loginType", loginType);
        await axios.post('/api/public/login/with_code?remember-me=1', formData);
        UserService.springLogin();
        const me = await userApi.getMe();
        UserService.setUserInfo(me);
        UiEventBus.$emit('loginSuccess');

        this.$store.commit('setLoggingDisplayState', true);
        this.$store.commit('setLoadingOverlay', true);
        this.$store.commit('setLoggedIn', true);

        this.error = true;
        this.signingIn = false;
      } catch (error) {
        console.log(error);
        this.signingIn = false;
        this.error = true;
        this.error_message = this.$t('login.code_is_not_right');
      }

    },
    async requestCode() {
      try {
        const loginType = this.loginType;

        if (loginType == null) {
          this.error = true;
          this.error_message = this.$t('login.user_not_found');
          return;
        }
        this.error = false;
        await publicApi.sendLoginCode(this.username, loginType);
        this.codeSent = true;
      } catch (error) {
        this.error = true;
        this.error_message = this.$t('login.user_not_found');
      }
    },
  },
}
</script>

<style scoped>

</style>
