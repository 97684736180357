<template>
  <div :class="(proposal || thread).attachments.length === 0 ? 'fill-height' : 'pb-16'">
    <v-card>
      <v-list three-line class="py-0">
        <template v-for="(file, index) in (proposal || thread).attachments">
          <FilesListItem :file="file"
                         :key="file.id"
                         :vote-status="voteStatus"
                         :invert-colors="invertColors"
                         @open-image="handleImageView"
                         @open-video="handleVideoPlay"
                         :disable-attachment-loading="attachmentLoading"
                         @loading-attachment-link="attachmentLoading = true"
                         @loaded-attachment-link="attachmentLoading = false"
                         @delete-file="deleteFile"
                         @who-has-downloaded-files="showWhoHasDownloadedFile"/>
          <v-divider v-if="index < (proposal || thread).attachments.length - 1" :key="`${file.id}_divider`"></v-divider>
        </template>
      </v-list>
    </v-card>

    <no-content-view v-if="ready && (proposal || thread).attachments.length === 0 && voteStatus !== 'OPEN'"
                     :title="$t('proposals.no_files')"
                     :description="proposal ? $t('proposals.no_files_found_and_vote_start') :  $t('threads.no_files_found_and_discussion_end')"
                     :showRefreshButton = "false"
                     icon="mdi mdi-file-remove-outline"/>

    <no-content-view v-if="ready && (proposal || thread).attachments.length === 0 && voteStatus === 'OPEN'"
                     :button="{callback: openFileAddDialog, text: $t('proposals.add_file_dialog.title'), icon: 'mdi mdi-plus' }"
                     :title="$t('proposals.no_files')"
                     :description="$t('proposals.no_files_found')"
                     :showRefreshButton = "true"
                     icon="mdi mdi-file-remove-outline"/>

    <v-fade-transition>
      <v-btn
        :color="invertColors ? 'secondary darken-1' : 'primary'"
        dark
        v-show="ready && (proposal || thread).attachments.length > 0"
        v-if="voteStatus === 'OPEN'"
        fixed
        bottom
        right
        rounded
        style="transition: all 0.2s ease-in-out"
        :fab="!scrollingUp"
        @click="openFileAddDialog"
        large
      >
        <v-icon :left="scrollingUp">mdi mdi-plus</v-icon>
        <span v-show="scrollingUp">{{ $t('proposals.add_file_dialog.title') }}</span>
      </v-btn>
    </v-fade-transition>
    <who-has-downloaded-file-dialog ref="whoHasDownloadedFileDialog" />
    <confirm-dialog ref="confirmDialog" />
    <FileAddDialog ref="fileAddDialog" :proposal="proposal" :thread="thread" @file-uploaded="onFileUploaded"/>
  </div>
</template>

<script>
import FilesListItem from "@/components/Tenant/Common/FilesListItem";
import FileAddDialog from "@/components/Tenant/Common/FileAddDialog";
import NoContentView from "@/components/NoContentView";
import ConfirmDialog from "@/components/ConfirmDialog";
import tenantApi from "@/api/tenant";
import WhoHasDownloadedFileDialog from "@/components/Tenant/Common/WhoHasDownloadedFileDialog";

export default {
  name: "Files",
  components: {NoContentView, FileAddDialog, FilesListItem, ConfirmDialog, WhoHasDownloadedFileDialog},
  props: {
    proposal: {
      type: [Object],
      default: () => null,
    },
    thread: {
      type: [Object],
      default: () => null,
    },
    scrollingUp: {
      type: Boolean,
      default: false,
    },
    voteStatus: {
      type: String,
      default: 'OPEN',
    },
    invertColors: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      ready: false,
      attachmentLoading: false,
    }
  },
  methods: {
    viewerInited(viewer) {
      this.$viewer = viewer;
    },
    handleImageView(imageUrl) {
      this.$emit('handle-image-view', imageUrl)
    },
    handleVideoPlay(video) {
      this.$emit('handle-video-play', video);
    },
    openFileAddDialog() {
      this.$refs.fileAddDialog.open();
    },
    onFileUploaded() {
      this.$emit('file-uploaded');
    },
    async deleteFile(fileId) {
      const deleteConfirmed = await this.$refs.confirmDialog.open(this.$t('proposals.file_delete_dialog.title'), this.$t('proposals.file_delete_dialog.message'), { color: 'warning', agree: this.$t('proposals.file_delete_dialog.delete_yes'), cancel: this.$t('proposals.file_delete_dialog.delete_no') });
      if (!deleteConfirmed) return;
      try {
        if (this.proposal) {
          await tenantApi.deleteAttachment(this.proposal.id, fileId);
        } else {
          await tenantApi.deleteThreadAttachment(this.thread.id, fileId);
        }
        this.$showSuccessNotification(this.$t('proposals.file_delete_dialog.file_delete_confirmed'));
        this.$emit('file-deleted');
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    showWhoHasDownloadedFile(attachmentId) {
      const r = this.proposal ? this.proposal.attachmentReads : this.thread.attachmentReads;
      const usersThatHasDownloadedFile = r.filter(r => r.user.id !== this.$userInfo.id && r.targetId === attachmentId)
        .map(r => r.user);
      this.$refs.whoHasDownloadedFileDialog.open(usersThatHasDownloadedFile);
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.xsOnly) {
      setTimeout(() => {
        this.ready = true
      }, 300);
    } else {
      this.ready = true;
    }
  }
}
</script>

<style scoped>

</style>
