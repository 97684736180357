<template>
  <div>
    <v-dialog v-model="dialog" max-width="960">
      <div :style="{height: $vuetify.breakpoint.xsOnly ? 'calc(100vh - 70px)' : 'auto'}" style="display: flex; align-items: center; justify-content: center; position:relative">
        <v-btn v-if="dialog" @click="dialog = false" small class="close-btn" icon><v-icon color="white">mdi mdi-close</v-icon></v-btn>
        <video ref="videoPlayer" class="video-js"></video>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import VideoJS from 'video.js';
import videojs_fi_lang from 'video.js/dist/lang/fi.json';

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null,
      dialog: false,
    }
  },
  methods: {
    setSource(source) {
      this.player.src(source);
    },
    openPlayer(video) {
      this.dialog = true;
      this.$nextTick(() => {

        if (!this.player) {
          const options = Object.assign({}, this.options);
          const fi_lang = videojs_fi_lang;
          fi_lang["Replay"] = this.$t('replay');
          fi_lang["Picture-in-Picture"] = this.$t('picture_in_picture');
          options.languages = {
            fi: fi_lang,
          }
          this.player = VideoJS(this.$refs.videoPlayer, options);
        }

        this.player.src(video);
        this.player.ready(() => {
          this.player.play();
        });
      });
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        if (this.player) {
          this.player.pause();
        }
      }
    }
  },
  mounted() {},
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>

<style lang="scss" scoped>
  .close-btn {
    z-index: 9999;
    position: absolute;
    top: 2px;
    right: 2px;
  }
</style>
