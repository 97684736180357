<template>
  <hb-basic-page :search-title="`${$t('user_management.search')}...`"
              :subtle-loading="subtleLoading"
              :loading="loading"
              name="app-users-list"
              @handle-search="handleSearch"
              :noContainerPadding="$vuetify.breakpoint.xsOnly"
              no-gutters>

    <v-col cols="12" sm="8" lg="9" xl="10" :style="{height: $vuetify.breakpoint.xsOnly ? '0' : 'auto'}">
      <add-user-dialog @on-dismiss="onAddUserDialogDismiss" :roles="roles" :allUsers="allUsers" @send-invitation="sendInvitation" :tenants="tenants" :tenant-roles="tenantRoles" />
      <v-fab-transition v-if="!loading">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary"
              :loading="generatingExcel"
              dark
              @click="exportExcel"
              :fab="$vuetify.breakpoint.smAndDown"
              :fixed="$vuetify.breakpoint.smAndDown"
              :bottom="$vuetify.breakpoint.smAndDown"
              :right="$vuetify.breakpoint.smAndDown"
              class="ml-sm-2"
              :style="{marginBottom: $vuetify.breakpoint.smAndDown ? '50px' : ''}">
              <v-icon :left="$vuetify.breakpoint.mdAndUp">download</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('user_management.download_excel_button ') }}</span>
            </v-btn>
          </template>
          {{ $t('user_management.download_excel') }}
        </v-tooltip>
      </v-fab-transition>
    </v-col>
    <v-col cols="12" sm="4" lg="3" xl="2">
      <v-select
        v-model="filter"
        :items="filterOptions"
        :placeholder="`${$t('user_management.filtered')} 0 ${$t('user_management.selections')}`"
        item-text="desc"
        item-value="value"
        multiple
        hide-details
        single-line
        solo
        prepend-inner-icon="filter_list"
        :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : ''"
      >
        <template v-slot:selection="{ item, index }">
          <template v-if="filter.length !== filterOptions.length && index === 0">
            <span class="grey--text mr-1">{{ $t('user_management.filtered') }}</span>
            <span class="grey--text caption">({{ filter.length }} {{ $t('user_management.selections') }})</span>
          </template>
          <template v-else>
            <span v-if="index === 0" class="grey--text">{{ $t('user_management.show_all') }}</span>
          </template>
        </template>
      </v-select>
    </v-col>
    <v-col cols="12" v-if="filteredLocalUsers.length > 0 || filteredCloudUsers.length > 0" :pb-5="$vuetify.breakpoint.xsOnly">
      <v-list two-line color="transparent" class="pa-0 pb-15">
        <v-list-item class="users-header" v-if="$vuetify.breakpoint.smAndUp">
          <v-list-item-avatar></v-list-item-avatar>
          <v-list-item-content class="grey--text">
            <v-container fluid ma-0 pa-0>
              <v-row align="center" justify="center" class="fill-height" ma-0>
                <v-col cols="12" sm="6" lg="5" xl="3">
                  <span class="caption">{{ $t('user_management.user') }}</span>
                </v-col>
                <v-col cols="12" sm="6" lg="7" xl="9">
                  <span class="caption">{{ $t('user_management.roles') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
          <v-list-item-action style="width: 36px;"></v-list-item-action>
        </v-list-item>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <users-list :users="filteredLocalUsers" :allRoles="roles" type="local" @reload-users="getAllUsers" :all-tenants="tenants" :all-tenant-roles="tenantRoles" @send-invitation="sendInvitation" />
        <users-list :users="filteredCloudUsers" :allRoles="roles" type="user" @reload-users="getAllUsers" :all-tenants="tenants" :all-tenant-roles="tenantRoles" @send-invitation="sendInvitation" />
      </v-list>
    </v-col>

  </hb-basic-page>
</template>

<script>
import UsersList from '../components/UserManagement/UsersList.vue';
import AddUserDialog from '../components/UserManagement/AddUserDialog.vue';
import adminApi from "../api/admin";
import systemApi from "../api/system";
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default {
    name: "UserManagementView",
    components: {
      UsersList: UsersList,
      AddUserDialog: AddUserDialog,
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        searchPhrase: '',
        cloudUsers: [],
        localUsers: [],
        roles: [],
        filter: [2,3],
        filterOptions: [
          { desc: "Aktiiviset", value: 2 },
          { desc: "Ei aktiiviset", value: 3 },
        ],
        tenants: [],
        tenantRoles: [],
        generatingExcel: false,
        exportRoles: [
          'tenant_chairman',
          'tenant_member',
          'tenant_substitute_member',
        ],
      }
    },
    methods: {
      handleSearch(searchPhrase) {
        this.searchPhrase = searchPhrase;
      },
      onAddUserDialogDismiss() {
        this.getAllUsers();
      },
      async reloadLocalUsers() {
        try {
          this.localUsers = await adminApi.getLocalUsers()
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async reloadCloudUsers() {
        try {
          this.cloudUsers = await adminApi.getCloudUsers()
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async getAllUsers() {
        if (this.localUsers.length > 0 || this.cloudUsers.length > 0) {
          this.subtleLoading = true;
        } else {
          this.loading = true;
        }
        await this.reloadCloudUsers();
        await this.reloadLocalUsers();
        this.loading = false;
        this.subtleLoading = false;
      },
      filterUsers(users) {
        const filteredUsers = users;

        const filterOutActive = this.filter.findIndex(f => f === 2) === -1;
        const filterOutInactive = this.filter.findIndex(f => f === 3) === -1;

        if ((this.searchPhrase == null || this.searchPhrase.length === 0) && !filterOutActive && !filterOutInactive) {
          return filteredUsers;
        }

        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(" ");
        return filteredUsers.filter(user => {
          let found = [];
          const userTenants = [];
          user.tenantRoles.forEach(tenant => userTenants.push(tenant.name));
          const lowerSearchedString =
            (user.email ? user.email.toLowerCase() : '') +
            (user.subject ? user.subject.toLowerCase() : '') +
            (user.firstName ? user.firstName.toLowerCase() : '') +
            (user.lastName ? user.lastName.toLowerCase() : '') +
            (userTenants.join(' ').toLowerCase()) +
            (user.roleSet && user.roleSet.length > 0 ? user.roleSet.join(' ').toLowerCase() : '');
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          })
          if (filterOutActive && user.enabled) found.push(false);
          if (filterOutInactive && !user.enabled) found.push(false);
          return found.indexOf(false) === -1;
        })
      },
      async getRoles() {
        try {
          this.roles = await systemApi.getRoles();
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async getTenants() {
        try {
          this.tenants = await this.$adminApi.getTenants();
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async getTenantRoles() {
        try {
          const response = await this.$adminApi.getTenantRoles();
          this.tenantRoles = response.filter(role => !(role === 'tenant_admin' || role === 'tenant_basic'));
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      sendInvitation(userId, type) {
        try {
          this.$userApi.sendInvitationMessage(userId, type);
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      exportExcel() {
        this.generatingExcel = true;

        const wb = XLSX.utils.book_new();
        const ws_name = this.$t('user_management.members');

        const headers = [
          this.$t('tenants.organization'),
          this.$t('tenants.city'),
          this.$t('user_management.role.title'),
          this.$t('user_management.name'),
          this.$t('user_management.email'),
          this.$t('user_management.phone'),
          this.$t('user_management.street_address'),
          this.$t('user_management.postal_code'),
          this.$t('user_management.city')
        ];

        /* make worksheet */
        const ws_data = [
          headers,
          ...this.exportUsers,
        ];
        const ws = XLSX.utils.aoa_to_sheet(ws_data);
        ws['!autofilter']={ref:"A1:I1"};

        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        const wbOut = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

        function s2ab(s) {
          const buf = new ArrayBuffer(s.length);
          const view = new Uint8Array(buf);
          for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
          }
          return buf;
        }

        const fileName = `${this.$t('user_management.members')}.xlsx`;

        saveAs(new Blob([s2ab(wbOut)], { type: "application/octet-stream" }), fileName);

        this.generatingExcel = false;
      },
      userFullName(user) {
        if (!user) {
          return '';
        } else if (user.firstName && user.lastName) {
          return `${user.firstName} ${user.lastName}`;
        } else {
          return user.firstName || user.lastName || '';
        }
      },
      userTenantRole(tenantId, user) {
        const tr = user.tenantRoles.find(t => t.tenant === tenantId);
        if (tr) {
          return tr.roleSet
            .filter(r => this.exportRoles.includes(r))
            .map(r => this.$t(`user_management.role.${r}`))
            .join(', ');
        }
        return '';
      },
    },
    computed: {
      filteredLocalUsers() {
        return this.filterUsers(this.localUsers);
      },
      filteredCloudUsers() {
        return this.filterUsers(this.cloudUsers);
      },
      allUsers() {
        return this.cloudUsers.concat(this.localUsers);
      },
      exportUsers() {
        let users = [];
        for (const tenant of this.tenants) {
          const tenantMembers = this.allUsers
            .filter(u => u.enabled && u.tenantRoles.filter(t => t.tenant === tenant.id && t.roleSet.some(r => this.exportRoles.includes(r))).length > 0)
            .map(u => [
              tenant.name,
              tenant.city,
              this.userTenantRole(tenant.id, u),
              this.userFullName(u),
              u.email || '',
              u.phone || '',
              u.streetAddress || '',
              u.postalCode || '',
              u.city || ''
            ]);
          users = users.concat(tenantMembers);
        }
        return users;
      }
    },
    mounted() {
      this.getAllUsers();
      this.getRoles();
      this.getTenants();
      this.getTenantRoles();
    },
  }
</script>

<style lang="scss">
  .users-header {
    .v-list__tile {
      height: 30px;
    }
  }
</style>
