<template>
  <v-dialog v-model="dialog" max-width="600" @keydown.esc="cancel" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 font-weight-bold pb-1' : ''">{{ $t('proposals.leave_comment') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5">
        {{ $t('proposals.you_are_voting') }} <strong>"{{ voteType ? $t('proposals.vote_keys_for_buttons.' + voteType) : ''}}"</strong>
        <span v-if="housingmanager && user">, {{ $t('proposals.for_user_1') }} <strong>{{ user.firstName }} {{ user.lastName }}</strong> {{ $t('proposals.for_user_2') }}</span>
        .
        {{ $t('proposals.comment_when_voting_help') }}
        <p class="mt-2 caption">{{ $t('proposals.you_cannot_alter_your_vote_after_this') }}</p>

        <v-textarea rows="3" outlined v-model="comment" :placeholder="`${$t('proposals.write_comment')}...`" :label="$t('proposals.comment')" hide-details />
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn :class="$vuetify.breakpoint.xsOnly ? 'mx-0' : ''"
               :block="$vuetify.breakpoint.xsOnly"
               color="default"
               :disabled="loading"
               @click.native="cancel">{{ $t('cancel') }}</v-btn>
        <v-btn :class="$vuetify.breakpoint.xsOnly ? 'mb-2 mx-0' : ''"
               :block="$vuetify.breakpoint.xsOnly"
               :disabled="loading"
               color="secondary" class="primary--text"
               @click.native="skipCommenting">{{ $t('skip_commenting') }}</v-btn>
        <v-btn :class="$vuetify.breakpoint.xsOnly ? 'mb-2 mx-0' : ''"
               :block="$vuetify.breakpoint.xsOnly"
               color="primary"
               :loading="loading"
               @click.native="submitComment">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tenantApi from "@/api/tenant";

export default {
  name: 'CommentWhenVotingDialog',
  props: {
    proposal: {
      type: Object,
      default: () => {},
    },
    housingmanager: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      voteType: '',
      comment: '',
      loading: false,
      user: null,
    };
  },
  methods: {
    open(voteType, user) {

      this.dialog = true;
      this.voteType = voteType;
      if (this.housingmanager) {
        this.user = user;
      }
      this.comment = '';
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async submitComment() {
      if (this.comment.length === 0) {
        this.$showErrorNotification(this.$t('proposals.vote_or_skip'))
        return;
      }

      try {
        this.loading = true;
        const comment = await tenantApi.createComment(this.proposal.id, {
          message: this.comment,
          voteType: this.voteType,
          user: this.user,
        });
        this.resolve({commented: true, comment});
        this.dialog = false;
      } catch (err) {
        this.$handleApiError(err);
      }
      this.loading = false;
    },
    skipCommenting() {
      this.resolve({commented: false});
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
}
</script>
